@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/box-shadow";
@use "../../settings/colors";
@use "../../settings/theme";
@use "../../settings/layout";
@use "../../settings/header";
@use "../../settings/typography" as typography-settings;
@use "../../settings/transition";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.badges-wrap {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	gap: units.px2rem(4px);
}

.badges-wrap--form-group {
	width: 100%;
}

.badge {
	@include typography.style(badge);

	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-height: spacing.set(6);
	padding-inline: spacing.set(3);
	text-align: center;
	white-space: nowrap;
	color: colors.$white;
	border-radius: map.get(border-radius.$values, full);
	background-color: map.get(colors.$brand, blue-dark);
}

.badges-wrap--form-group .badge:last-of-type {
	margin-bottom: spacing.set(2);
}

.badge__remove {
	min-height: spacing.set(6);
	padding-inline: spacing.set(2);
	margin-right: spacing.set(-2);
	font-size: map.get(typography-settings.$font-size-values, 3);
	color: colors.$white;
	cursor: pointer;
}

.badge__remove .icon {
	position: relative;
	top: units.px2rem(1px);
	pointer-events: none;
}
