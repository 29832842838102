@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/box-shadow";
@use "../../settings/colors";
@use "../../settings/header";
@use "../../settings/layout";
@use "../../settings/typography" as typography-settings;
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/units";

.side-menu {
	width: 100%;
	padding: spacing.set(3) spacing.set(5);
	background-color: colors.$white;
	box-shadow: map.get(box-shadow.$values, small);

	@include breakpoint.up(md) {
		position: sticky;
		top: calc(#{header.$height} + #{spacing.set(2)});
	}
}
