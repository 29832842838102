/* stylelint-disable */
.dr-aside {
	position: fixed;
	left: 0;
	user-select: none;
	margin-top: 73px;
	z-index: 1900;
}
.dr-aside--min {
	margin-left: -265px;
	~ .dr-main {
		max-width: calc(100vw - 50px);
	}
}
.dr-aside--empty {
	position: absolute;
	top: 50%;
	transform: translateY(-100%);
	text-align: center;
	width: calc(100% - 20px);
}
.dr-aside__body {
	background-color: #F9FAFA;
	top: 73px;
	bottom: 0;
	height: calc(100vh - 73px);
	border-right: 1px solid #EEEEEE;
	width: 315px;
	position: fixed;
	direction: rtl;
	box-sizing: content-box;
}
.dr-aside__body--min {
	width: 52px;
	padding: 13px 10px;
	margin-left: -20px;
	overflow-x: hidden;
	overflow-y: auto;
}
.dr-aside__body__inner {
	direction: rtl;
	width: 100%;
	padding-left: 20px;
	margin-left: -20px;
	overflow-x: hidden;
	overflow-y: auto;
	box-sizing: content-box;
}
.dr-aside__body__nrm-dir {
	direction: ltr;
	width: 100%;
	padding: 13px 10px;
	// height: 100%;
}
.dr-aside__search {
	border: 0;
	margin-top: -6px;
	padding-left: 36px;
	background-image: url(../../img/icons/icon-search-light.svg);
	background-position: left 6px center;
	background-size: 20px;
	background-repeat: no-repeat;
}
.dr-aside__search__reset {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $lightGrey;
	border-radius: 4px;
	color: $mediumGrey;
	&:hover {
		color: $main-color;
	}
}
.dr-aside__title {
	color: #787878;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.15px;
	line-height: 15px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
}

.dr-aside__title-wrap {
	display: flex;
	align-items: center;
}

.dr-aside__search-button {
	margin-right: 5px;
}

.dr-aside__search__form {
	direction: ltr;
}

.search-bar-shown {
	color: $mediumGrey;

	.icon--search-drbox {
		background-image: url(../../img/icons/icon-search-orange.svg);
	}
}


.dr-aside--filter-bottom-btns {
	// vh window, 73 header menu, 52 search/icon section, 174 filter section, 201 slider section, 44 height of "apply filters" btn, 26 padding on dr-body
	margin-top: calc(100vh - 73px - 52px - 174px - 201px - 44px - 26px);
	transition: margin 0.3s ease;
}
.is-search-bar-visible .dr-aside--filter-bottom-btns {
	// 68 height of searchbar
	margin-top: calc(100vh - 73px - 52px - 174px - 201px - 44px - 26px - 68px);
}
.dr-main {
	margin-right: 0;
	width: 100%;
	max-width: calc(100% - 315px);
	width: calc(100% - 315px);
	overflow: hidden;
	padding: 0;
	user-select: none;
}
.dr-main--full {
	max-width: 100%;
	width: 100%;
}
.dr-main--aside-min {
	max-width: calc(100% - 52px);
	width: calc(100% - 52px);
}
.dr-main--communication {
	padding: 20px $aside-width 128.53px 20px;
	overflow: hidden;
	max-height: calc(100vh - 73px);
	background-color: white;
	user-select: auto;
	position: relative;
}
.dr-main__no-conversations {
	position: absolute;
	max-width: 90%;
	width: 500px;
	top: 30%;
	transform: translate(-50%);
	left: 50%;
	&.with-detail{
		max-width: calc((100% - (23vw + 40px)) * 0.9);
		width: 400px;
		left: calc((100% - (23vw + 40px)) * 0.5);
	}
}
.dr-communication__body {
	height: calc(100vh - 128.53px - 20px - 73px);
	overflow: auto;
	padding-right: 20px;
}
.dr-communication__form-wrap {
	position: fixed;
	bottom: 0;
	padding-top: 13px;
	padding-right: inherit;
	padding-bottom: 13px;
	padding-left: 20px;
	right: 0;
	height: 128.53px;
	width: inherit;
	background-color: #FFFFFF;
}
.dr-communication__form {
	position: relative;
	border: 1.5px solid #DADEE1;
	border-radius: 4px;
	height: 100%;
	display: flex;
	flex-flow: row;
	.error-container {
		position: absolute;
		bottom: 13px;
		left: 13px;
	}
}
.dr-communication__input {
	border: 0;
	flex: 1;
	padding: 13px;
	font-family: $font-lato;
	font-size: 14px;
	line-height: 17px;
}
.dr-communication__submits {
	margin-top: auto;
	margin-left: auto;
	margin-bottom: 7px;
	margin-right: 7px;
}
.dr-message__wrap {
	max-width: 70%;
	margin-top: 18px;
	margin-bottom: 18px;
}
.dr-message__head {
	color: #A5A5A5;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 0.13px;
	line-height: 14px;
	display: block;
}
.dr-message__head__wrap {
	font-family: $font-lato;
	font-size: 14px;
	line-height: 17px;
}
.dr-message__head__id {
	color: $main-color;
	font-size: 14px;
	font-weight: bold;
	line-height: 17px;
}
.dr-message__body {
	position: relative;
	display: inline-block;
	background-color: #ECECEC;
	border-radius: 2px 35px 35px 35px;
	padding: 20px 15px;
	margin: 10px 0 0 0;
	color: #313131;
	font-family: $font-lato;
	font-size: 14px;
	line-height: 17px;
	text-align: left;
}
.dr-message--right {
	text-align: right;
	margin-left: auto;
	.dr-message__body {
		background-color: #9BB5C3;
		color: white;
		border-radius: 35px 2px 35px 35px;
	}
}
.dr-message__body__text {
	margin: 0;
	hyphens: auto;
	word-break: break-word;
}
.dr-message__gr-submess {
	margin-bottom: -40px;
	margin-top: 10px;
	display: block;
	text-align: right;
	color: white;
	font-family: $font-lato;
	font-size: 0;
	font-weight: bold;
	line-height: 16px;
}
.dr-message__gr-submess__text,
.dr-message__gr-submess__btn {
	display: inline-block;
	vertical-align: middle;
	box-shadow: 0 6px 30px -5px rgba(106,120,124,0.1);
	background-color: #69B552;
	border-radius: 4px;
	padding: 11px 15px;
	line-height: 16px;
}
.dr-message__gr-submess__text {
	margin-right: -5px;
	font-size: 12px;
	font-weight: bold;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.dr-message__gr-submess__btn {
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 600;
	transition: background .2s ease;
	padding-left: 8px;
	&:hover {
		background-color: darken(#69B552, 5%);
	}
	svg {
		display: inline-block;
		vertical-align: middle;
		height: 13.33px;
		margin-top: -3px;
	}
}
.dr-message__gr-btns {
	margin-bottom: -50px;
	display: block;
	text-align: right;
}
.dr-message__gr-btns__group {
	padding: 4px 5px;
	background-color: white;
	border-radius: 4px;
	display: inline-block;
	margin: 10px 6px;
	&:last-child {
		margin-right: 0;
	}
}
.dr-message__gr-btn {
	color: #787878;
	font-size: 11px;
	font-weight: 600;
	line-height: 14px;
	padding: 9px 10px;
	background-color: #ECECEC;
	text-transform: uppercase;
	border-radius: 4px;
	display: inline-block;
	&:hover {
		color: $main-color;
	}
}
.dr-message__gr-btn--conf {
	&::before {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		margin-left: 4px;
		margin-right: 2px;
		width: 10px;
		height: 5px;
		border: 1.5px solid currentColor;
		border-top-width: 0;
		border-right-width: 0;
		transform: rotate(-45deg);
		transform-origin: left bottom;
	}
}
.dr-message__gr-btn--dec {
	position: relative;
	&::before,
	&::after {
		content: " ";
		width: 14px;
		height: 1.5px;
		display: inline-block;
		vertical-align: middle;
		background-color: currentColor;
	}
	&::before {
		transform: rotate(-45deg);
		margin-top: -2px;
		margin-left: 1px;
		margin-right: 5px;
	}
	&::after {
		transform: rotate(45deg);
		position: absolute;
		left: 11px;
		top: 15px;
	}
}
.dr-message__body__sign,
.dr-extra__name__sign {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background-color: #DF3939;
	border-radius: 50%;
	svg {
		position: absolute;
	}
}
.dr-message__body__sign {
	position: absolute;
	top: -7px;
	right: 0;
	height: 24px;
	width: 24px;
	box-shadow: 0 6px 30px -10px rgba(106,120,124,0.1);
}
.dr-extra__name__sign {
	height: 17px;
	width: 17px;
	vertical-align: middle;
	svg {
		height: 9px;
		width: 11px;
	}
}

/***  mailbox  ***/

.dr-mainbox {
	position: relative;
	margin-top: $dr-height;
	margin-bottom: -20px;
	overflow: hidden;
	display: flex;
	flex-flow: column;
	min-height: calc(100vh - 131px + 20px);
}
.dr-head {
	width: inherit;
	position: fixed;
	z-index: 102;
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
	padding: 0 30px;
	background-color: #FFFFFF;
	border-bottom: 1px solid #eeeeee;
	height: $dr-height;
	overflow: hidden;
}
.dr-bulks {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding-left: 51px;
	font-size: 0;
	white-space: nowrap;
}
.dr-bulk__cover-wrap {
	display: none;
	top: 0;
	left: 0;
	position: absolute;
	width: 150px;
	height: 53px;
	border-radius: 6px;
	background: $base-background;
}
.dr-bulk__cover-left {
	position: absolute;
	z-index: $z-index-dr-hover - 1;
	top: 10px;
  	right: 143px;
	width: 10px;
	height: 100%;
	border-radius: 0 0 6px;
	background: $base-background;
}

.dr-bulk__cover-left--end {
	position: absolute;
	z-index: $z-index-dr-hover;
	bottom: 0;
	right: 0;
	border-radius: 6px 0 0;
	width: 10px;
	  height: 10px;
	background: $semiGrey;
}

.dr-bulk__cover-top {
	position: absolute;
	z-index: $z-index-dr-hover - 1;
	top: 0;
	right: -6px;
	width: 10px;
	height: 50%;
	border-radius: 0 0 6px;
	background: $base-background;
}

.dr-bulk__cover-top--end {
	position: absolute;
	z-index: $z-index-dr-hover;
	top: 0;
	right: 0;
	width: 6px;
	height: 100%;
	border-radius: 6px 0;
	background: $semiGrey;
}

.dr-bulk {
	position: relative;
	display: inline-block;
	width: 325px;
	height: 100%;
	margin: 0 10px;
	border-radius: 6px 6px 0 0;
	padding: 10px;
	vertical-align: top;

	&:hover,
	&.hover {
		background-color: $semiGrey;

		.dr-bulk__cover-wrap {
			display: block;
		}
	}
}

.dr-bulk__btn-wrap {
	display: flex;
	justify-content: flex-end;
}

.dr-bulk__btn {
	display: block;
	flex: 1;
	text-transform: uppercase;
	border-radius: 4px;
	background-color: #ECECEC;
	color: #787878;
	font-size: 11px;
	font-weight: 600;
	line-height: 34px;
	padding: 5px;
	text-align: center;
	height: 44px;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	&:hover {
		color: $main-color;
	}
}

.dr-bulk__btn__more {
	height: 44px;
	margin: 0;

	&::after {
		margin-left: 5px;
	}
}

.dr-bulk__btn__more--mini {
	padding: 0 0 0 5px;

	&::before {
		margin-right: 0;
	}

	&::after {
		margin-left: 0;
	}
}

.dr-boardbox--min .dr-bulk__btn__more {
	display: none;
}

.dr-daylines-wrap {
	position: relative;
	overflow: auto;
	padding-bottom: 20px;
	flex: 1;
}
.dr-daylines-wrap--bulks {
	padding-top: 63px;
}
.dr-dayline {
	position: relative;
	display: inline-flex;
	flex-flow: row nowrap;
	pointer-events: none;
	height: 377px;
	width: 2140px;
	border: 1px solid #EEEEEE;
	background-color: #F7F7F7;
	margin-bottom: 18px;
	padding-left: 50px;
	overflow: hidden;
}
.dr-dayline__name {
	position: sticky;
	display: flex;
	flex-flow: column;
	align-items: center;
	top: 0;
	bottom: 0;
	left: 0;
	width: 50px;
	height: 377px;
	margin-bottom: -377px;
	border: 1px solid #EEEEEE;
	background-color: #FFFFFF;
	padding-top: 12px;
	padding-bottom: 12px;
	z-index: 10;
	transition: box-shadow .2s ease;
	box-shadow: 0 0 14px 0 rgba(0,0,0,0);
	&.is-fixed {
		box-shadow: 0 0 14px 0 rgba(0,0,0,0.1);
	}
}
.dr-dayline__name__circle {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 31px;
	flex: 0 0 31px;
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 0.14px;
	line-height: 31px;
	color: #EDEDED;
	margin-top: 8px;
	cursor: default;
	#percentageCircle {
		stroke: currentColor;
	}
	svg {
		position: absolute;
		width: 31px;
		height: 31px;
		left: 0;
		top: 0;
		pointer-events: none;
	}
	&.is-ok {
		color: #69B552;
	}
	&.is-over {
		color: #DF3939;
		#percentageCircle {
			stroke: currentColor;
		}
		&::before{
			content: " ";
			position: absolute;
			border-radius: 50%;
			top: -2px;
			right: -3px;
			z-index: 1;
			height: 16px;
			width: 16px;
			background-color: #DF3939;
			box-shadow: 0 6px 30px -10px rgba(106,120,124,0.1);
			background-image: url(../../img/icons/icon-warning-white.svg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: 11px;
		}
	}
}
.dr-dayline__name__year {
	opacity: 0.4;
	color: #606060;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: -0.02px;
	line-height: 15px;
	text-align: center;
	width: 50px;
}
.dr-dayline__name__date {
	color: #FE5C4F;
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	text-align: center;
	width: 50px;
}
.dr-dayline__name__name {
	transform: rotate(-90deg);
	transform-origin: center;
	white-space: nowrap;
	pointer-events: none;
	height: 85%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	span {
		width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.dr-dayline__name__icon {
	cursor: pointer;
	width: 30px;
	height: 30px;
	flex: 0 0 30px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 3px 0;
	z-index: 101;
}
.dr-dayline__info-icon {
	&:hover {
		.icon--info {
			background-image: url(../../img/icons/icon-info-color.svg);
		}
		+ .dr-dayline__info {
			opacity: 1;
		}
	}
}
.dr-dayline__info {
	position: absolute;
	opacity: 0;
	left: 0;
	bottom: 0;
	pointer-events: none;
	height: 377px;
	width: 489px;
	border: 1px solid #EEEEEE;
	background-color: #FFFFFF;
	z-index: 100;
	padding-left: 115px;
	padding-right: 44px;
	padding-top: 46px;
	padding-bottom: 46px;
}
.dr-dayline__info__name {
	color: #313131;
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	text-align: center;
}
.dr-dayline__info__title {
	color: #FE5C4F;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: -0.02px;
	line-height: 15px;
}
.dr-dayline__info__desc {
	max-height: 200px;
	overflow: hidden;
}
.dr-boardbox {
	position: relative;
	pointer-events: auto;
	height: 362px;
	flex: 0 0 325px;
	max-width: 325px;
	margin-top: auto;
	margin-left: 10px;
	margin-right: 10px;
	border-radius: 3px 3px 0 0;
	background-color: #FFFFFF;
	box-shadow: 0 8px 30px -10px rgba(106,120,124,0.3);
	padding: 10px 10px 0;
	display: flex;
	flex-flow: column;
	&.status-dash-before::before {
		left: 12px;
		top: 12px;
	}
}
.dr-boardbox--min {
	flex: 0 0 80px;
	max-width: 80px;
	&:hover,
	&.hover {
	 .dr-bulk__cover-wrap {
			display: none;
		}
	}

	.dr-extras,
	.dr-boardbox__icon,
	.dr-extra__options {
		display: none;
	}
	.js-boardbox-max {
		display: flex;
	}
	.dr-boardbox__title {
		flex: 1;
		padding-left: 0;
		text-align: center;
		display: flex;
		flex-flow: column;
		justify-content: space-between;
	}
	.dr-boardbox__title__extras {
		span {
			display: block;
			color: #313131;
			font-size: 16px;
			font-weight: 600;
			line-height: 25px;
		}
	}
	.dr-boardbox__title__name {
		white-space: nowrap;
		transform: rotate(-90deg) translate(-50px,-29px);
		transform-origin: center right;
		display: flex;
		justify-content: flex-end;
		color: #313131;
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
	}
}
.dr-boardbox__icon {
	float: right;
	margin-left: 10px;
	width: 22px;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	position: relative;
	cursor: pointer;
}
.js-boardbox-max {
	display: none;
	position: absolute;
	right: 0;
	top: 0;
	margin: 0;
}
.dr-boardbox__title {
	position: relative;
	color: #606060;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.15px;
	line-height: 15px;
	padding-left: 20px;
	flex: 0 0 29px;
	height: 29px;
	width: 100%;
	margin-bottom: 10px;
}
.status-dash-before {
	position: relative;
	&::before {
		content: " ";
		position: absolute;
		left: 0;
		top: 2px;
		height: 12px;
		width: 12px;
		border-radius: 50%;
		background-color: #E4E4E4;
	}
	&.btns-list__btn {
		padding-left: 28px;
		&::before {
			left: 8px;
			top: calc(50% - 6px);
		}
	}
}
.status-dash-before-booking-pending::before {
	background-color: #FFE798;
}
.status-dash-before-booked::before {
	background-color: #FFAB7B;
}
.status-dash-before-confirmation-pending::before {
	background-color: #BAE0AF;
}
.status-dash-before-confirmed::before {
	background-color: #69B552;
}
.status-dash-before-canceled::before {
	background-color: #DF3939;
}
.dr-boardbox__title__extras {
	color: #A5A5A5;
	font-size: 11px;
	letter-spacing: 0.13px;
	line-height: 14px
}
.dr-mainbox__title {
	padding: 10px 30px;
	width: 100%;
	position: sticky;
	left: 0;
	z-index: 20;
}
.dr-main__hr-scroll {
	position: fixed;
	height: 20px;
	bottom: 0;
	right: 0;
	width: inherit;
	z-index: 99;
	overflow: auto;
	box-sizing: content-box;
}
.dr-main__hr-scroll__inner {
	width: 2140px;
	height: 100%;
	display: block;
}
.dr-extras {
	flex: 1;
	display: flex;
	flex-flow: column;
	overflow: auto;
	padding-right: 10px;
	margin-right: -10px;
}
.dr-extras__empty {
	color: #313131;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	text-align: center;
	margin-bottom: 12px;
	margin-top: auto;
}
.dr-extras__empty__subtitle {
	color: #A5A5A5;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 0.13px;
	line-height: 14px;
	text-align: center;
	margin-bottom: auto;
	padding-bottom: 25px;
}
.dr-extra {
	display: block;
	position: relative;
	border: 1px solid #E5E5E5;
	border-radius: 4px;
	background-color: #FFFFFF;
	box-shadow: 0 6px 30px -5px rgba(106,120,124,0.1);
	padding: 5px 11px 8px;
	margin-bottom: 5px;
	transition: padding .2s ease;
	.role-boc-group {
		min-width: calc(100% - 36px);
	}
	.addRoleTag:first-child {
		width: calc(100% + 36px);
		background-color: #EEEEEE;
		justify-content: space-between;
	}
	&:focus {
		outline: none;
	}
	&::after {
		content: "";
		display: block;
		position: absolute;
		right: 0px;
		top: 0;
		height: 100%;
		width: 0;
		border-radius: 0 2px 2px 0;
		background-color: #F5C0B4;
		opacity: 0;
		transition: all .2s ease;
	}
	&:hover,
	&.is-active {
		&::after {
			opacity: 1;
			width: 6px;
		}
		.dr-extra__unseen {
			right: 8px;
		}
	}
	&.is-active {
		&::after {
			background-color: #FE5C4F;
		}
	}
}
.dr-extra__unseen {
	position: absolute;
	top: 7px;
	right: 5px;
	border-radius: 50%;
	height: 10px;
	width: 10px;
	background-color: #618FC1;
	transition: right .2s ease;
}
.dr-extra__message {
	position: relative;
	max-height: 60px;
	overflow: hidden;
}
.dr-extra--noborder {
	border: 0;
}
.dr-extra__head {
	min-height: 39px;
	margin-bottom: 4px;
}
.dr-extra__options {
	float: right;
}
.dr-extra__img {
	height: 39px;
	width: 64px;
	border-radius: 4px;
	float: left;
	overflow: hidden;
	margin-right: 14px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.dr-extra__name {
	color: #313131;
	font-family: $font-lato;
	font-size: 14px;
	line-height: 17px;
}
.dr-extra__tag {
	display: inline-block;
	padding: 0 8px;
	height: 17px;
	border-radius: 8.5px;
	background-color: #46546A;
	white-space: nowrap;
	color: #FFFFFF;
	font-size: 11px;
	font-weight: 600;
	line-height: 17px;
	text-align: center;
	margin-right: 3px;
}

.dr-sd-nav-item {
	display: flex;
	text-align: left;
}

.dr-sd-nav-item__time-wrap {
	margin-right: 8px;
}

.dr-sd-nav-item__time {
	display: block;
	color: #606060;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.15px;
	line-height: 15px;
	margin-top: 4px;
}

//** draggable **//

#draggable-live-region {
	z-index: 2100;
}
.draggable-mirror {
	z-index: 2000;
	width: 295px;
	box-shadow: 0 2px 8px 0 rgba(0,0,0,0.3);
}
.draggable-source--is-dragging {
	opacity: .5;
}
.draggable--is-dragging {
	.dr-extra,
	[data-tooltip],
	a ,
	.btn {
		pointer-events: none;
	}
}
.draggable-container {
	transition: padding-top .2s ease;
	&:focus {
		outline: none;
	}
}
.draggable-container-disabled {
	> * {
		filter: blur(2px);
		opacity: 0.5;
	}
	&::after {
		content: "The Talent is already assigned \A to this Shooting Day";
		border-radius: 3px 3px 0 0;
		top: 0;
		left: 0;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		background-color: rgba(231,233,237,0.92);
		color: #606060;
		font-size: 12px;
		font-weight: 600;
		letter-spacing: -0.02px;
		line-height: 15px;
		text-align: center;
		white-space: pre;
		z-index: 2;
	}
}
.dr-boardbox--min.draggable-container-disabled::after {
	content: '🚫';
}
.dr-boardbox:not(.draggable-container-disabled) {
	.draggable-container--over:not(.draggable-container--is-dragging) {
		padding-top: 130px;
		.dr-extras__empty,
		.dr-extras__empty__subtitle {
			display: none;
		}
	}
}
.dr--no-sd {
	flex-flow: column;
	height: calc(90% - #{$dr-height});
	justify-content: center;
	align-items: center;
	display: flex;
}

//*********//
.viewer-tb {
	border-collapse: separate;
	border-spacing: 0 5px;
	width: 100%;
}
.viewer-tb--tr {
	border-radius: 4px;
	background-color: #FFFFFF;
	box-shadow: 0 6px 30px -5px rgba(106,120,124,0.1);
	color: #606060;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.15px;
	line-height: 15px;
	text-align: right;
	td {
		padding: 23px 20px;
		border: 1px solid #E5E5E5;
		border-left-width: 0px;
		border-right-width: 0px;
		&:first-child {
			border-radius: 4px 0 0 4px;
			border-left-width: 1px;
		}
		&:last-child {
			border-radius: 0 4px 4px 0;
			border-right-width: 1px;
		}
	}
}
.viewer-tb--name {
	text-align: left;
	color: #C4C4C4;
	font-family: $font-lato;
	font-size: 13px;
	font-weight: bold;
	letter-spacing: 0.26px;
	line-height: 16px;
}
.viewer-modalbody {
	padding: 8px 10px!important;
	max-height: 500px;
	overflow: auto;
	background-color: #F9FAFA;
}
/* stylelint-enable */
