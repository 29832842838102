@use "sass:map";
@use "../../../scss/settings/colors";
@use "../../../scss/settings/typography" as typography-settings;
@use "../../../scss/tools/typography";
@use "../../../scss/tools/units";

.text-1 {
	@include typography.style(text1);
}

.text-2 {
	@include typography.style(text2);
}

.text-3 {
	@include typography.style(text3);
}

.text-4 {
	@include typography.style(text4);
}

p {
	margin-top: 0;
	letter-spacing: units.px2em(0.24px, typography-settings.$font-size-base);
}

.text-default {
	color: colors.$text;
}

.text-info {
	color: map.get(colors.$grays, 700);
}

.text-light {
	color: map.get(colors.$grays, 600);
}

.text-danger {
	color: colors.$primary;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}
