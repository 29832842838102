// 1. Generic
// ----------
// Ground-zero styles.
@use "scss/styles/01-generic/keyframes";
@use "scss/styles/01-generic/normalizer";

// 2. Theme
// -----------
// CSS Variables theme
@use "scss/styles/02-theme/theme";

// 3. Elements
// -----------
// Unclassed HTML elements (type selectors).
@use "scss/styles/03-elements/heading-elements";
@use "scss/styles/03-elements/link-elements";
@use "scss/styles/03-elements/page-elements";
@use "scss/styles/03-elements/text-elements";

// 3. Components
// -----------
@use "scss/styles/04-components/alerts";
@use "scss/styles/04-components/badge";
@use "scss/styles/04-components/button";
@use "scss/styles/04-components/container";
@use "scss/styles/04-components/cropper";
@use "scss/styles/04-components/custom-select";
@use "scss/styles/04-components/detail-field";
@use "scss/styles/04-components/dropdown";
@use "scss/styles/04-components/extra-detail";
@use "scss/styles/04-components/file-line";
@use "scss/styles/04-components/filter-advanced";
@use "scss/styles/04-components/filter-range";
@use "scss/styles/04-components/filter";
@use "scss/styles/04-components/form-grid";
@use "scss/styles/04-components/form-group";
@use "scss/styles/04-components/grid";
@use "scss/styles/04-components/header";
@use "scss/styles/04-components/checkbox";
@use "scss/styles/04-components/info-toggle";
@use "scss/styles/04-components/input-image";
@use "scss/styles/04-components/input";
@use "scss/styles/04-components/jsr-slider";
@use "scss/styles/04-components/label";
@use "scss/styles/04-components/loading";
@use "scss/styles/04-components/logo-upper";
@use "scss/styles/04-components/main";
@use "scss/styles/04-components/mark";
@use "scss/styles/04-components/modal";
@use "scss/styles/04-components/nav-box";
@use "scss/styles/04-components/nav-toggle";
@use "scss/styles/04-components/nav";
@use "scss/styles/04-components/page-box";
@use "scss/styles/04-components/photos-grid";
@use "scss/styles/04-components/premium-box-account";
@use "scss/styles/04-components/required";
@use "scss/styles/04-components/search";
@use "scss/styles/04-components/section-divider";
@use "scss/styles/04-components/select-checkbox";
@use "scss/styles/04-components/select";
@use "scss/styles/04-components/side-menu";
@use "scss/styles/04-components/table-layout";
@use "scss/styles/04-components/tabler";

/* stylelint-disable */
@import "scss/vars";

@import "scss/tools/detail-form";
@import "scss/tools/spacing";
@import "scss/settings/spacing";

@import "scss/core/base";
@import "scss/core/typo";
@import "scss/core/links";
@import "scss/core/btns";
@import "scss/core/forms";

// @import "scss/layout/nav";
@import "scss/layout/main";
@import "scss/layout/aside";
@import "scss/layout/aside";
@import "scss/layout/dr";
@import "scss/layout/nav";
@import "scss/layout/table-layout";

@import "scss/components/dashboard";

@import "scss/components/bar";
@import "scss/components/cir";
@import "scss/components/modal";
@import "scss/components/modal-bulker";
@import "scss/components/tabs";
@import "scss/components/icons";
@import "scss/components/pagination";
@import "scss/components/datepicker";
@import "scss/components/quest-detail";
@import "scss/components/database-filter";
@import "scss/components/mm-jsr";
@import "scss/components/filter";
@import "scss/components/slider";
@import "scss/components/communication";
@import "scss/components/projects-tab";
@import "scss/components/project-st";
@import "scss/components/clockpicker";
@import "scss/components/detail";
@import "scss/components/detail-field";
@import "scss/components/detail-select-field";
@import "scss/components/detail-selectable-field";
@import "scss/components/detail-textarea";
@import "scss/components/detail-textfield";
@import "scss/components/profile";
@import "scss/components/role";

@import "scss/simplelightbox";

@import "scss/helpers";
@import "scss/sliding-content";
@import "~clockpicker/dist/bootstrap-clockpicker.css";
@import "~select2/dist/css/select2.css";
@import "~@tarekraafat/autocomplete.js/dist/css/autoComplete.css";
@import "scss/vendor/cropper";

// @import "scss/core/respo-edits";
@import "scss/components/custom-filter";
@import "scss/components/custom-info";
@import "scss/components/questionnaire";
@import "scss/components/questionnaire-bar";
@import "scss/components/questionnaire-header";
@import "scss/components/questionnaire-nav";
/* stylelint-enable */
