/* stylelint-disable */
.cont-250 {
	flex: 1;
	margin-right: auto;
	margin-left: auto;
	max-width: 250px;
}

.main-container {
	padding-left: 5vw;
}
