@use "sass:color";
@use "sass:map";
@use "../../settings/colors";
@use "../../settings/header";
@use "../../settings/layout";
@use "../../settings/transition";
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/units";
@use "../../tools/spacing";

.header {
	position: sticky;
	top: 0;
	z-index: map.get(z-index.$values, header);
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: header.$height;
	padding-inline: map.get(layout.$container, padding-inline, xs);
	border-bottom: units.px2rem(1px) solid map.get(colors.$grays, 200);
	background-color: colors.$white;

	&::before {
		content: "";
		position: absolute;
		top: header.$height;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: map.get(z-index.$values, header);
		display: none;
		height: calc(100vh - #{header.$height});
		/* stylelint-disable unit-no-unknown */
		height: calc(100dvh - #{header.$height});
		/* stylelint-enable unit-no-unknown */
		background-color: color.change(map.get(colors.$grays, 900), $alpha: 0.5);
		transition: opacity transition.$base;

		@include breakpoint.up(lg) {
			content: none;
			transition: none;
		}
	}

	@include breakpoint.up(md) {
		padding-inline: map.get(layout.$container, padding-inline, md);
	}
}

.header--nav-is-open {
	&::before {
		display: block;
		animation: fade-in 500ms forwards;
	}
}

.header__logo {
	display: flex;
	align-items: center;
	width: spacing.set(29);
	margin-right: spacing.set(11);
}

.header__logo img {
	max-width: 100%;
}
