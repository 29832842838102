/* stylelint-disable */
.questionnaire {
	padding-top: 73px;
}

.questionnaire--list {
	padding-top: 12px;
}

.questionnaire__subtitle {
	max-width: 450px;
	margin-right: auto;
	margin-left: auto;
}

.questionnaire__box {
	display: flex;
	background-color: #fff;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.questionnaire__footer,
.questionnaire__box--narrow {
	max-width: 840px;
	margin-right: auto;
	margin-left: auto;
}

.questionnaire__box__inner {
	flex: 1 0 100%;
	padding: 30px;

	@media (min-width: 900px) {
		flex: 1 0 auto;
	}
}

.questionnaire__box__inner--half {
	@media (min-width: 900px) {
		width: 50%;
	}
}

.questionnaire__box__title {
	font-family: $font-family;
	font-size: 16px;
	font-weight: 600;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	color: #313131;
}

.questionnaire__info {
	max-width: 600px;
	padding: 20px 30px;
	margin-right: auto;
	margin-bottom: 25px;
	margin-left: auto;
	font-family: $font-lato;
	font-size: 14px;
	font-weight: 400;
	line-height: 19px;
	text-align: center;
	color: #ffffff;
	border-radius: 4px;
	background-color: #46546a;
}

.questionnaire__create {
	max-width: 640px;
	padding: 15px 20px 20px;
	margin-right: auto;
	margin-left: auto;
	border-radius: 6px;
	background-color: #fff;
	box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.15);
}

.questionnaire__create__title {
	margin-bottom: 16px;
	font-family: $font-family;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	color: #313131;
}

.questionnaire__create__select {
	position: relative;
	display: flex;
	border-radius: 4px;
	background-color: #eeeeee;
}

.questionnaire__create__select > input {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 30px;
	font-family: $font-family;
	font-size: 11px;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
	border-radius: 4px;
	border: 0;
	color: #fe5c4f;
	background-color: #eeeeee;
	outline: 0;
	cursor: pointer;
	transition: color .2s ease, background .2s ease;

	&:focus,
	&:hover {
		color: darken($lightGreyBtnText, 10%);
	}
}

.questionnaire__create__input {
	position: relative;
	flex: 1 0 auto;
	width: auto;
	margin: 5px 0 5px 5px;
	border-radius: 4px;
}

.questionnaire__create__input > input {
	border: 0;
	background-color: #fff;
}
/* stylelint-enable */
