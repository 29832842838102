/* stylelint-disable */
$_role-box-group-width: 294px;
$_role-box-size: 36px;
$_role-border-radius: 4px;

.role-box-group {
	position: relative;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 2px 4px;
	width: $_role-box-group-width;
	max-width: calc(100% - #{$_role-box-size});
	padding: 4px;
	margin-right: 24px;
	border: 1px solid $lightGrey;
	border-radius: $_role-border-radius;
}

.role-box-group--no-right-radius {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.role-box-group--hashtags {
	background-color: $white;
}

.role-box-group--no-bottom-right-radius {
	border-bottom-right-radius: 0;
}

.role-box {
	z-index: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: $_role-box-size;
	font-size: 14px;
	line-height: 17px;
	font-family: $font-lato;
	color: #989898;
	border-width: 0;
}

.role-box--btn {
	display: inline-flex;
	width: 137px;
	height: 46px;
	padding-left: 16px;
	white-space: nowrap;
	border-width: 1px 0 1px 1px;
	border-style: solid;
	border-color: $lightGrey;
	border-top-left-radius: $_role-border-radius;
	border-bottom-left-radius: $_role-border-radius;
}

.role-box__inner {
	position: relative;
	z-index: -1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 6px;
	overflow: hidden;
	color: #787878;
	border-radius: 3px;
	background-color: $lightGrey;
	transition: background 0.2s ease;
}

.role-add-wrap {
	width: calc(100% + #{$_role-box-size});
}

.role-add {
	display: flex;
	grid-column: auto / span 2;
	order: 1;
	height: 44px;
	border-radius: $_role-border-radius;
	background-color: $lightGrey;
}

.role-box__add {
	position: absolute;
	right: -33px;
	bottom: -1px;
	flex: 0 0 $_role-box-size;
	width: $_role-box-size;
	height: 46px;
	border-width: 1px 1px 1px 0;
	border-style: solid;
	border-color: $lightGrey;
	border-radius: $_role-border-radius;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	background-image: url(../../img/icons/icon-add-grey.svg);
	background-position: center;
	background-size: 15px;
	background-repeat: no-repeat;
	background-color: $white;
}

.role-box-group--hashtags .role-box__add {
	right: -36px;
}

.role-box--block {
	grid-column: auto / span 2;
}

.role-box-group .role-box:last-child {
	border-right-width: 0;
}

.role-box:not(.role-box--nohover):hover .role-box__inner {
	background-color: #d8d8d8;
}

.role-box-group .role-box:not(.role-box--nohover):hover .role-box__inner {
	background-color: $lightGrey;
}

.role-box-group-fluid > .role-add {
	width: 100%;
}

.role-box-group .role-box + .role-box:last-child,
.role-box-group .role-add:not(:only-child) {
	margin-top: 0;
	border-top-left-radius: 0;
}

.role-box-group--no-outline {
	width: $_role-box-group-width + $_role-box-size;
	padding: 0;
	border-width: 0;
}

.role-box__add--with-corner {
	&::before,
	&::after {
		content: '';
		position: absolute;
		height: 6px;
		background-color: $white;
		pointer-events: none;
	}

	&::before {
		top: -7px;
		right: 25px;
		z-index: 2;
		width: 6px;
		border-bottom: 1px solid $lightGrey;
		border-left: 1px solid $lightGrey;
		border-radius: 0 $_role-border-radius 0 $_role-border-radius;
	}

	&::after {
		top: -6px;
		left: 0;
		width: calc(100% - 29px);
	}
}

.role-box-group .role-box:not(:last-child) .role-box__add {
	display: none;
}

.role-box-group .role-add:not(:only-child) {
	position: relative;

	&::before {
		top: -6px;
		right: 30px;
	}

	&::after {
		background-color: $lightGrey;
	}
}

.role-box-group .role-add:not([hidden]) ~ .role-box,
.role-box-group--grey .role-box + .role-box:not(:last-child) {
	background-color: transparent;
}

.role-box__inner__text {
	flex: 0 0 calc(100% - 20px);
	width: 50px;
	padding-left: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	transition: margin 0.2s ease;
}

.role-box-group:not(.role-box-group--hashtags) .role-add[hidden] ~ .role-box:last-child {
	width: calc(100% + #{$_role-box-size});
	border-top: 1px solid $lightGrey;
	border-top-right-radius: 3px;
}

.role-box-group:not(.role-box-group--hashtags) .role-box {
	position: relative;
}

.role-box-group-fluid {
	width: auto;
	max-width: none;
	margin-right: 0;
}

.role-box-form-wrap.role-add {
	order: 2;
}

.role-box.addHashTag {
	justify-content: space-between;
	padding-left: 10px;
}

.role-box-form {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-left: 4px;
}

.role-box-form-hash {
	opacity: 0.5;
	margin-right: 9px;
	font-size: 14px;
	line-height: 17px;
	font-family: $font-lato;
	color: $darkGrey;
}

.role-box-form > .select2-container {
	position: relative;
	flex: 1;
}

.role-box-group--grey {
	border-radius: $_role-border-radius;
	background-clip: content-box;
	background-color: $lightGrey;
}

.role-box__rate--trash {
	position: absolute;
	left: 4px;
	opacity: 0;
	background-image: url(../../img/icons/icon-delete.svg);
	background-position: center;
	background-size: auto 65%;
	background-repeat: no-repeat;
}

.role-box__trash {
	position: absolute;
	top: 9px;
	right: 9px;
	opacity: 0;
	width: 15px;
	height: 15px;
	padding: 0;
	color: transparent;
	border: 0;
	background-image: url(../../img/icons/icon-delete-dark.svg);
	background-position: center;
	background-size: 90%;
	background-repeat: no-repeat;
	background-color: transparent;
	cursor: pointer;
	transition: opacity 0.2s ease;
	appearance: none;
}

.role-box:not(.role-box--nohover):hover .role-box__trash {
	opacity: 0.8;
}

.role-box__btn-over {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
	color: $white;
	border: 0;
	border-radius: 3px;
	background-color: $main-color;
	cursor: pointer;
	transition: opacity 0.2s ease;
}

.role-box:not(.role-box--nohover):hover .role-box__btn-over,
.role-box:not(.role-box--nohover):hover .role-box__rate--trash {
	opacity: 1;
}

.role-box:not(.role-box--nohover):hover .role-box__inner__text {
	padding-right: 30px;
	margin-right: -30px;
	margin-left: 30px;
}

.role-box:not(.role-box--nohover):hover.role-box--viewer:hover .role-box__inner__text {
	margin-right: 0;
	margin-left: 0;
}

.role-box__inner__text--fluid {
	flex-basis: 100%;
}

.role-box__rate {
	display: flex;
	flex: 0 0 29px;
	align-items: center;
	justify-content: center;
	width: 29px;
	height: 20px;
	font-weight: 600;
	font-size: 11px;
	line-height: 14px;
	font-family: $font-family;
	color: $white;
	border-radius: 5px;
	background-color: #d8d8d8;
	cursor: pointer;
	transition-property: box-shadow, opacity;
	transition-duration: 200ms;
	transition-timing-function: ease;

	&::before {
		content: " ";
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	&:not([data-score='0']) {
		&::after {
			content: attr(data-score);
			margin-left: 4px;
		}
	}

	&:hover {
		box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.7);
	}
}

.role-box--nohover .role-box__rate {
	cursor: default;

	&:hover {
		box-shadow: none;
	}
}

.role-box__votes-wrap {
	position: relative;
	display: flex;
	flex-direction: column;
	grid-row-gap: 2px;
	align-items: flex-end;
	justify-content: center;
	width: 30px;
	height: 32px;
	padding: 6px;
}

.role-box__vote {
	width: 6px;
	height: 6px;
	font-size: 0;
	border-radius: 50%;
	background-color: $grey;
	cursor: pointer;
}

.role-box__vote--up.is-active {
	background-color: $alert-green;
}

.role-box__vote--down.is-active {
	background-color: $alert-orange;
}

.role-box__vote--stop.is-active {
	background-color: $alert-red;
}

.role-box__rates-wrap {
	position: absolute;
	top: 2px;
	right: 0;
	bottom: 2px;
	display: flex;
	visibility: hidden;
	opacity: 0;
	grid-column-gap: 3px;
	align-items: center;
	justify-content: center;
	padding-right: 3px;
	padding-left: 3px;
	border: 1px solid $main-color;
	border-radius: 9px 3px 3px 9px;
	background-color: $white;
	box-shadow: 0 3px 6px 0 transparentize($black, 0.5);
	transition-property: box-shadow, opacity, visibility;
	transition-duration: 300ms;
	transition-timing-function: ease-out;
}

.role-box__rates-wrap:hover,
.role-box__rates-wrap:focus,
.role-box__votes-wrap:hover + .role-box__rates-wrap,
.role-box__votes-wrap:focus + .role-box__rates-wrap {
	visibility: visible;
	opacity: 1;
}

.role-box__rate--up::before {
	width: 14px;
	height: 14.67px;
	background-image: url(../../img/icons/icon-thumb-up.svg);
}

.role-box__rate--up:not([data-score='0']) {
	background-color: $alert-green;

	&::before {
		width: 11.75px;
		height: 11.92px;
		background-image: url(../../img/icons/icon-thumb-up-white.svg);
	}
}

.role-box__rate--down::before {
	width: 14.46px;
	height: 14.67px;
	margin-top: 2px;
	background-image: url(../../img/icons/icon-thumb-up.svg);
	transform: rotate(180deg);
}

.role-box__rate--down:not([data-score='0']) {
	background-color: $alert-orange;

	&::before {
		width: 11.75px;
		height: 11.92px;
		background-image: url(../../img/icons/icon-thumb-up-white.svg);
	}
}

.role-box__rate--stop::before {
	width: 13.13px;
	height: 14.84px;
	background-image: url(../../img/icons/icon-thumb-stop.svg);
}

.role-box__rate--stop:not([data-score='0']) {
	background-color: $alert-red;

	&::before {
		width: 10.67px;
		height: 12.06px;
		background-image: url(../../img/icons/icon-thumb-stop-white.svg);
	}
}

.role-add .input-wrapper {
	height: 35px;
	margin: 5px;
}

.role-add .custom-select__input {
	height: 35px;
	border-color: transparent;
	background-color: $white;
}

.role-add .custom-select__arrow {
	top: 9px;
}

.role-box--old {
	position: relative;
	padding: 5px;
	height: 44px;
	border: 1px solid #eee;
}
/* stylelint-enable */
