@use "sass:map";
@use "../../settings/typography" as typography-settings;
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.lead {
	@include typography.style(lead);
}

.h1 {
	@include typography.style(heading1);
}

.h2 {
	@include typography.style(heading2);
}

.h2 small {
	font-size: units.px2em(13px, map.get(typography-settings.$font-size-values, 9));
}

.h2--account-section {
	margin-block: spacing.set(6);

	@include breakpoint.up(lg) {
		margin-block: spacing.set(8);
	}
}

.h3 {
	@include typography.style(heading3);
}

.h4 {
	@include typography.style(heading4);
}
