@use "sass:color";
@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/header";
@use "../../settings/typography" as typography-settings;
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.nav-box {
	padding: spacing.set(1) spacing.set(2);
	margin-inline: spacing.set(-2);
	margin-bottom: spacing.set(8);
	font-family: typography-settings.$font-family-primary;
	text-align: center;
	border: units.px2rem(1px) solid map.get(colors.$grays, 300);
	border-radius: map.get(border-radius.$values, medium);
	background-color: map.get(colors.$grays, 066);

	@include breakpoint.up(lg) {
		padding: spacing.set(1) spacing.set(6);
		margin-inline: unset;
		margin-bottom: unset;
	}
}

.nav-box__title {
	@include typography.style(text2);

	margin-bottom: 0;
	font-weight: map.get(typography-settings.$font-weight-values, semibold);
	font-family: typography-settings.$font-family-primary;
	letter-spacing: units.px2em(-0.1px, map.get(typography-settings.$font-size-values, 3));
	color: map.get(colors.$grays, 800);
}

.nav-box__type {
	padding: 0;
	font-weight: map.get(typography-settings.$font-weight-values, semibold);
	color: colors.$primary;
}

.nav-box__type--button {
	cursor: pointer;
}

.nav-box__cta {
	@include typography.style(text3);

	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-family: typography-settings.$font-family-primary;
	text-decoration: underline;
	color: colors.$primary;
	cursor: pointer;
}

.nav-box__date {
	@include typography.style(text3);

	margin-block: 0;
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	letter-spacing: units.px2em(-0.1px, map.get(typography-settings.$font-size-values, 1));
	color: map.get(colors.$grays, 700);
}

.nav-box__date span {
	color: map.get(colors.$grays, 800);
}

.dropdown .nav-box {
	margin-bottom: spacing.set(6);

	@include breakpoint.up(lg) {
		margin-inline: spacing.set(-2);
		margin-bottom: spacing.set(4);
	}
}
