@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/theme";
@use "../../settings/transition";
@use "../../settings/typography" as typography-settings;
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.select {
	@include typography.style(label);

	padding: units.px2rem(9px) spacing.set(7) units.px2rem(9px) spacing.set(0);
	line-height: map.get(typography-settings.$line-height-values, 0);
	color: theme.$page-text-color;
	border: 0;
	appearance: none;
	background-color: transparent;
	pointer-events: auto;
	cursor: pointer;
}

.select:not(select) {
	pointer-events: none;
}

.select-hidden {
	position: absolute;
	z-index: -1;
	opacity: 0;
	width: 0;
	height: 0;
	pointer-events: none;
}
