/* stylelint-disable */
.custom-filter {
	width: 100%;
	margin-top: 25px;
	border-radius: 8px;
	border: 1px solid #eeeeee;
	background-color: #ffffff;
}

.custom-filter__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
}

.custom-filter__title {
	margin-right: 20px;
	font-family: $font-family;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: -0.02px;
	text-align: left;
	color: #313131;
}

.custom-filter__clear {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 7px 8px;
	font-family: $font-family;
	font-size: 9px;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
	color: #787878;
	border: 0;
	border-radius: 4px;
	background-color: #ececec;
	outline: 0;
	cursor: pointer;

	&:hover {
		color: $main-color;
	}
}

.custom-filter__row {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 5px;
	margin: 0 30px 5px 10px;
	border-radius: 4px;
	border: 1px solid #eeeeee;
	background-color: #eeeeee;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 14px;
		height: 2px;
		box-sizing: border-box;
		background-color: #787878;
		border-radius: 2px;
	}

	&::before {
		transform: translate(-50%, -50%) translateY(-2px);
	}

	&::after {
		transform: translate(-50%, -50%) translateY(2px);
	}
}

.custom-filter__row__delete {
	position: absolute;
	top: 50%;
	left: 100%;
	transform: translateY(-50%);
	padding: 5px;
}

.custom-filter__input {
	margin: 0;

	&:first-of-type {
		margin-bottom: 30px;
	}
}

.custom-filter__input > .input {
	background-color: #fff;
}

.custom-filter__footer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 30px 15px 10px;
}

.custom-filter__add {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 10px 8px 30px;
	border-radius: 4px;
	background-color: #ececec;
	font-family: $font-family;
	font-size: 11px;
	font-weight: 600;
	text-transform: uppercase;
	color: #787878;
	cursor: pointer;
	outline: 0;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 10px;
		height: 2px;
		width: 13px;
		border: 0;
		border-radius: 2px;
		box-sizing: border-box;
		background-color: currentColor;
	}

	&::before {
		transform: translateY(-50%);
	}

	&::after {
		transform: translate(0.5px, -50%) rotate(90deg);
	}

	&:hover {
		color: $main-color;
	}
}

.custom-filter--wide .custom-filter__row {
	@media (min-width: map-get($breakpoints, md)) {
		flex-direction: row;
	}
}

.custom-filter--wide .custom-filter__row > .custom-filter__input {
	@media (min-width: map-get($breakpoints, md)) {
		max-width: 45%;
		margin-bottom: 0;
	}
}
/* stylelint-enable */
