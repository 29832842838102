@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.select-checkbox {
	position: relative;
	display: flex;
	align-items: flex-start;
}

.select-checkbox--is-disabled {
	pointer-events: none;
}

.select-checkbox__input {
	position: absolute;
	top: 0;
	left: 0;
	z-index: map.get(z-index.$values, base);
	opacity: 0;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	cursor: pointer;
}

.select-checkbox__label {
	@include typography.style(label-checkbox);

	position: relative;
	z-index: 2;
	display: block;
	padding-bottom: spacing.set(3);
	padding-left: spacing.set(6);
	text-align: left;

	&::before,
	&::after {
		content: "";
		position: absolute;
		width: units.px2rem(20px);
		height: units.px2rem(20px);
	}

	&::before {
		top: units.px2rem(-3px);
		left: 0;
		border: units.px2rem(1px) solid map.get(colors.$grays, 700);
		border-radius: map.get(border-radius.$values, small);
		background-color: colors.$white;
	}

	&::after {
		top: 0;
		left: units.px2rem(7px);
		display: none;
		width: units.px2rem(5px);
		height: units.px2rem(11px);
		border: solid colors.$white;
		border-width: 0 units.px2rem(2px) units.px2rem(2px) 0;
		transform: rotate(45deg);
	}
}

.select-checkbox-wrap.is-hidden {
	display: none;
}

.custom-select__option--checkbox:last-of-type .select-checkbox__label {
	padding-bottom: 0;
}

.select-checkbox__label--radio {
	&::before {
		border-radius: map.get(border-radius.$values, full);
	}

	&::after {
		left: units.px2rem(3px);
		width: units.px2rem(16px);
		height: units.px2rem(16px);
		border: 0;
		border-radius: map.get(border-radius.$values, full);
	}
}

.select-checkbox__input:checked + .select-checkbox__label {
	&::before {
		border-color: colors.$primary;
		background-color: colors.$primary;
	}

	&::after {
		display: block;
	}
}

.select-checkbox__input:checked + .select-checkbox__label--radio {
	&::before {
		background-color: colors.$white;
	}

	&::after {
		background-color: colors.$primary;
	}
}
