@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/box-shadow";
@use "../../settings/colors";
@use "../../settings/layout";
@use "../../settings/typography" as typography-settings;
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/units";

.page-box {
	position: relative;
	width: 100%;
	max-width: map.get(layout.$container, width, small);
	margin-bottom: units.px2rem(10px);
	overflow: hidden;
	border-radius: map.get(border-radius.$values, small);
	background-color: colors.$white;
	box-shadow: map.get(box-shadow.$values, small);
}

.page-box__inner {
	padding: units.px2rem(30px) units.px2rem(20px);
}

.page-box__inner-15 {
	padding: spacing.set(3);
}

.page-box__inner-irreg {
	padding: units.px2rem(25px) units.px2rem(16px) units.px2rem(16px) units.px2rem(27px);
}

.page-box__inner-big {
	padding: units.px2rem(66px) units.px2rem(32px) spacing.set(3);
}

.page-box__title {
	display: inline-block;
	width: 100%;
	margin: 0 auto units.px2rem(35px) auto;
}

.page-box__slim {
	width: 90%;
	max-width: units.px2rem(299px);
	margin: 0 auto;
}

.page-box__grey {
	position: relative;
	z-index: 1;
	display: inline-block;
	width: 100%;

	&::before {
		content: " ";
		position: absolute;
		top: 0;
		left: spacing.set(-3);
		z-index: -1;
		width: 110%;
		max-width: 100vw;
		height: 100%;
		border-top: units.px2rem(1px) solid map.get(colors.$grays, 300);
		border-bottom: units.px2rem(1px) solid map.get(colors.$grays, 300);
		background-color: map.get(colors.$grays, 100);
	}
}

.page-box__btn-wrapper {
	text-align: center;

	@include breakpoint.up(sm) {
		text-align: right;
	}
}

.page-box__alert {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: spacing.set(6);
	padding: units.px2rem(8px) spacing.set(2);
	font-size: map.get(typography-settings.$font-size-values, 3);
	line-height: map.get(typography-settings.$line-height-values, 0);
	text-align: center;
	color: colors.$white;
	border: units.px2rem(1px) solid transparent;
	border-radius: map.get(border-radius.$values, medium);
	background-color: map.get(colors.$brand, red);
}

.page-box__buttons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: spacing.set(4);

	@include breakpoint.up(sm) {
		flex-direction: row;
	}
}

.page-box__buttons--small {
	justify-content: space-between;
	gap: spacing.set(2) units.px2rem(2px);
}
