@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/box-shadow";
@use "../../settings/header";
@use "../../settings/layout";
@use "../../settings/typography" as typography-settings;
@use "../../settings/theme";
@use "../../settings/transition";
@use "../../tools/spacing";
@use "../../tools/units";
@use "../../tools/typography";

.custom-select__input {
	padding-right: spacing.set(5);

	&:disabled {
		opacity: 0.5;
	}

	&::placeholder {
		opacity: 1;
		color: map.get(colors.$grays, 700);
	}
}

.custom-select__input ~ .js-customSelect {
	position: absolute;
	top: 0;
	visibility: hidden;
	opacity: 0;
	width: 100%;
	height: 0;
	padding: 0;
	border: 0;
}

.custom-select__options {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 10;
	display: none;
	width: 100%;
	max-height: 180px;
	padding: 0;
	overflow-x: hidden;
	overflow-y: auto;
	font-family: typography-settings.$font-family-secondary;
	color: map.get(colors.$grays, 700);
	border-radius: 0 0 map.get(border-radius.$values, small) map.get(border-radius.$values, small);
	background-color: colors.$white;
	box-shadow: map.get(box-shadow.$values, small);
	cursor: pointer;
}

.custom-select__options--multiple {
	padding: spacing.set(3) spacing.set(2);
}

.custom-select__options--up {
	top: auto;
	bottom: calc(100% + #{units.px2rem(8px)});
	border-radius: map.get(border-radius.$values, small);
}

.custom-select__input--focus ~ .custom-select__options,
.custom-select__input:focus ~ .custom-select__options,
.custom-select__input.is-open ~ .custom-select__options {
	display: block;
}

.custom-select__arrow {
	position: absolute;
	top: 50%;
	right: spacing.set(1);
	opacity: 0.4;
	width: units.px2rem(18px);
	height: units.px2rem(18px);
	background-image: url(../../../img/icons/icon-chevron-down.svg);
	background-position: center;
	background-size: spacing.set(2);
	background-repeat: no-repeat;
	transform: translateY(-50%) rotate(0deg);
	pointer-events: none;
	transition: transform transition.$base;
}

.custom-select__input--focus ~ .custom-select__arrow,
.custom-select__input--focus .custom-select__arrow,
.custom-select__input:focus ~ .custom-select__arrow,
.custom-select__input.is-open ~ .custom-select__arrow,
.custom-select__input.is-open .custom-select__arrow,
.custom-select__input:focus .custom-select__arrow {
	opacity: 1;
	background-image: url(../../../img/icons/icon-chevron-down-color.svg);
	transform: translateY(-50%) rotate(180deg);
}

.custom-select__option {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	padding: spacing.set(2) units.px2rem(12px);
	cursor: pointer;

	&:hover {
		background-color: map.get(colors.$grays, 200);
	}
}

.custom-select__option[data-disabled="true"] {
	display: none;
	color: lighten(map.get(colors.$grays, 200), 50%);
	cursor: default;

	&:hover {
		background-color: transparent;
	}
}

.custom-select__option.is-hidden {
	display: none;
}

.custom-select__option[data-disabled="false"] {
	display: none;
}

.custom-select__option-not-found {
	display: none;
	padding: spacing.set(2) units.px2rem(12px);
	font-weight: map.get(typography-settings.$font-weight-values, bold);
}

.custom-select__option:not[data-disabled="false"] ~ .custom-select__option-not-found {
	display: block;
}

.select-checkbox-wrap--checkbox.is-hidden ~ .custom-select__option-not-found {
	display: block;
}

.select-checkbox-wrap--checkbox:not(.is-hidden) ~ .custom-select__option-not-found {
	display: none;
}

.custom-select__option span {
	padding-left: spacing.set(1);
	text-align: right;
	color: rgba(70, 69, 135, 0.35);
}

.custom-select__input.custom-select__input--multiple:focus ~ .custom-select__options,
.custom-select__input.custom-select__input--multiple.custom-select__input--focus ~ .custom-select__options,
.custom-select__input.custom-select__input--multiple.is-open ~ .custom-select__options {
	display: block;
}
