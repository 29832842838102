@use "sass:map";
@use "../../settings/header";
@use "../../settings/colors";
@use "../../settings/typography" as typography-settings;
@use "../../settings/theme";
@use "../../tools/typography";
@use "../../tools/spacing";
@use "../../tools/units";

html {
	height: 100;
	font-size: 16px;
	text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-padding-top: calc(#{header.$height} + #{spacing.set(8)});
	scroll-behavior: smooth;
}

body {
	@include typography.style(body);

	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	justify-content: center;
	min-height: 100vh;
	color: theme.$page-text-color;
	background-color: theme.$page-background-color;
}

hr {
	display: block;
	height: units.px2rem(1px);
	border-width: 0;
	background-color: map.get(colors.$grays, 300);
}
