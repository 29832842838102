@use "sass:map";
@use "../../settings/typography" as typography-settings;
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/spacing";

.search {
	margin-top: spacing.set(2);

	@include breakpoint.up(lg) {
		position: absolute;
		bottom: 100%;
		display: none;
		width: spacing.set(52);
		margin-top: unset;
	}
}

.search--is-open {
	display: block;
}

.search__wrap {
	position: relative;
}

.search__icon {
	position: absolute;
	top: 50%;
	left: spacing.set(2);
	z-index: map.get(z-index.$values, base);
	display: flex;
	align-items: center;
	font-size: map.get(typography-settings.$font-size-values, 8);
	transform: translateY(-50%);

	@include breakpoint.up(lg) {
		display: none;
	}
}

.search__reset {
	position: absolute;
	top: 50%;
	right: spacing.set(3);
	display: none;
	font-size: map.get(typography-settings.$font-size-values, 8);
	text-decoration: none;
	transform: translateY(-50%);
}

.input--search:not(:placeholder-shown) ~ .search__reset {
	display: block;
}

/* stylelint-disable selector-max-specificity */
.table-layout__detail:not(:empty) ~ .table-layout__table .search {
	position: static;
	display: block;
	width: 100%;
	margin-top: spacing.set(2);

	@include breakpoint.up(xxxxl) {
		position: absolute;
		bottom: 100%;
		width: spacing.set(52);
		margin-top: unset;
	}
}

.table-layout__detail:not(:empty) ~ .table-layout__table .search:not(.search--is-open) {
	@include breakpoint.up(xxxxl) {
		display: none;
	}
}
/* stylelint-enable selector-max-specificity */

.table-layout__detail:not(:empty) ~ .table-layout__table .search__icon {
	display: flex;

	@include breakpoint.up(xxxxl) {
		display: none;
	}
}
