@use "sass:map";
@use "../../settings/colors";
@use "../../settings/header";
@use "../../settings/layout";
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/units";
@use "../../tools/spacing";

.main {
	flex: 1 0 100%;
	max-width: 100%;
	min-height: calc(100vh - header.$height);
	margin: 0 auto auto;
}

.main-content {
	flex: 1 0 30%;
	max-width: calc(1140px + 10vw);
	padding-top: spacing.set(6);
	padding-right: units.px2rem(8px);
	padding-bottom: spacing.set(16);
	padding-left: units.px2rem(8px);
}
