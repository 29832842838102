@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/theme";
@use "../../settings/typography" as typography-settings;
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.file-line {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	gap: spacing.set(2) spacing.set(3);
	margin-block: spacing.set(3);
}

.file-line__label {
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	cursor: pointer;
}

.file-line__wrap {
	display: flex;
	flex-flow: column;
	justify-content: center;
	font-size: map.get(typography-settings.$font-size-values, 5);
	letter-spacing: units.px2em(0.24px, map.get(typography-settings.$font-size-values, 5));
	color: map.get(colors.$grays, 700);
}

.file-line__control {
	position: relative;
}

.file-line__btns {
	display: none;
	flex-wrap: wrap;
	gap: spacing.set(2) spacing.set(3);
}

.file-line__btns--visible {
	display: flex;
}

.file-line.has-file .file-line__btns {
	display: flex;
}

.file-line__pseudo-btn {
	opacity: 0.5;
	cursor: pointer;
}

.file-line__pseudo-btn--hidden {
	display: none;
}

.file-line.has-file .file-line__pseudo-btn {
	display: none;
}

.file-line__btn {
	padding: units.px2rem(2px) 0;
	appearance: none;
	text-decoration: underline;
	border: 0;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}

.file-line__btn--default {
	color: theme.$link-color;
}

.file-line__btn--danger {
	color: colors.$primary;
}

.file-line__image {
	display: block;
	object-fit: cover;
	width: 100%;
	height: 100%;
	background-color: colors.$white;
	pointer-events: none;
}

.file-line__image[src=""],
.file-line__image:not([src]) {
	display: none;
}

.file-line__preview {
	position: relative;
	display: block;
	width: 100%;
	aspect-ratio: 21 / 9;
	overflow: hidden;
	border-radius: map.get(border-radius.$values, medium);
	background-image: url(../../../img/icon-profile-def.svg);
	background-position: center bottom;
	background-size: auto 90%;
	background-repeat: no-repeat;
	background-color: map.get(colors.$grays, 200);
	cursor: pointer;

	@include breakpoint.up(sm) {
		flex: 0 0 spacing.set(20);
		aspect-ratio: unset;
		height: spacing.set(10);
	}
}
