@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/box-shadow";
@use "../../tools/breakpoint";
@use "../../settings/colors";
@use "../../settings/transition";
@use "../../settings/typography" as typography-settings;
@use "../../settings/z-index";
@use "../../tools/spacing";
@use "../../tools/units";
@use "../../../fonts/icons/icons";

.info-toggle {
	position: relative;
	margin-left: auto;
}

.info-toggle__input {
	position: absolute;
	top: 0;
	left: 0;
	z-index: map.get(z-index.$values, base);
	visibility: hidden;
	opacity: 0;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}

.info-toggle__label .icon {
	cursor: pointer;

	&::before {
		content: map-get(icons.$icons-map, visible);
	}
}

/* stylelint-disable selector-max-specificity */
.info-toggle__input:checked ~ .info-toggle__label .icon::before {
	content: map-get(icons.$icons-map, non-visible);
}
/* stylelint-enable selector-max-specificity */
