/* stylelint-disable */
.btn--block {
	display: block;
	width: 100%;
}
.btn--nohover {
	cursor: default;
}
.btn--small {
	padding: 10px;
	height: auto;
	font-size: 9px;
	&.btn--downArrow {
		padding-right: 7px;
		padding-left: 18px;
		&::after {
			background-size: 8px;
			margin-left: 3px;
			height: 17px;
			width: 17px;
		}
	}
}
.btn--med {
	padding: 10px 7px;
	height: auto;
	font-size: 11px;
	&::before {
		margin-right: 6px;
	}
	&.btn--width {
		padding-left: 15px;
		padding-right: 15px;
	}
}
.btn--projects {
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.15px;
	color: #606060;
	text-transform: none;
	&:hover {
		color: $main-color;
	}
}
.btn--no-bg-transparent {
	color: $lightGreyBtnText;
	&:not(.btn--nohover):hover {
		color: darken($lightGreyBtnText, 10%);
	}
}

.btn--grey {
	background-color: $grey;
	color: white;
	&:not(.btn--nohover):hover {
		background-color: darken($grey, 10%);
	}
}
.btn--light-grey {
	background-color: $lightGrey;
	color: #787878;
	&:not(.btn--nohover):hover {
		// background-color: darken($lightGrey, 10%);
		color: $main-color;
	}
}

.btns-list {
	border-radius: 2px;
	background-color: #FFFFFF;
	box-shadow: 2px 4px 16px 0 rgba(0,0,0,0.15);
}
.btns-list--small {
	.btns-list__btn {
		text-transform: none;
		font-size: 10px;
		text-align: right;
		padding: 11px 10px;
	}
}
.btns-list--light {
	.btns-list__btn {
		text-transform: none;
		font-size: 11px;
		padding: 8px;
		padding-right: 40px;
		font-weight: 500;
		letter-spacing: 0.13px;
		line-height: 14px;
	}
}
.btns-list__btn {
	text-align: left;
	display: block;
	color: $darkGrey;
	font-size: 11px;
	font-weight: 600;
	line-height: 14px;
	text-transform: uppercase;
	text-decoration: none;
	white-space: nowrap;
	padding: 14px 12px;
	cursor: pointer;
	transition: color .2s ease, background .2s ease;
	width: 100%;
	&:not(.btn--nohover):hover {
		background-color: $hover-gray;
	}
	&.active {
		color: $main-color;
	}
}
.btn-more-photos {
	width: 100px;
	background-color: $lightGrey;
	color: $lightGreyBtnText;
	font-family: $font-family;
	font-size: 11px;
	font-weight: 600;
	line-height: 14px;
	text-align: center;
	padding: 6px;
	text-transform: uppercase;
	display: block;
	border-radius: 4px;
	cursor: pointer;
}
.btn--downArrow,
.btn--downArrow-dark {
	padding-right: 7px;
	padding-left: 23px;
	&::after {
		content: " ";
		// margin-left: 10px;
		margin-top: -4px;
		margin-bottom: -1px;
		display: inline-block;
		vertical-align: bottom;
		height: 15px;
		width: 20px;
		background-image: url(../../img/icons/icon-chevron-down-white.svg);
		background-repeat: no-repeat;
		background-size: 10px;
		background-position: center;
		transition: transform .2s ease;
	}
	&.is-open {
		&::after {
			transform: rotate(180deg);
		}
	}
	&:not(.btn--nohover):hover {
		&::after {
			opacity: 1;
		}
	}
}
.btn--downArrow-dark {
	&::after {
		background-image: url(../../img/icons/icon-chevron-down.svg);
	}
}
.btn--filter {
	padding-right: 17px;
	margin-left: 3px;
	&::before {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		height: 20px;
		width: 20px;
		background-image: url(../../img/icons/icon-filter.svg);
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 15px;
		margin-top: -6px;
		margin-bottom: -7px;
	}
	&:hover::before {
		background-image: url(../../img/icons/icon-filter-color.svg);
	}
}
.btn--filter--active {
	color: $main-color;
	&::before,
	.icon--filter {
		opacity: 1;
		background-image: url(../../img/icons/icon-filter-color.svg);
	}
	&:not(.btn--nohover):hover {
		&::before {
			background-image: url(../../img/icons/icon-filter.svg);
		}
	}
}
.btn--transfer,
.btn--transferred {
	padding-left: 25px;
	padding-right: 9px;
	&::after {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		height: 18px;
		width: 15px;
		background-image: url(../../img/icons/icon-upload-to-project.svg);
		background-repeat: no-repeat;
		background-size: contain;
		margin-left: 15px;
		margin-top: -7px;
		margin-bottom: -7px;
	}
}
.btn--transfer {
	&:hover::after {
		background-image: url(../../img/icons/icon-upload-to-project-color.svg);
	}
}
.btn--transferred {
	&::after {
		background-image: url(../../img/icons/icon-success.svg);
		margin-left: 8px;
		margin-right: 3px;
		margin-top: -5px;
		margin-bottom: -7px;
	}
}
.btn--person-del {
	padding: 12px 10px;
	&::after {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		height: 20px;
		width: 20px;
		background-image: url(../../img/icons/icon-remove-extra.svg);
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: -1px;
		margin-left: 1px;
	}
	&:hover::after {
		background-image: url(../../img/icons/icon-remove-extra-color.svg);
	}
}
.btn--trash {
	padding: 12px 10px;
	&.has-margin {
		margin-right: 18px;
	}
	&.btn--small {
		padding: 9px;
	}
	&::after {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		height: 20px;
		width: 20px;
		background-image: url(../../img/icons/icon-delete-dark.svg);
		background-repeat: no-repeat;
		background-size: 16.67px 20px;
		margin-right: -2px;
		margin-left: 2px;
	}
	&:hover::after {
		background-image: url(../../img/icons/icon-delete-color.svg);
	}
}
.btn--trash-left {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 10px 12px 50px;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 15px;
		display: block;
		height: 20px;
		width: 20px;
		background-image: url(../../img/icons/icon-delete-dark.svg);
		background-repeat: no-repeat;
		background-size: 16.67px 20px;
		transform: translateY(-50%);
	}

	&:hover::before,
	&:focus::after {
		background-image: url(../../img/icons/icon-delete-color.svg);
	}
}

.btn--upload-left {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 10px 12px 50px;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 15px;
		display: block;
		height: 20px;
		width: 24px;
		background-image: url(../../img/icons/icon-upload-dark.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		transform: translateY(-50%);
	}

	&:hover::before,
	&:focus::after {
		background-image: url(../../img/icons/icon-upload-color.svg);
	}
}

.btn--crop {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 10px 12px 50px;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 15px;
		display: block;
		height: 18px;
		width: 18px;
		background-image: url(../../img/icons/icon-crop-dark.svg);
		background-repeat: no-repeat;
		background-size: 100%;
		transform: translateY(-50%);
	}

	&:hover::before,
	&:focus::before {
		background-image: url(../../img/icons/icon-crop-color.svg);
	}
}

.btn--edit {
	padding: 12px 10px;
	color: $mediumGrey;
	transition: color 200ms ease;

	& > svg {
		width: 18px;
		height: 18px;
	}

	&:hover {
		color: $main-color;
	}
}
.cornernum {
	position: absolute;
	top: -5px;
	right: -5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $main-color;
	border-radius: 17px;
	color: white;
	height: 17px;
	min-width: 17px;
	padding: 1px;
	font-size: 11px;
	line-height: 17px;
	font-weight: 600;
	z-index: 5;
	&:empty {
		display: none;
	}
}

.btn--white {
	background-color: #fff;
	color: $lightGreyBtnText;
	&:not(.btn--nohover):hover,
	&:focus {
		background-color: $main-color;
		color: white;
		&::before {
			background-image: url(../../img/icons/icon-add-white.svg);
			opacity: 1;
		}
	}
	&::before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 20px;
		width: 20px;
		margin-top: -13px;
		margin-bottom: -11px;
		margin-right: 3px;
		margin-left: -5px;
		background-image: url(../../img/icons/icon-add.svg);
		background-repeat: no-repeat;
		background-size: 10px;
		background-position: center;
		opacity: .5;
	}
}

.btns-switch {
	display: inline-block;
	border: 1px solid #E8E8E8;
	border-radius: 6px;
	background-color: #FFFFFF;
	padding: 5px;
	margin-top: 26px;
	margin-bottom: 28px;
}

.magic-btns {
	position: relative;
	font-size: 0;
	white-space: nowrap;
	min-width: 160px;
	&::before {
		content: attr(data-value);
		display: block;

		font-size: 11px;
		font-weight: 600;
		line-height: 14px;
		text-align: center;
		height: 44px;
		padding: 15px;
		box-sizing: border-box;
		border-radius: 4px;
		text-transform: uppercase;
		border: 0;
		cursor: pointer;
		text-decoration: none;
		background-color: $main-color;
		color: white;
	}
	.btn {
		position: absolute;
		top: 0;
		width: 50%;
		border-radius: 0;
		opacity: 0;
		&:first-child {
			border-radius: 4px 0 0 4px;
			left: 0;
		}
		&:last-child {
			border-radius: 0 4px 4px 0;
			right: 0;
		}
	}
	&:hover {
		.btn {
			opacity: 1;
		}
	}
}

.filter-btns-group {
	display: inline-flex;
}

.filter-btns-group.is-open {
	position: relative;

	&::before,
	&::after {
		content: '';
		position: absolute;
		width: 200vw;
		left: -100vw;
		height: 1px;
		background-color: #EEEEEE;
	}

	&::before {
		top: -7px;
	}

	&::after {
		bottom: -7px;
	}
}

.filter-reset-x {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	padding-left: 5px;
	padding-right: 5px;
	order: 1;
	margin-left: 1px;
	@extend .btn--light-grey;
	&:hover {
		background-color: #DDDDDD;
	}
	@extend .icon--cross;
	+ .btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.btn--download {
	float:right;
	margin-left: 3px;
	margin-right: 7px;
	height:34px;
	padding: 11px;

	&::before {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		height: 20px;
		width: 20px;
		background-image: url(../../img/icons/icon-download.svg);
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 15px;
		margin-top: -6px;
		margin-bottom: -7px;
	}
	&:hover::before {
		background-image: url(../../img/icons/icon-download-color.svg);
	}
}

.btn--grey-dropdown-blank {
	background-color: #ECECEC !important;
	color: #787878 !important;
}

.btn--grey-dropdown {
	background-color: #ECECEC !important;
	color: #787878 !important;

	&::before {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		height: 20px;
		width: 20px;
		background-image: url(../../img/icons/icon-download.svg);
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 5px;
		margin-top: -6px;
		margin-bottom: -7px;
	}
}

.btn--transfer-dd {
	padding-right: 9px;
	&::before {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		height: 18px;
		width: 15px;
		background-image: url(../../img/icons/icon-upload-to-project.svg);
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 15px;
	}
}

.btn--pdf-dd {
	padding-right: 9px;
	&::before {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		height: 18px;
		width: 15px;
		background-image: url(../../img/icons/icon-download.svg);
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 15px;
	}
}
.btn--person-del-dd {
	padding-right: 9px;
	&::before {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		height: 18px;
		width: 15px;
		background-image: url(../../img/icons/icon-remove-extra-color.svg);
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 15px;
	}
}

.btn--trash-dd {
	padding-right: 9px;
	&::before {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		height: 18px;
		width: 15px;
		background-image: url(../../img/icons/icon-delete-color.svg);
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 15px;
	}
}

.btn--trash-dd-dark::before {
	background-image: url(../../img/icons/icon-delete-dark.svg);
}
.btn--versions {
	width: 110px;
}

.btn--condensed {
	height: 34px;
	padding: 7px 10px;
	font-size: 11px;
	line-height: 1.8;
}

.btn--condensed.btn--downArrow,
.btn--condensed.btn--downArrow-dark {
	padding-right: 4px;

	&::after {
		margin-bottom: 2px;
	}
}

/* stylelint-enable */
