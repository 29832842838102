@use "sass:color";
@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/box-shadow";
@use "../../settings/colors";
@use "../../settings/header";
@use "../../settings/layout";
@use "../../settings/transition";
@use "../../settings/typography" as typography-settings;
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.dropdown-table-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: spacing.set(2) spacing.set(3);
	margin-top: spacing.set(2);
}

.dropdown {
	position: relative;
	display: inline-block;
	text-align: left;
}

.dropdown--nav {
	@include breakpoint.up(lg) {
		margin-left: spacing.set(4);
	}
}

.dropdown--lang-only {
	position: absolute;
	top: spacing.set(3);
	right: spacing.set(2);

	@include breakpoint.up(sm) {
		right: spacing.set(3);
	}
}

.dropdown--bulk-actions {
	flex: 1;

	@include breakpoint.up(lg) {
		flex: unset;
	}
}

.dropdown--right {
	margin-left: auto;
}

.dropdown-table-wrap .dropdown:not[hidden="hidden"] {
	flex: 1;
}

.dropdown__content {
	position: absolute;
	top: calc(100% + 6px);
	z-index: 1000;
	display: none;
	flex-direction: column;
	min-width: spacing.set(39);
	max-width:
		calc(
			100vw - #{map.get(layout.$container, padding-inline, xs)} - #{map.get(layout.$container, padding-inline, xs)}
		);
	max-height: spacing.set(98);
	padding: spacing.set(4) spacing.set(5);
	overflow: auto;
	background-color: colors.$white;
	box-shadow: map.get(box-shadow.$values, small);
	pointer-events: none;
	transition: opacity transition.$base;
}

.dropdown__content--right {
	right: 0;
}

.dropdown__content--header {
	position: static;
	display: block;
	min-width: 0;
	max-height: unset;
	padding: 0;
	overflow: unset;
	text-align: left;
	box-shadow: none;
	pointer-events: unset;
	transition: unset;

	@include breakpoint.up(lg) {
		position: absolute;
		top: calc(100% + 6px);
		right: 0;
		z-index: 1000;
		display: none;
		min-width: spacing.set(46);
		max-height: spacing.set(98);
		padding: spacing.set(4) spacing.set(5);
		overflow: auto;
		background-color: colors.$white;
		box-shadow: map.get(box-shadow.$values, small);
		pointer-events: none;
		transition: opacity transition.$base;
	}
}

.dropdown__content--lang {
	min-width: 0;

	@include breakpoint.up(lg) {
		min-width: spacing.set(25);
	}
}

.dropdown--content--right {
	right: 0;
}

.dropdown__content--buttons {
	padding: spacing.set(1) 0;
}

.dropdown__button {
	position: relative;
	display: block;
	min-width: spacing.set(9);
	height: spacing.set(9);
	padding: 0;
	cursor: pointer;
}

.dropdown__button,
.dropdown__button::after {
	background-position: center;
	background-repeat: no-repeat;
}

.dropdown__button--user {
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		right: 0;
		width: units.px2rem(18px);
		height: units.px2rem(18px);
		border-radius: map.get(border-radius.$values, full);
		background-image: url(../../../img/icons/icon-chevron-down-color.svg);
		background-size: 50%;
		background-color: colors.$white;
		transform: translate(50%, -50%);
		transform-origin: center;
		cursor: pointer;
		transition: transform transition.$base;
	}
}

.btn--dropdown.is-open + .dropdown__content {
	display: flex;
	transform: translateY(0%);
	pointer-events: auto;
	animation: fade-in 500ms forwards;
}

.dropdown__item {
	@include typography.style(text2);

	position: relative;
	display: block;
	padding: spacing.set(1) spacing.set(0);
	margin-block: spacing.set(1);
	font-weight: map.get(typography-settings.$font-weight-values, semibold);
	font-family: typography-settings.$font-family-primary;
	letter-spacing: units.px2em(-0.1px, map.get(typography-settings.$font-size-values, 3));
	text-decoration: none;
	color: colors.$text;
	cursor: pointer;
	transition: color transition.$base;

	&:last-of-type {
		margin-bottom: 0;
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		right: unset;
		bottom: 0;
		left: spacing.set(-5);
		opacity: 0;
		width: units.px2rem(4px);
		height: unset;
		border-radius: 0 map.get(border-radius.$values, full) map.get(border-radius.$values, full) 0;
		background-color: colors.$primary;
	}
}

.dropdown__item.is-hidden {
	display: none;
}

.dropdown__item[data-disabled="true"] {
	display: none;
}

.dropdown__item--nav {
	margin-block: spacing.set(3);

	@include breakpoint.up(lg) {
		margin-block: spacing.set(1);
	}
}

.dropdown__item--grayish {
	opacity: 0.4;
}

.dropdown__item--active {
	opacity: 1;

	&::after {
		opacity: 1;
	}
}

.dropdown__label {
	pointer-events: none;
}
