/* stylelint-disable */
.quest__header {
	position: relative;
	height: 369px;
	width: 100%;
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
	&::after {
		content: "";
		position: absolute;
		height: 100px;
		width: 100%;
		bottom: 0;
		left: 0;
		background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
	}
}
.quest__header-title {
	position: absolute;
	bottom: 32px;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
	color: white;
	z-index: 1;
}

.quest__header-img {
	&.is-dark {
		+ .quest__header-title {
			color: white;
		}
	}
	&.is-light {
		+ .quest__header-title {
			color: black;
		}
	}
}

.needConsent {
	margin-top: 25px;
	margin-bottom: 30px;
	border-radius: 4px;
	background-color: $grey;
	padding: 1px 20px;
	color: #FFFFFF;
	font-family: $font-lato;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
}
/* stylelint-enable */
