@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/layout";
@use "../../settings/typography" as typography-settings;
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/units";

.alerts-wrap {
	position: fixed;
	top: spacing.set(3);
	right: 0;
	left: 0;
	margin-inline: auto;
	z-index: map.get(z-index.$values, alert);
	max-width: units.px2rem(360px);

	@include breakpoint.up(sm) {
		max-width: units.px2rem(500px);
	}
}

.alert {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
	max-width: units.px2rem(360px);
	min-height: spacing.set(10);
	padding: spacing.set(2) spacing.set(7);
	margin-inline: auto;
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-family: typography-settings.$font-family-secondary;
	color: colors.$white;
	border-radius: map.get(border-radius.$values, medium);

	@include breakpoint.up(sm) {
		max-width: units.px2rem(500px);
	}
}

.alert > .close {
	position: absolute;
	top: 0;
	right: 0;
	width: spacing.set(3);
	height: spacing.set(3);
	padding: 0;
	color: white;
	cursor: pointer;
}

.alert-success {
	background-color: colors.$green;
}

.alert-warning {
	background-color: colors.$primary;
}

.alert-danger {
	background-color: map.get(colors.$brand, red);
}

.alert-info {
	background-color: colors.$green;
}
