@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.input-image-wrapper {
	padding: spacing.set(2) spacing.set(4);
	border-radius: map.get(border-radius.$values, large);
	background-color: map.get(colors.$grays, 100);
}

.input-image-wrapper--disabled {
	opacity: 0.5;
	pointer-events: none;
}

.image-input {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	opacity: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;

	&::-webkit-file-upload-button {
		display: none;
		width: 100%;
		height: 100%;
	}
}
