@use "sass:map";
@use "../../settings/colors";
@use "../../settings/typography" as typography-settings;
@use "../../tools/units";
@use "../../tools/typography";

.label {
	@include typography.style(label);

	position: relative;
	z-index: 1;
	display: inline-flex;
}

.label--input {
	position: absolute;
	top: 0;
	left: units.px2rem(7px);
	display: inline-flex;
	align-items: center;
	padding-inline: units.px2rem(3px);
	white-space: nowrap;
	background-image: linear-gradient(to top, colors.$white 50%, transparent 50%);
	transform: translateY(-50%);
}

.label__sup {
	top: 0.2em;
	font-size: map-get(typography-settings.$font-size-values, 8);
	font-family: typography-settings.$font-family-secondary;
}
