/* stylelint-disable */
.a-note {
	@extend .p-note;
	color: currentColor;
	&:hover {
		text-decoration: underline;
	}
}
.a-note--color {
	color: $main-color;
}
.a-fade {
	@extend .p-fade;
	&:hover {
		text-decoration: underline;
	}
}

a[href=""] {
	pointer-events: none;
}
/* stylelint-enable */
