/* stylelint-disable */
$font-family: Montserrat, sans-serif;
$font-lato: Lato, sans-serif;

$main-color: #FE5C4F;
$main-color-hover: #DD5044;

$semiLightGrey: #F5F8F8;
$lightGrey: #ECECEC;
$grey: #C4C4C4;
$semiGrey: #DDD;
$mediumGrey: #606060;
$darkGrey: #313131;
$black: #000000;
$white: #fff;
$pastelBlue: #B6E2EC;

$lightGreyBtnText: #B1B1B1;

$hover-gray: $lightGrey;

$base-background: #F9FAFA;
$typo-dark: #2D3747;
$headline-dark: $darkGrey;
$p-fade: #A3A3A3;

$alert-green: #69B552;
$alert-orange: #FB8440;
$alert-red: #DF3939;

$form-color: #DADEE1;
$form-label: $grey;
$form-checkbox: $mediumGrey;

$aside-width-xs: 300px;
$aside-width: 330px;
$aside-width-wide: 850px;
$aside-width-wide-xxl: 930px;
$header-height: 73px;
$dr-height: 58px;
$tabs-header-height: 61px;

$max-container: 1140px;

$z-index-dr-hover: 29;
$z-index-modal: 2103;
$z-index-modal-aside: 2500;

$breakpoints: (
	xs: 0,
	sm: 36em, // 576 px
	md: 48em, // 768 px
	lg: 62em, // 992 px
	xl: 75em, // 1200 px
	xxl: 82em, // 1312 px
	xxxl: 100em, // 1600 px
);

/* KEYFRAMES */
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* EXTENDS */
%form-error {
	text-align: left;
	color: $alert-red;
	font-family: $font-lato;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.24px;
	line-height: 15px;
	width: 100%;
}

%form-box-shadow {
	box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
}

%icon-circle {
	position: absolute;
	overflow: hidden;
	color: transparent;
	border-radius: 50%;
	height: 26px;
	width: 26px;
	background-color: #FFFFFF;
	box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
	&::before {
		content: " ";
		height: 100%;
		width: 100%;
		opacity: 0.4;
		position: absolute;
		top: 0;
		left: 0;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 9.3px;
		transition: opacity .2s ease;
	}
	&:hover {
		&::before {
			opacity: 1;
		}
	}
}

%icon-circle--close {
	@extend %icon-circle;
	&::before {
		background-image: url(../img/icons/icon-close.svg);
	}
}

:root {
	--vh: 1vh;
}
/* stylelint-enable */
