/* stylelint-disable */
@mixin field-list() {
	$_grid-gap: 10px;

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	grid-gap: $_grid-gap;
	width: 100%;
}

@mixin wrap() {
	position: absolute;
	right: 0;
	left: 0;
	z-index: 3;
	padding: 2px 2px 2px 6px;
	border: 1px solid #d8d8d8;
	border-radius: 4px 4px 0 4px;
	background-color: $white;
	box-shadow: 0 2px 8px 0 rgba($mediumGrey, 0.3);
}

@mixin selectable-field-wrap() {
	position: relative;
	display: inline-flex;
	align-items: flex-start;
	padding: 5px 10px;

	&:hover,
	&:focus {
		background-color: $lightGrey;
	}
}

@mixin selectable-field() {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 26px;
	text-align: left;
}

@mixin selectable-field-input() {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	opacity: 0;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	cursor: inherit;

	&:disabled {
		pointer-events: none;
	}
}

@mixin selectable-field-label() {
	position: relative;
	z-index: 2;
	display: block;
	padding-left: 25px;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.45;
	color: $mediumGrey;
	user-select: none;
	cursor: pointer;

	&::before,
	&::after {
		content: '';
		position: absolute;
		width: 15px;
		height: 15px;
	}
}

@mixin field() {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

@mixin label() {
	margin-right: 5px;
	font-weight: 600;
	font-size: 13px;
	color: $main-color;
}

@mixin input() {
	flex-grow: 1;
	min-width: 180px;
	padding: 4px 8px;
	font-weight: 400;
	font-size: 14px;
	color: $mediumGrey;
	border: 1px solid #d8d8d8;
	border-radius: 4px;
	background-color: $white;
	transition: border-color 200ms ease;

	&:focus,
	&:active {
		border-color: $main-color;
		outline: none;
	}
}

@mixin disabled() {
	opacity: 0.5;
	pointer-events: none;
}

@mixin error-container() {
	padding-top: 2px;
	padding-bottom: 1px;
	text-align: right;
}
/* stylelint-enable */
