/* stylelint-disable */

.table-layout__filter:not(.filter--is-mini) ~ .table-layout__detail:not(:empty) ~ .table-layout__table .table-layout__filter-hide {
	display: none;
}

.table-layout__filter:not(.filter--is-mini) ~ .table-layout__table .table-layout__bulk {
	flex-direction: row-reverse;
	width: 100%;
	height: auto;
	padding: 6px 8px;
	text-align: center;

	@media (min-width: map-get($breakpoints, xl)) {
		display: flex;
		flex-direction: row;
		width: 151px;
		height: 44px;
		padding: 10px 10px 10px 17px;
		text-align: left;
	}
}

.table-layout__filter:not(.filter--is-mini) ~ .table-layout__detail:not(:empty) ~ .table-layout__table .table-layout__bulk {
	flex-direction: row-reverse;
	width: 100%;
	height: auto;
	padding: 6px 8px;
	text-align: center;
}
/* stylelint-enable */
