@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/typography" as typography-settings;
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.input-group {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	gap: spacing.set(3);
}

.input-wrapper {
	position: relative;
	display: inline-block;
	width: 100%;
	margin-block: units.px2rem(18px);
}

.input-wrapper--select {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: spacing.set(2) spacing.set(3);
}

.input {
	@include typography.style(input);

	position: relative;
	z-index: 0;
	width: 100%;
	height: spacing.set(9);
	padding: spacing.set(3) spacing.set(2);
	border: 0;
	border: 1px solid map.get(colors.$grays, 400);
	border-radius: map.get(border-radius.$values, medium);
	background-color: colors.$white;

	&::placeholder {
		color: map.get(colors.$grays, 700);
	}

	@media (hover: none) {
		font-size: map.get(typography-settings.$font-size-values, 6);
	}
}

.input--search {
	padding-inline: spacing.set(7);

	@include breakpoint.up(lg) {
		padding-inline: spacing.set(2) spacing.set(7);
	}
}

.input--select {
	padding-right: spacing.set(5);
}

.table-layout__detail:not(:empty) ~ .table-layout__table .input--search {
	padding-left: spacing.set(7);

	@include breakpoint.up(xxxxl) {
		padding-left: spacing.set(2);
	}
}

.input--white {
	color: map.get(colors.$grays, 700);
	background-color: colors.$white;
}

.input--small {
	@include typography.style(input-small);

	width: spacing.set(10);
	height: spacing.set(4);
	padding: 0 spacing.set(1);
	line-height: spacing.set(4);
	text-align: center;
	border: units.px2rem(1px) solid map.get(colors.$grays, 400);
	border-radius: map.get(border-radius.$values, medium);
	background-color: colors.$white;
}

.input--textarea {
	line-height: map.get(typography-settings.$line-height-values, 2);
}

.input--focus ~ .label--input,
.input:focus ~ .label--input {
	color: colors.$primary;
}

.input-wrapper--active .input {
	border-color: colors.$primary;
	outline: none;
}

.input-wrapper--active .input .custom-select__arrow {
	opacity: 1;
	background-image: url(../../../img/icons/icon-chevron-down-color.svg);
}

.input-wrapper--active .input ~ .label--input {
	color: colors.$primary;
}

.input-wrapper--15 {
	flex: 0 0 30%;
	width: 30%;

	@include breakpoint.up(sm) {
		flex: 0 0 15%;
		width: 15%;
	}
}

.input-wrapper--33 {
	flex: 1;

	@include breakpoint.up(sm) {
		flex: unset;
		width: 33%;
	}
}

.input-wrapper--42 {
	flex: 1;

	@include breakpoint.up(sm) {
		flex: unset;
		width: 42%;
	}
}

.input-wrapper--50 {
	flex: 1;
}

.input-wrapper--60 {
	flex: 1;

	@include breakpoint.up(sm) {
		flex: unset;
		width: 60%;
	}
}

.input-wrapper--flex-1 {
	flex: 1;
}

.input:focus,
.input--focus {
	border-color: colors.$primary;
	outline: none;
}

.input-cross {
	appearance: none;
	position: absolute;
	top: units.px2rem(-14px);
	right: spacing.set(1);
	padding: units.px2rem(3px) spacing.set(1);
	line-height: map.get(typography-settings.$line-height-values, 2);
	text-decoration: none;
	color: colors.$primary;
	background-image: linear-gradient(to top, colors.$white 50%, transparent 50%);
	cursor: pointer;
}

.error-container {
	@include typography.style(label);

	width: 100%;
	margin-top: units.px2rem(4px);
	text-align: left;
	color: map.get(colors.$grays, 800);
}

.has-error .input + .label {
	color: colors.$primary;
}

.has-error .error-container {
	color: colors.$primary;
}
