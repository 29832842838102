/* stylelint-disable */
$icons-font: "icons";

@font-face {
	font-family: $icons-font;
	src: url("./icons.eot?f484c20b7a7ee9f48aa91f48aae34248#iefix") format("embedded-opentype"),
url("./icons.woff2?f484c20b7a7ee9f48aa91f48aae34248") format("woff2"),
url("./icons.woff?f484c20b7a7ee9f48aa91f48aae34248") format("woff");
}

span[class^="icon-"], span[class*=" icon-"] {
	font-family: icons !important;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-feature-settings: normal;
	font-variant: normal;
	text-rendering: auto;
	vertical-align: baseline;
}

$icons-map: (
	"chevron-down": "\f101",
	"chevron-left": "\f102",
	"chevron-up": "\f103",
	"close": "\f104",
	"download": "\f105",
	"filter": "\f106",
	"minimize": "\f107",
	"non-visible": "\f108",
	"search": "\f109",
	"share": "\f10a",
	"visible": "\f10b",
);

.icon-chevron-down::before {
	content: map-get($icons-map, "chevron-down");
	display: block;
	line-height: 1;
}
.icon-chevron-left::before {
	content: map-get($icons-map, "chevron-left");
	display: block;
	line-height: 1;
}
.icon-chevron-up::before {
	content: map-get($icons-map, "chevron-up");
	display: block;
	line-height: 1;
}
.icon-close::before {
	content: map-get($icons-map, "close");
	display: block;
	line-height: 1;
}
.icon-download::before {
	content: map-get($icons-map, "download");
	display: block;
	line-height: 1;
}
.icon-filter::before {
	content: map-get($icons-map, "filter");
	display: block;
	line-height: 1;
}
.icon-minimize::before {
	content: map-get($icons-map, "minimize");
	display: block;
	line-height: 1;
}
.icon-non-visible::before {
	content: map-get($icons-map, "non-visible");
	display: block;
	line-height: 1;
}
.icon-search::before {
	content: map-get($icons-map, "search");
	display: block;
	line-height: 1;
}
.icon-share::before {
	content: map-get($icons-map, "share");
	display: block;
	line-height: 1;
}
.icon-visible::before {
	content: map-get($icons-map, "visible");
	display: block;
	line-height: 1;
}
/* stylelint-enable */
