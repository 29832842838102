@use "sass:map";
@use "../../../scss/settings/colors";
@use "../../../scss/settings/theme";
@use "../../../scss/tools/typography";

a {
	text-decoration: underline;
	color: theme.$link-color;

	&:hover {
		text-decoration: none;
		color: theme.$link-color-hover;
	}
}

.link {
	@include typography.style(link);
}

.link-plain {
	@include typography.style(link-plain);
}

.link-small {
	@include typography.style(link-small);

	text-decoration: none;
	color: map.get(colors.$grays, 800);
}

.link-cta {
	@include typography.style(link);

	color: colors.$primary;
}
