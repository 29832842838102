@use "sass:map";
@use "sass:color";
@use "../../settings/border-radius";
@use "../../settings/box-shadow";
@use "../../settings/colors";
@use "../../settings/theme";
@use "../../settings/layout";
@use "../../settings/header";
@use "../../settings/z-index";
@use "../../settings/typography" as typography-settings;
@use "../../settings/transition";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

:root {
	--number-of-rows: 4;
}

.extra-detail-wrap:not(:empty) {
	position: fixed;
	top: 0;
	left: 0;
	z-index: map.get(z-index.$values, filter-aside);
	width: 100vw;
	height: 100vh;
	/* stylelint-disable unit-no-unknown */
	height: 100svh;
	/* stylelint-enable unit-no-unknown */
	padding-inline: map.get(layout.$container, padding-inline, xs);
	overflow: auto;
	background-color: color.change(colors.$black, $alpha: 0.4);
	scrollbar-width: none;

	@include breakpoint.up(sm) {
		position: sticky;
		top: 0;
		left: unset;
		z-index: unset;
		flex: unset;
		width: 50%;
		height: calc(100vh - #{header.$height});
		/* stylelint-disable unit-no-unknown */
		height: calc(100svh - #{header.$height});
		/* stylelint-enable unit-no-unknown */
		padding-inline: unset;
		overflow-x: auto;
		border-left: units.px2rem(1px) solid map.get(colors.$grays, 200);
		background-color: unset;
		transition: max-width transition.$base;
		scrollbar-width: unset;
	}

	@include breakpoint.up(lg) {
		position: relative;
		top: unset;
		left: unset;
		overflow: unset;
	}

	@include breakpoint.up(lg) {
		width: map.get(layout.$aside-width, medium);
		min-width: 0;
		max-width: map.get(layout.$aside-width, medium);
	}

	@include breakpoint.up(xl) {
		flex: unset;
	}

	@include breakpoint.up(xxxxl) {
		flex: 1;
	}
}

.extra-detail-wrap:empty {
	min-width: 0;
	max-width: 0;
}

.extra-detail {
	display: flex;
	flex-direction: column;
	margin: spacing.set(5) auto;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: colors.$white;

	@include breakpoint.up(sm) {
		margin: unset;
	}

	@include breakpoint.up(lg) {
		flex-direction: row;
		height: calc(100vh - #{header.$height});
	}
}

.extra-detail__close {
	position: absolute;
	top: units.px2rem(33px);
	right: spacing.set(4);
	z-index: 2;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(32px);
	height: units.px2rem(32px);
	font-size: map.get(typography-settings.$font-size-values, 1);
	text-decoration: none;
	color: map.get(colors.$grays, 800);
	border-radius: map.get(border-radius.$values, small);
	background-color: map.get(colors.$grays, 200);

	@include breakpoint.up(sm) {
		top: units.px2rem(3px);
		right: unset;
		left: units.px2rem(18px);
		width: units.px2rem(26px);
		height: units.px2rem(26px);
		border-radius: map.get(border-radius.$values, full);
		background-color: colors.$white;
		box-shadow: map.get(box-shadow.$values, small);
		transform: translateX(-50%);
		transition: color transition.$base, background-color transition.$base;
	}

	@include breakpoint.up(lg) {
		right: unset;
		left: 0;
	}

	@include breakpoint.up(xxl) {
		top: units.px2rem(14px);
	}

	@media (hover: hover) {
		&:hover {
			color: colors.$white;
			background-color: colors.$primary;
		}
	}
}

.extra-detail-main {
	display: grid;
	grid-auto-rows: max-content;
	align-items: flex-start;
	width: 100%;
	padding: spacing.set(3) spacing.set(2) 0;
	background-color: colors.$white;

	@include breakpoint.up(sm) {
		padding-block: spacing.set(2);
	}

	@include breakpoint.up(lg) {
		position: sticky;
		top: 0;
		width: spacing.set(57);
		min-height: calc(100vh - #{header.$height});
	}

	@media screen and (max-height: #{spacing.set(130)}) {
		position: static;
	}
}

.extra-detail-main__name {
	@include typography.style(heading4);

	order: -2;
	margin-bottom: units.px2em(15px, map.get(typography-settings.$font-size-values, 6));

	@include breakpoint.up(sm) {
		order: unset;
		text-align: center;
	}
}

.extra-detail__grid-wrap {
	display: grid;
	grid-template-rows: repeat(var(--number-of-rows), minmax(#{spacing.set(5)}, 1fr));
	grid-template-columns: 1fr;
	grid-auto-flow: column;
	grid-column-gap: spacing.set(9);

	@include breakpoint.up(xl) {
		grid-template-columns: repeat(2, 1fr);
	}
}

.extra-detail__grid-wrap--header {
	--number-of-rows: 7;

	@include breakpoint.up(xl) {
		--number-of-rows: 4;
	}
}

.extra-detail__grid-wrap--residency {
	--number-of-rows: 6;

	@include breakpoint.up(xl) {
		--number-of-rows: 3;
	}
}

.extra-detail__grid-wrap--visual {
	--number-of-rows: 18;

	@include breakpoint.up(xl) {
		--number-of-rows: 9;
	}
}

.extra-detail__grid-wrap--teenager {
	--number-of-rows: 4;

	@include breakpoint.up(xl) {
		--number-of-rows: 2;
	}
}

.extra-detail-info {
	min-width: spacing.set(56);
	background-color: colors.$white;

	@include breakpoint.up(sm) {
		background-color: theme.$page-background-color;
	}

	@include breakpoint.up(lg) {
		flex: 1;
		width: 100%;
	}
}

.extra-detail-info__content {
	padding: spacing.set(1) spacing.set(2) spacing.set(2) spacing.set(2);

	@include breakpoint.up(lg) {
		padding-right: spacing.set(6);
	}
}

.extra-detail-info__heading {
	@include typography.style(text2);

	display: block;
	padding-bottom: spacing.set(1);
	margin-top: spacing.set(3);
	margin-bottom: units.px2rem(13px);
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	letter-spacing: px2em(-0.02px, map.get(typography-settings.$font-size-values, 3));
	font-family: typography-settings.$font-family-primary;
	color: colors.$primary;
	border-bottom: units.px2rem(1px) solid colors.$primary;
}

.extra-detail-info__subtitle {
	position: relative;
	padding-left: spacing.set(2);

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		display: block;
		height: units.px2rem(1px);
		background-color: map.get(colors.$grays, 500);
		transform: translateY(-50%);
	}
}

.extra-detail-info__subtitle > span {
	@include typography.style(text2);

	position: relative;
	z-index: 1;
	display: inline-flex;
	align-items: center;
	height: units.px2rem(23px);
	padding-inline: spacing.set(2);
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	color: colors.$white;
	border-radius: map.get(border-radius.$values, full);
	background-color: map.get(colors.$grays, 500);
}

.extra-detail-info__representative-wrap {
	padding-top: spacing.set(2);
	margin-top: spacing.set(4);
	border-top: units.px2rem(1px) solid map.get(colors.$grays, 300);
}

.extra-detail-info__representative-description {
	@include typography.style(text2);
}

.extra-detail-info__representative-heading {
	@include typography.style(text2);

	font-family: typography-settings.$font-family-primary;
	color: colors.$primary;
}

.extra-detail-main__control-buttons {
	display: flex;
	flex-wrap: nowrap;
	order: -1;
	gap: spacing.set(3);
	width: 100%;
	margin-bottom: spacing.set(3);

	@include breakpoint.up(sm) {
		order: unset;
		margin-bottom: spacing.set(2);
	}
}
