@use "sass:map";
@use "../tools/units";

$font-weight-values: (
	regular: 400,
	medium: 500,
	semibold: 600,
	bold: 700,
);

$font-weight-base: map.get($font-weight-values, regular);

$line-height-values: (
	0: 1,
	1: 1.2,
	2: 1.5,
);

$line-height-heading: map.get($line-height-values, 1);
$line-height-base: map.get($line-height-values, 2);

$font-size-values: (
	1: units.px2rem(10px),
	2: units.px2rem(11px),
	3: units.px2rem(12px),
	4: units.px2rem(13px),
	5: units.px2rem(14px),
	6: units.px2rem(16px),
	7: units.px2rem(17px),
	8: units.px2rem(20px),
	9: units.px2rem(24px),
	10: units.px2rem(32px),
	11: units.px2rem(64px),
);

$font-size-base: map.get($font-size-values, 5);

$font-family-primary: montserrat, sans-serif;
$font-family-secondary: lato, sans-serif;
