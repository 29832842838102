@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/box-shadow";
@use "../../settings/colors";
@use "../../settings/theme";
@use "../../settings/layout";
@use "../../settings/header";
@use "../../settings/z-index";
@use "../../settings/typography" as typography-settings;
@use "../../settings/transition";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.cropper-title {
	font-weight: normal;
	font-size: map.get(typography-settings.$font-size-values, 6);
	line-height: map.get(typography-settings.$line-height-values, 1);
	color: map.get(colors.$grays, 700);
}

.cropper-head {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: spacing.set(2);
	padding-block: spacing.set(2);
	border-bottom: units.px2rem(1px) solid map.get(colors.$grays, 200);
}

.cropper-head__item {
	overflow: hidden;
	border: units.px2rem(1px) solid transparent;
	border-radius: map.get(border-radius.$values, large);
}

.cropper-head__item.active {
	border-color: colors.$primary;
	box-shadow: 0 0 units.px2rem(2px) 0 colors.$primary;
}

.cropper__thumbnail {
	display: block;
	max-height: spacing.set(8);
}

.cropper__thumbnail img {
	display: block;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.cropper__thumbnail--landscape {
	aspect-ratio: 16 / 9;
}

.cropper__thumbnail--portrait {
	aspect-ratio: 3 / 4;
}

.cropper__content img {
	width: 100%;
	max-width: 100%;
}

.cropper__content__tab {
	display: none;
}

.cropper__content__tab.active {
	display: block;
}
