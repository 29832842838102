@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/theme";
@use "../../settings/transition";
@use "../../settings/typography" as typography-settings;
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.btn {
	@include typography.style(button);

	display: inline-flex;
	column-gap: spacing.set(2);
	align-items: center;
	justify-content: center;
	min-height: spacing.set(9);
	padding-inline: spacing.set(7);
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
	border: units.px2rem(1px) solid transparent;
	border-radius: map.get(border-radius.$values, medium);
	cursor: pointer;
	transition: color transition.$base, background-color transition.$base;
}

.btn--smaller {
	min-height: units.px2rem(32px);
	padding-inline: spacing.set(2);
	font-size: map.get(typography-settings.$font-size-values, 3);
	text-transform: none;
}

.btn--wide {
	padding-inline: spacing.set(6);
}

.btn--narrow {
	padding-inline: spacing.set(3);
}

.btn--full {
	justify-content: space-between;
	width: 100%;
}

.btn--rounded {
	border-radius: map.get(border-radius.$values, full);
}

.btn--flex-1 {
	flex: 1;
}

.btn--text-left {
	justify-content: flex-start;
}

.btn--icon {
	min-width: units.px2rem(32px);
	min-height: units.px2rem(32px);
	padding: 0;
}

.btn--icon-sm {
	font-size: map.get(typography-settings.$font-size-values, 2);
}

.btn--icon-lg {
	font-size: map.get(typography-settings.$font-size-values, 8);
}

.btn--with-icon .icon {
	font-size: map.get(typography-settings.$font-size-values, 7);
}

.btn--main {
	color: colors.$white;
	background-color: colors.$primary;

	&:hover {
		color: colors.$white;
		background-color: map.get(colors.$brand, orange-dark);
	}

	&:focus {
		border-color: colors.$primary;
		outline: 0;
	}
}

.btn--secondary {
	color: map.get(colors.$grays, 700);
	background-color: map.get(colors.$grays, 200);

	&:hover,
	&:focus {
		color: map.get(colors.$grays, 700);
		background-color: darken(map.get(colors.$grays, 200), 10%);
		outline: 0;
	}
}

.btn--plain {
	color: colors.$primary;
	background-color: transparent;

	&:hover {
		color: theme.$page-text-color;
	}
}

.btn--with-mark {
	position: relative;
}

.btn--with-mark .mark {
	position: absolute;
	top: units.px2rem(-4px);
	right: units.px2rem(-4px);
	pointer-events: none;
}

.btn--default {
	color: theme.$page-text-color;
	background-color: transparent;

	&:hover {
		color: colors.$primary;
	}
}

.btn--dropdown {
	position: relative;
}

.btn--dropdown-select {
	padding-right: 0;
}

.btn--dropdown-select:not(button) {
	cursor: default;
}

.btn--dropdown-select.has-error {
	border: units.px2rem(1px) solid colors.$primary;
}

.btn--dropdown-select .error-container {
	position: absolute;
	top: 100%;
}

.btn--dropdown .icon {
	transform: rotate(0);
	transform-origin: 50% 50%;
	pointer-events: none;
	transition: transform transition.$base;
}

.btn--dropdown-select .icon {
	position: absolute;
	top: 50%;
	right: spacing.set(2);
	color: theme.$page-text-color;
	transform: translateY(-50%) rotate(0);
	transform-origin: 50% 50%;
	transition: transform transition.$base;
}

.btn--dropdown.is-open .icon {
	transform: rotate(180deg);
}

.btn--dropdown-select.is-open .icon {
	transform: translateY(-50%) rotate(180deg);
}

.btn--circle {
	aspect-ratio: 1;
}

.btn--loading {
	position: relative;
	color: transparent;
	background-color: colors.$primary;
	pointer-events: none;

	&:hover {
		background-color: colors.$primary;
	}

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		box-sizing: border-box;
		width: spacing.set(6);
		height: spacing.set(6);
		border: units.px2rem(6px) solid transparent;
		border-right-color: #fff;
		border-radius: 50%;
		background-color: colors.$primary;
		transform: translate(-50%, -50%) rotate(0deg);
		animation: rotationBtn 1s linear infinite;
	}
}
