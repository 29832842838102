@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/typography" as typography-settings;
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.form-group {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	column-gap: spacing.set(3);
}

.form-group--secondary {
	padding-inline: spacing.set(2);
	margin-bottom: spacing.set(1);
	border-radius: map.get(border-radius.$values, large);
	background-color: map.get(colors.$grays, 100);
}

.form-group--full-mobile {
	grid-column: 1 / span 2;

	@include breakpoint.up(sm) {
		grid-column: unset;
	}
}
