/* stylelint-disable */
.modal-bulker {
	position: relative;
	display: flex;
	box-sizing: border-box;
	margin: 20px -20px;
	text-align: left;
	border-width: 1px 0;
	border-style: solid;
	border-color: #eaeaea;
	background-color: $white;

	&::before {
		content: '';
		position: absolute;
		left: 25%;
		top: 0;
		display: block;
		box-sizing: border-box;
		width: 12px;
		height: 12px;
		border-width: 1px 1px 0 0;
		border-style: solid;
		border-color: $lightGrey;
		background-color: $semiLightGrey;
		transform: translate(-50%, -50%) rotate(135deg);
	}

	&::after {
		content: '';
		position: absolute;
		left: 75%;
		top: 100%;
		display: block;
		box-sizing: border-box;
		width: 12px;
		height: 12px;
		border-width: 1px 1px 0 0;
		border-style: solid;
		border-color: $lightGrey;
		background-color: $white;
		transform: translate(-50%, -50%) translateY(1px) rotate(135deg);
	}
}

.modal-bulker__section {
	position: relative;
	flex: 1 0 50%;

	&:not(:last-child) {
		border-right: 1px solid #d8d8d8;

		&::after {
			content: '';
			position: absolute;
			left: 100%;
			top: 35px;
			display: block;
			box-sizing: border-box;
			width: 12px;
			height: 12px;
			border-width: 1px 1px 0 0;
			border-style: solid;
			border-color: #d8d8d8;
			background-color: $white;
			transform: translate(-50%, -50%) translateX(1px) rotate(45deg);
		}
	}
}

.modal-bulker__section__head {
	min-height: 70px;
	padding: 20px 20px 5px;
	box-sizing: border-box;
}

.modal-bulker__section:first-child > .modal-bulker__section__head {
	border-bottom: 1px solid #d8d8d8;
}

.modal-bulker__section__body {
	max-height: 230px;
	overflow: auto;
	padding: 20px;
}

.modal-bulker__title {
	font-size: 13px;
	font-weight: 600;
	letter-spacing: -0.02px;
	line-height: 16px;
	color: $mediumGrey;
}

.modal-bulker__checkbox {
	position: relative;
	display: block;
}

.modal-bulker__checkbox__input {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
	padding: 0;
	margin-left: 0;
	cursor: inherit;
}

.modal-bulker__checkbox__label {
	position: relative;
	z-index: 1;
	display: block;
	box-sizing: border-box;
	padding: 5px 10px 5px 34px;
	font-family: $font-lato;
	color: $form-checkbox;
	font-size: 12px;
	letter-spacing: 0.15px;
	line-height: 15px;
	cursor: pointer;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 10px;
		transform: translateY(-50%);
		border: 1px solid $form-checkbox;
		height: 17px;
		width: 17px;
		border-radius: 2px;
		box-sizing: border-box;
	}

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 12.5px;
		height: 17px / 100 * 27;
		width: 17px / 100 * 57;
		border: 1.5px solid transparent;
		border-top: 0;
		border-right: 0;
		transform: translateY(-50%) translateY(-2.5px) rotate(-45deg);
		z-index: 0;
	}
}

.modal-bulker__checkbox__input:checked + .modal-bulker__checkbox__label {
	&::before {
		border-color: $main-color;
		background-color: $main-color;
	}

	&::after {
		border-color: $white;
	}
}

.modal-bulker__checkbox--highlight {
	font-weight: 600;
}

.modal-bulker__checkbox--box {
	margin-bottom: 2px;
}

.modal-bulker__checkbox--box > .modal-bulker__checkbox__label {
	padding-top: 11px;
	padding-bottom: 11px;
	border-radius: 2px;
	background-color: #f4f4f4;
}

.modal-bulker__checkbox--box > .modal-bulker__checkbox__input:checked + .modal-bulker__checkbox__label {
	background-color: #e0e0e0;
}
/* stylelint-enable */
