@use "sass:color";
@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/header";
@use "../../settings/transition";
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.nav {
	display: flex;
	align-items: center;
	column-gap: spacing.set(7);
}

.nav--account {
	justify-content: center;

	@include breakpoint.up(xl) {
		justify-content: flex-start;
	}
}

.nav__title {
	@include typography.style(text4);

	margin-bottom: spacing.set(2);
}

.nav-link {
	@include typography.style(nav-link);

	position: relative;
	padding: spacing.set(4) spacing.set(3);
	text-decoration: none;
	color: colors.$text;
	transition: color transition.$base;

	@include breakpoint.up(lg) {
		color: color.change(colors.$text, $alpha: 0.4);
	}

	&:hover {
		color: color.change(colors.$text, $alpha: 0.8);
	}

	&::after {
		content: "";
		position: absolute;
		right: 0;
		bottom: units.px2rem(-1px);
		left: 0;
		z-index: 1;
		opacity: 0;
		height: units.px2rem(4px);
		border-radius: map.get(border-radius.$values, full) map.get(border-radius.$values, full) 0 0;
		background-color: colors.$primary;
	}
}

.nav-link--tabs {
	padding-top: 0;
	padding-inline: 0;
}

.nav-link--active,
.nav-link--is-current {
	color: colors.$text;

	&::after {
		opacity: 1;
	}
}

.nav--right {
	column-gap: spacing.set(6);
	margin-left: auto;
}

.nav--left {
	@include breakpoint.up(lg) {
		margin-right: auto;
	}
}

.nav--right--logged-out {
	column-gap: spacing.set(1);
}

.nav-collapse {
	position: fixed;
	top: header.$height;
	right: 0;
	bottom: 0;
	z-index: map.get(z-index.$values, menu);
	display: block;
	visibility: hidden;
	width: spacing.set(47);
	height: calc(100vh - header.$height);
	/* stylelint-disable unit-no-unknown */
	height: calc(100dvh - header.$height);
	/* stylelint-enable unit-no-unknown */
	padding: spacing.set(4) spacing.set(5);
	overflow-x: auto;
	background-color: colors.$white;
	transform: translateX(spacing.set(47));
	transition: transform transition.$base;
	overscroll-behavior: none;

	@include breakpoint.up(lg) {
		position: static;
		display: flex;
		visibility: visible;
		flex: 1;
		align-items: center;
		justify-content: flex-end;
		width: auto;
		height: unset;
		padding: unset;
		overflow: unset;
		box-shadow: unset;
		transform: unset;
		transition: unset;
	}
}

.nav-collapse--nav-is-open {
	visibility: visible;
	transform: translateX(0);

	&::before {
		opacity: 1;
	}
}

.nav-collapse > .nav__title,
.nav-collapse > .nav > .nav__title {
	display: block;

	@include breakpoint.up(lg) {
		display: none;
	}
}

.nav-collapse .nav {
	flex-direction: column;
	align-items: stretch;
	margin-bottom: spacing.set(8);

	@include breakpoint.up(lg) {
		flex-direction: row;
		align-items: center;
		margin-bottom: unset;
	}
}

.nav-collapse .nav-link {
	padding: spacing.set(1) spacing.set(0);

	@include breakpoint.up(lg) {
		padding: units.px2rem(29px) spacing.set(3);
	}

	&::after {
		top: 0;
		right: unset;
		bottom: 0;
		left: spacing.set(-5);
		width: units.px2rem(4px);
		height: unset;
		border-radius: 0 map.get(border-radius.$values, full) map.get(border-radius.$values, full) 0;

		@include breakpoint.up(lg) {
			top: unset;
			right: 0;
			bottom: units.px2rem(-1px);
			left: 0;
			z-index: 1;
			width: unset;
			height: units.px2rem(4px);
			border-radius: map.get(border-radius.$values, full) map.get(border-radius.$values, full) 0 0;
		}
	}
}

.nav-collapse .btn--dropdown {
	display: none;

	@include breakpoint.up(lg) {
		display: inline-flex;
	}
}
