/* stylelint-disable */
.slider {
	width: 450px;
	position: relative;
	height: 0;
	padding-bottom: calc(450px * 0.6);
	margin: auto;
	border-radius: 5px;
	box-shadow: 0 0px 25px 0 rgba(0,0,0,0.1);
	margin-top: 50px;
	margin-bottom: 50px;
}

.slider-item {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
/* stylelint-enable */
