/* stylelint-disable */
.pagination-list {
	list-style: none;
	padding: 0;
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
	font-family: $font-family;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.15px;
	line-height: 15px;
	text-align: center;
	color: $grey;
	margin: 29px 0;
}
.pagination-list__item {
	text-decoration: none;
	height: 39px;
	width: 32px;
	border-radius: 3px;
	display: block;
	display: flex;
	justify-content: center;
	align-items: center;
	&.active {
		background-color: $main-color;
		color: white;
	}
}
/* stylelint-enable */
