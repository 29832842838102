@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/box-shadow";
@use "../../settings/colors";
@use "../../settings/theme";
@use "../../settings/layout";
@use "../../settings/header";
@use "../../settings/typography" as typography-settings;
@use "../../settings/transition";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.detail-field {
	position: relative;
	display: flex;
	align-items: center;
	padding-block: units.px2rem(7px);

	@include breakpoint.up(lg) {
		padding-left: spacing.set(5);
	}
}

.detail-field--column {
	flex-direction: column;
	row-gap: spacing.set(1);
	align-items: flex-start;
	padding-bottom: spacing.set(1);
}

.detail-field__form {
	position: relative;
}

.detail-field__list-wrap {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	gap: spacing.set(3) spacing.set(2);
	width: 100%;
}

.detail-field__list-wrap .checkbox__wrap {
	margin-block: 0;
}

.detail-field__second {
	margin-top: spacing.set(4);
}

.detail-field__content {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: spacing.set(1);
	width: 100%;
	overflow: hidden;
}

.detail-field__content--spacey {
	margin-bottom: spacing.set(1);
}

.detail-field__label {
	@include typography.style(text2);

	flex-shrink: 0;
	margin-bottom: 0;
	font-weight: map.get(typography-settings.$font-weight-values, semibold);
	line-height: map.get(typography-settings.$line-height-values, 1);
	font-family: typography-settings.$font-family-primary;
}

.detail-field__label--bold {
	font-weight: map.get(typography-settings.$font-weight-values, bold);
}

.detail-field__footer {
	@include typography.style(text2);

	display: inline-flex;
	line-height: map.get(typography-settings.$line-height-values, 1);
	font-family: typography-settings.$font-family-primary;
	text-align: right;
	color: map.get(colors.$grays, 800);
}

.detail-field__footer a {
	word-break: break-all;
}
