@use "sass:map";
@use "../../settings/colors";
@use "../../settings/header";
@use "../../settings/layout";
@use "../../settings/typography" as typography-settings;
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/units";
@use "../../tools/spacing";

.table-layout {
	display: flex;
	min-height: calc(100vh - header.$height);
	/* stylelint-disable unit-no-unknown */
	min-height: calc(100svh - #{header.$height});
	/* stylelint-enable unit-no-unknown */
	overflow: auto;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@include breakpoint.up(xxl) {
		padding-left: map.get(layout.$aside-width, small);
	}
}

.table-layout--center {
	@include breakpoint.up(xxl) {
		padding-left: unset;
	}
}

.table-layout--filter-is-minimized {
	@include breakpoint.up(xxl) {
		padding-left: units.px2rem(53px);
	}
}

.table-layout__table {
	position: sticky;
	top: 0;
	flex: 1;
	min-width: map.get(layout.$database-width, min);
	height: 100%;
	height: calc(100vh - #{header.$height});
	/* stylelint-disable unit-no-unknown */
	height: calc(100svh - #{header.$height});
	/* stylelint-enable unit-no-unknown */
	padding-top: spacing.set(6);
	overflow: auto;

	@include breakpoint.up(xxxl) {
		flex: 1;
	}
}

.table-layout__container {
	max-width: map.get(layout.$database-width, max);
	padding-inline: map.get(layout.$container, padding-inline, xs);
	margin-right: auto;
	margin-left: auto;

	@include breakpoint.up(lg) {
		padding-inline: units.px2rem(8px);
	}
}

.table-layout__table .h1 {
	margin-bottom: 0;
	font-size: map.get(typography-settings.$font-size-values, 8);
	text-align: left;

	@include breakpoint.up(xxl) {
		font-size: map.get(typography-settings.$font-size-values, 9);
		text-align: center;
	}

	@include breakpoint.up(xxxl) {
		font-size: map.get(typography-settings.$font-size-values, 10);
	}
}

.table-layout__detail {
	flex-shrink: 0;
	order: 2;
	height: 100%;
}

.filter:not(.filter--is-mini) ~ .table-layout__detail:not(:empty) {
	display: block;
}

.table-layout__detail:empty ~ .table-layout__table {
	flex: 1;
}

.table-layout__control {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	gap: spacing.set(3);
	margin-bottom: spacing.set(4);

	@include breakpoint.up(lg) {
		display: grid;
		grid-template-columns: 1fr auto 1fr;
	}

	@include breakpoint.up(xxxl) {
		justify-content: center;
	}
}

.table-layout__empty-div {
	display: none;

	@include breakpoint.up(lg) {
		display: block;
	}
}

.table-layout__detail:not(:empty) ~ .table-layout__table .table-layout__control {
	@include breakpoint.up(lg) {
		display: flex;
		justify-content: space-between;
	}

	@include breakpoint.up(xxl) {
		display: block;
	}
}

.table-layout__detail:not(:empty) ~ .table-layout__table .table-layout__empty-div {
	@include breakpoint.up(lg) {
		display: none;
	}
}
