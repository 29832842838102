@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/box-shadow";
@use "../../settings/colors";
@use "../../settings/layout";
@use "../../settings/typography" as typography-settings;
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/units";

.grid {
	display: grid;
	justify-content: center;
	gap: spacing.set(6);

	@include breakpoint.up(xl) {
		grid-template-columns: spacing.set(37) minmax(auto, map.get(layout.$container, width, small)) spacing.set(37);
	}
}
