@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.checkbox__wrap {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	margin-bottom: spacing.set(3);
	text-align: left;
}

.checkbox__wrap--filter {
	margin-top: 0;
	margin-bottom: 0;
}

.checkbox__wrap--table {
	margin-top: units.px2rem(3px);
	margin-bottom: 0;
}

.custom-checkbox__wrap--tabler-head {
	display: none;

	@include breakpoint.up(xxxxl) {
		display: block;
	}
}

.checkbox__wrap,
.custom-checkbox__wrap {
	position: relative;
	cursor: pointer;
}

.checkbox__wrap.is-disabled,
.custom-checkbox__wrap.is-disabled {
	pointer-events: none;
	cursor: none;
}

.checkbox {
	position: absolute;
	top: 0;
	left: 0;
	z-index: map.get(z-index.$values, base);
	opacity: 0;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	cursor: pointer;
}

.checkbox__label {
	@include typography.style(label-checkbox);

	position: relative;
	z-index: 2;
	display: block;
	flex: 1;
	padding-left: spacing.set(2);
	text-align: left;
	cursor: pointer;
}

.checkbox__label small {
	display: block;
}

.custom-checkbox {
	width: units.px2rem(20px);
	height: units.px2rem(20px);

	&::before,
	&::after {
		content: "";
		position: absolute;
		width: units.px2rem(20px);
		height: units.px2rem(20px);
	}

	&::before {
		top: units.px2rem(-3px);
		left: 0;
		border: units.px2rem(1px) solid map.get(colors.$grays, 700);
		border-radius: map.get(border-radius.$values, small);
		background-color: colors.$white;
	}

	&::after {
		top: 0;
		left: units.px2rem(7px);
		display: none;
		width: units.px2rem(5px);
		height: units.px2rem(11px);
		border: solid colors.$white;
		border-width: 0 units.px2rem(2px) units.px2rem(2px) 0;
		transform: rotate(45deg);
	}
}

.checkbox[type="radio"] + .custom-checkbox {
	&::before {
		border-radius: map.get(border-radius.$values, full);
	}

	&::after {
		left: units.px2rem(3px);
		width: units.px2rem(16px);
		height: units.px2rem(16px);
		border: 0;
		border-radius: map.get(border-radius.$values, full);
	}
}

.checkbox:checked + .custom-checkbox {
	&::before {
		border-color: colors.$primary;
		background-color: colors.$primary;
	}

	&::after {
		display: block;
	}
}

/* stylelint-disable selector-max-specificity */
.checkbox[type="radio"]:checked + .custom-checkbox {
	&::before {
		background-color: colors.$white;
	}

	&::after {
		background-color: colors.$primary;
	}
}
/* stylelint-enable selector-max-specificity */

.checkboxes-group {
	display: flex;
	flex-flow: row wrap;
	row-gap: spacing.set(3);
	margin-top: spacing.set(3);
}

.checkboxes-group .checkbox__wrap {
	margin-block: 0;
}

.checkboxes-group--4 .checkbox__wrap {
	flex: 0 0 50%;

	@include breakpoint.up(md) {
		flex: 0 0 25%;
	}
}

.checkboxes-group--3 .checkbox__wrap {
	flex: 0 0 33%;
}

.checkboxes-group--2 .checkbox__wrap {
	flex: 0 0 50%;
}

.checkboxes-group + .error-container {
	width: 100%;
	margin-bottom: spacing.set(3);
}
