@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/typography" as typography-settings;
@use "../../settings/transition";
@use "../../tools/spacing";
@use "../../tools/units";

.loading {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10;
	visibility: hidden;
	opacity: 0;
	width: spacing.set(13);
	height: spacing.set(13);
	transform: translate(-50%, -50%);
	pointer-events: none;
	transition: opacity transition.$base, visibility 0.1s 0.2s linear;

	&::before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		border: spacing.set(1) solid transparent;
		border-right-color: colors.$primary;
		border-radius: map.get(border-radius.$values, full);
		transform: rotate(0deg);
		animation: rotation 1s linear infinite;
	}
}

.loading ~ div,
.loading ~ span,
.loading ~ form,
.loading ~ p,
.loading ~ img,
.loading ~ input {
	transition: filter transition.$base;
}

.loading.is-visible {
	visibility: visible;
	opacity: 1;
	transition: opacity transition.$base;
}

.loading.is-visible ~ div,
.loading.is-visible ~ span,
.loading.is-visible ~ form,
.loading.is-visible ~ p,
.loading.is-visible ~ img,
.loading.is-visible ~ input {
	filter: blur(units.px2rem(4px));
	pointer-events: none;
}
