@use "sass:color";
@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/box-shadow";
@use "../../settings/colors";
@use "../../settings/theme";
@use "../../settings/layout";
@use "../../settings/header";
@use "../../settings/typography" as typography-settings;
@use "../../settings/transition";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.photos-grid {
	position: relative;
	display: flex;
	align-items: center;
	gap: spacing.set(1);
	padding: spacing.set(1);
	border-radius: map.get(border-radius.$values, large);
	background-color: map.get(colors.$grays, 100);

	@include breakpoint.up(sm) {
		margin-bottom: spacing.set(4);
	}
}

.photos-grid--fullsize {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	padding: unset;
	border-radius: 0;
	background-color: unset;
}

.photos-grid__note {
	@include typography.style(text1);

	flex: 2.5;
	margin-bottom: 0;
	font-weight: map.get(typography-settings.$font-weight-values, semibold);
	font-family: typography-settings.$font-family-primary;
}

.photos-grid__preview-wrap {
	display: block;
}

.photo-grid__img {
	position: relative;
	flex: 1;
	overflow: hidden;
	border-radius: map.get(border-radius.$values, large);
}

.photo-grid__img .photos-grid__preview-wrap {
	aspect-ratio: 10 / 8;
}

.photo-grid__1 {
	grid-column: 1 / -1;
}

.photo-grid__1 .photos-grid__preview-wrap {
	aspect-ratio: 5 / 7;
}

.photos-grid__preview-wrap--empty {
	background-image: url(../../../img/icon-profile-def.svg);
	background-position: bottom units.px2rem(-5px) center;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: map.get(colors.$grays, 200);
}

.photos-grid__preview-wrap--blur {
	background-color: color.change(colors.$white, $alpha: 0.4);
	filter: blur(#{units.px2rem(20px)});
}

.photo-grid__hidden {
	position: absolute;
	inset: 0;
	display: grid;
	flex-direction: column;
	align-content: center;
	justify-items: center;
}

.photo-grid__hidden .icon {
	font-size: map.get(typography-settings.$font-size-values, 10);
}

.photo-grid__img--overlay-mini .icon {
	font-size: map.get(typography-settings.$font-size-values, 8);
}
