/* stylelint-disable */
.custom-info {
	width: 100%;
}

.custom-info__item {
	padding: 5px;
	margin-bottom: 5px;
	border-radius: 4px;
	border: 1px solid $grey;
}

.custom-info__item__head {
	display: flex;
	margin-bottom: 5px;
}

.custom-info__item__label {
	flex-grow: 1;
	padding: 5px;
	font-family: $font-family;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: -0.02px;
	text-align: left;
	color: #313131;
}

.custom-info__item__head > a {
	height: auto;
	padding: 3px 5px 5px;
}

.custom-info__item__content {
	min-height: 35px;
	padding: 9px 10px;
	font-family: $font-lato;
	font-size: 14px;
	font-weight: 400;
	text-align: left;
	color: #606060;
	border-radius: 3px;
	background-color: #d8d8d8;
}

.custom-info__create {
	margin-top: 15px;
	padding: 10px 10px 15px;
	border-radius: 4px;
	background-color: #f5f8f8;
}

.custom-info__create__title {
	margin-bottom: 10px;
	font-family: $font-family;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: -0.02px;
	text-align: left;
	color: #313131;
}

.custom-info__create__input {
	margin-top: 0;
}

.custom-info__create__input > label {
	visibility: hidden;
	opacity: 0;
}

.custom-info__create__input > input {
	background-color: #fff;
}
/* stylelint-enable */
