@use "sass:color";
@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/header";
@use "../../settings/transition";
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.nav-toggle {
	display: inline-block;
	width: units.px2rem(36px);
	padding: spacing.set(2) units.px2rem(8px);
	margin-right: units.px2rem(-8px);
	cursor: pointer;

	@include breakpoint.up(lg) {
		display: none;
	}
}

.nav-toggle__line {
	display: block;
	opacity: 1;
	width: 100%;
	height: units.px2rem(2px);
	margin-top: units.px2rem(4px);
	margin-bottom: units.px2rem(4px);
	border-radius: map.get(border-radius.$values, full);
	background-color: colors.$black;
	transform-origin: 50% 50%;
	transition: transform transition.$base, opacity transition.$base;
}

.nav-toggle--nav-is-open .nav-toggle__line {
	&:first-of-type {
		transform: translateY(#{units.px2rem(6px)}) rotate(45deg);
	}

	&:nth-of-type(2) {
		opacity: 0;
	}

	&:last-of-type {
		transform: translateY(#{units.px2rem(-6px)}) rotate(-45deg);
	}
}
