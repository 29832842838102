/* stylelint-disable */
.questionnaire-header {
	position: fixed;
	top: 73px;
	right: 0;
	left: 0;
	z-index: 999;
	display: flex;
	align-items: center;
	width: 100%;
	height: 63px;
	box-sizing: border-box;
	padding: 0 10px;
	background-color: #ffffff;
	border-bottom: 1px solid #eee;

	@media (min-width: 900px) {
		padding: 0 20px;
	}
}

.questionnaire-header__nav {
	flex-grow: 1;
	overflow: auto;
	white-space: nowrap;
}

.questionnaire-header__list {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 63px;
	padding: 0;
	margin: 0;
	list-style: none;
	border: 0;
}

.questionnaire-header__list__item {
	position: relative;
	padding: 0 15px;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		width: 8px;
		height: 8px;
		box-sizing: border-box;
		border-top: 1px solid #c4c4c4;
		border-right: 1px solid #c4c4c4;
		transform: translate(25%, -50%) rotate(45deg);
	}

	&:first-child::after {
		width: 1px;
		height: 24px;
		background-color: #c4c4c4;
		transform: translate(50%, -50%);
	}

	&:last-child::after {
		display: none;
	}
}

.questionnaire-header__link {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 63px;
	padding: 10px;
	opacity: 0.4;
	font-family: $font-family;
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	letter-spacing: -0.1px;
	line-height: normal;
	text-align: left;
	color: #313131;

	&::after {
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		height: 4px;
		visibility: hidden;
		opacity: 0;
		background-color: #fe5c4f;
		border-radius: 2px 2px 0 0;
	}
}

.questionnaire-header__link.active {
	opacity: 1;

	&::after {
		visibility: visible;
		opacity: 1;
	}
}

.questionnaire-header__control {
	white-space: nowrap;

	@media (min-width: 900px) {
		margin: 0 5px;
	}
}
/* stylelint-enable */
