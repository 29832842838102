/* stylelint-disable */
.sliding-content {
  max-height: 0;
  overflow: hidden;
  margin-right: -10px;
  margin-left: -10px;
  padding-right: 10px;
  padding-left: 10px;
  transition: max-height 0.5s ease-out;
}

.sliding-content--open {
  max-height: 200px;
  transition-timing-function: ease-in;
}
/* stylelint-enable */
