/* stylelint-disable */
[hidden] {
	display: none;
}
[novisible] {
	visibility: hidden;
	pointer-events: none;
}
.opacity-1 {
	opacity: 1;
}
.js-hidebehind {
	transition: all .3s ease;
	&.is-behind {
		opacity: .5;
		margin-right: -15vw;
	}
}
.transf-10 {
	transform: translateX(-9px);
}
.noscroll {
	overflow: hidden;
	height: 100vh;
}
.overflow-hidden {
	overflow: hidden;
}
.nowrap {
	white-space: nowrap;
}
.nofocus {
	&:focus {
		outline: none;
	}
}
.hyphens {
	hyphens: auto;
}
.break-all {
	word-break: break-all;
}
.cursor--pointer {
	cursor: pointer;
}
.position--relative,
.pos--rel {
	position: relative;
}
.text-align--center {
	text-align: center;
}
.text-align--right {
	text-align: right;
}
.text-align--left {
	text-align: left;
}
.text--upper {
	text-transform: uppercase;
}
.text-transform--capitalize {
	text-transform: capitalize;
}
.text-transform--reset {
	text-transform: none;
}
.float--right {
	float: right;
}
.color--main {
	color: $main-color;
}
.color--grey {
	color: $grey;
}
.color--dark-grey {
	color:$darkGrey;
}
.color--medium-grey {
	color: $mediumGrey;
}
.color--green {
	color: $alert-green;
}
.color--white {
	color: white;
}
.color--typo-drk {
	color: $typo-dark;
}
.color--grey2 {
	color: #787878;
}
.bg-white {
	background-color: white;
}
.bg-grey {
	background-color: #F9FAFA;
}
.height--100 {
	height: 100%;
}
.min-height--100 {
	min-height: 100%;
}
.width--100 {
	width: 100%;
}
.min-width {
	min-width: 100%;
}
.fw--bold {
	font-weight: bold;
}
.p--strong {
	@extend .fw--bold;
	font-family: $font-lato;
	font-size: 13px;
	letter-spacing: 0.26px;
	line-height: 16px;
}
.z-index--down {
	z-index: -1;
}
.z-index--1 {
	z-index: 1;
}
.z-index--2 {
	z-index: 2;
}
.z-index--3 {
	z-index: 3;
}
.w-100 {
	width: 100%;
}
.pd--0 {
	padding: 0;
}
.mr-r--17 {
	margin-right: 17px;
}
.mr-i--20 {
	margin-inline: 20px;
}
.mr-i--30 {
	margin-inline: 30px;
}
.mr-l--auto {
	margin-left: auto;
}
.mr-r--auto {
	margin-right: auto;
}
.mr-centering {
	margin-left: auto;
	margin-right: auto;
}
.mr-15 {
	margin: 15px;
}
.mr-tb-0 {
	margin-top: 0;
	margin-bottom: 0;
}
.mr--auto {
	margin: auto;
}
.mr--0 {
	margin: 0;
}
.mr-t--60 {
	margin-top: 50px;
}
.mr-t--50 {
	margin-top: 50px;
}
.mr-t--44 {
	margin-top: 44px;
}
.mr-t--40 {
	margin-top: 40px;
}
.mr-t--35 {
	margin-top: 35px;
}
.mr-t--30 {
	margin-top: 30px;
}
.mr-t--25 {
	margin-top: 25px;
}
.mr-t--21 {
	margin-top: 21px;
}
.mr-t--18 {
	margin-top: 18px;
}
.mr-t--15 {
	margin-top: 15px;
}
.mr-t--10 {
	margin-top: 10px;
}
.mr-t--5 {
	margin-top: 5px;
}
.mr-t--0 {
	margin-top: 0px;
}
.mr-b--50 {
	margin-bottom: 50px;
}
.mr-b--44 {
	margin-bottom: 44px;
}
.mr-b--40 {
	margin-bottom: 40px;
}
.mr-b--35 {
	margin-bottom: 35px;
}
.mr-b--30 {
	margin-bottom: 30px;
}
.mr-b--25 {
	margin-bottom: 25px;
}
.mr-b--21 {
	margin-bottom: 21px;
}
.mr-b--18 {
	margin-bottom: 18px;
}
.mr-b--15 {
	margin-bottom: 15px;
}
.mr-b--10 {
	margin-bottom: 10px;
}
.mr-b--2 {
	margin-bottom: 2px;
}
.mr-b--0 {
	margin-bottom: 0px;
}
.mr--10-15 {
	margin: 10px 15px;
}
.pa-i--10 {
	padding-inline: 10px;
}
.d--flex {
	display: flex;
}
.flex-wrap {
	flex-wrap: wrap;
}
.flex-row {
	display: flex;
	flex-flow: row;
}
.flex-column {
	display: flex;
	flex-flow: column;
}
.flex-100 {
	flex: 1 1 100%;
}
.flex-1 {
	flex: 1;
	width: auto;
}
.flex-wrap {
	flex-flow: wrap;
	gap: 0;
}
.width-auto {
	width: auto;
}
.flex-column--centered {
	@extend .flex-column;
	align-items: center;
}
.flex--centered {
	@extend .flex-column;
	align-items: center;
	justify-content: center;
}
.flex-row--between {
	@extend .flex-row;
	justify-content: space-between;
}
.flex-row--around {
	@extend .flex-row;
	justify-content: space-around;
}
.justify-content--start {
	justify-content: flex-start;
}
.justify-content--end {
	justify-content: flex-end;
}
.justify-content--between {
	justify-content: space-between;
}
.justify-content--around {
	justify-content: space-around;
}
.justify-content--center {
	justify-content: center;
}
.align-items--center {
	align-items: center;
}
.align-items--start {
	align-items: flex-start;
}
.align-items--end {
	align-items: flex-end;
}
/* stylelint-enable */
