@use "sass:map";
@use "../settings/breakpoints";

@mixin up($breakpoint) {
	@if not map.has-key(breakpoints.$values, $breakpoint) {
		@content;
	}

	@else {
		@media (min-width: map.get(breakpoints.$values, $breakpoint)) {
			@content;
		}
	}
}
