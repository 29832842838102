@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/theme";
@use "../../tools/spacing";
@use "../../tools/units";

$slider-bar-circle-size: units.px2rem(22px);
$slider-bar-height: units.px2rem(2px);

.jsr {
	position: relative;
	z-index: 1;
	display: block;
	width: 100%;
	margin: spacing.set(2) 0 units.px2rem(28px) 0;
	user-select: none;
}

.jsr_rail-outer {
	position: relative;
	padding: spacing.set(2) 0;
	margin: 0 units.px2rem(13px);
	cursor: pointer;
}

.jsr_rail {
	z-index: 1;
	height: $slider-bar-height;
	border-radius: map.get(border-radius.$values, tiny);
	background-color: map.get(colors.$grays, 200);
}

.jsr_bar {
	position: absolute;
	z-index: 2;
	height: $slider-bar-height;
	background-color: colors.$primary;
	pointer-events: none;
	cursor: move;
}

.jsr_bar--limit {
	z-index: 1;
	background-color: map.get(colors.$grays, 700);
	cursor: auto;
}

.jsr_slider {
	position: absolute;
	top: calc(#{$slider-bar-height} / 2 + #{units.px2rem(10px)});
	left: 0;
	z-index: 3;
	width: $slider-bar-circle-size;
	height: $slider-bar-circle-size;
	outline: 0;
	transform: translate(-50%, -50%);
	cursor: col-resize;
	transition: background-color 0.1s ease-in-out;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: $slider-bar-circle-size;
		height: $slider-bar-circle-size;
		border: units.px2rem(2px) solid colors.$primary;
		border-radius: map.get(border-radius.$values, full);
		background-color: theme.$page-background-color;
		transform: translate(-50%, -50%);
	}

	&:focus::before {
		background: colors.$primary;
	}
}

.jsr_canvas {
	display: none;
}

.jsr_lockscreen {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
