@use "sass:map";
@use "../../settings/colors";
@use "../../settings/typography" as typography-settings;
@use "../../settings/theme";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/units";
@use "../../tools/typography";

.filter-range {
	position: relative;
	display: flex;
	align-items: flex-end;
	column-gap: spacing.set(4);
}

.filter-range__label {
	@include typography.style(text1);

	flex: unset;
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-family: typography-settings.$font-family-primary;
	color: map.get(colors.$grays, 800);
	letter-spacing: units.px2em(-0.02px, map.get(typography-settings.$font-size-values, 4));

	@include breakpoint.up(xxl) {
		flex: 1;
	}
}

.filter-range__title {
	@include typography.style(text2);

	margin-bottom: units.px2em(8px, map.get(typography-settings.$font-size-values, 3));
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-family: typography-settings.$font-family-primary;
	color: map.get(colors.$grays, 800);
}

.filter-range__inputs {
	position: relative;
	flex: 1;
	width: spacing.set(33);

	@include breakpoint.up(xxl) {
		flex: unset;
	}
}

.filter-range__inputs--units > .input {
	padding-right: units.px2rem(22px);
	text-align: right;
}

.filter-range__inputs > .input {
	position: absolute;
	bottom: 0;

	&:first-of-type {
		left: 0;
	}

	&:last-of-type {
		right: 0;
	}
}

.filter-range__input-unit {
	@include typography.style(input-small);

	position: absolute;
	bottom: 0;
	line-height: spacing.set(4);
	color: map.get(colors.$grays, 800);
	pointer-events: none;

	&:first-of-type {
		left: units.px2rem(28px);
	}

	&:last-of-type {
		left: calc(100% - #{units.px2rem(22px)});
	}
}

.filter__submit {
	position: absolute;
	visibility: hidden;
	width: 0;
	height: 0;
	padding: 0;
}
