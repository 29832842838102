/* stylelint-disable */
.jsr_label {
	position: absolute;
	top: calc(10px + 5px + 15px / 1.5);
	font-size: 0.9em;
	white-space: nowrap;
	color: $main-color;
	font-size: 11px;
	font-weight: 600;
	letter-spacing: -0.02px;
	line-height: 14px;
	text-align: center;
	z-index: 2;
	span {
		color: #606060;
		font-weight: 500;
	}
}

.jsr_label--minmax {
	z-index: 1;
	color: #999;
	background: #333;
	transition: opacity 0.2s ease-in-out;
}

/* Merged labels */
.jsr_label .jsr_label {
	position: static;
	display: inline-block;
	font-size: 1em;
	padding-top: 0;
	padding-right: 0;
	padding-bottom: 0;
}

.jsr_label .jsr_label::before {
	content: ' - ';
	color: #606060;
	font-weight: 500;
}
/* stylelint-enable */
