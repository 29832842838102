/* stylelint-disable */
.dashboard__wrap {
	margin-top: 11px;
	margin-left: 10px;
	margin-right: 10px;
	width: calc(100% - 20px);
	display: flex;
}
.up-chart__bg {
	height: 360px;
	border-radius: 4px;
	background-color: white;
	padding: 10px;
	box-shadow: 0 6px 30px -5px rgba(106,120,124,0.1);
	max-width: calc(100% - 264px);
	width: 100%;
}
.up-chart__bg--sms {
	flex: 0 0 244px;
	margin-left: 20px;
}
.up-chart__roles {
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	align-items: center;
}
.up-chart__roles__inner {
	display: flex;
	flex-flow: row;
	align-items: center;
	width: 100%;
	overflow: auto;
	padding-bottom: 30px;
	margin-bottom: -30px;
}
.up-chart__role {
	display: inline-block;
	margin-right: 20px;
	margin-left: 20px;
	color: #A5A5A5;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 0.13px;
	line-height: 18px;
}
.up-chart__role__dash {
	height: 12px;
	width: 12px;
	background-color: currentColor;
	display: inline-block;
	border-radius: 50%;
	vertical-align: middle;
}
.up-chart__role__dash--op {
	opacity: .5;
}
.up-chart__role__name {
	color: #606060;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.15px;
	line-height: 15px;
	margin-bottom: 5px;
}
.up-chart {
	position: relative;
	height: 240px;
	margin-top: 44px;
	padding-top: 10px;
	padding-left: 30px;
	width: calc(100% - 30px);
	background-size: calc(100% - 30px) 60px;
	background-repeat: repeat-y;
	background-position: right top 10px;
	background-image: linear-gradient(to bottom, #eeeeee 1px, #ffffff 1px);
	overflow: hidden;
}
.up-chart__sidenums {
	position: absolute;
	top: 10px;
	left: 30px;
	height: 180px;
	width: 10px;
	pointer-events: none;
}
.up-chart__sidenum {
	position: absolute;
	left: auto;
	right: calc(100% + 10px);
	color: #A5A5A5;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 0.13px;
	line-height: 14px;
	transform: translateY(-50%);
	&:nth-child(1) {
		top: 0;
	}
	&:nth-child(2) {
		top: 60px;
	}
	&:nth-child(3) {
		top: 120px;
	}
	&:nth-child(4) {
		top: 180px;
	}
}
.up-chart__inner {
	height: 230px;
	white-space: nowrap;
	font-size: 0;
	overflow-x: auto;
	overflow-y: hidden;
	padding-bottom: 50px;
}

.up-chart__day {
	margin-right: 30px;
	margin-left: 20px;
	position: relative;
	min-width: 80px;
	height: 180px;
	display: inline-block;
}
.up-chart__day__date {
	position: absolute;
	left: 0;
	right: 0;
	top: calc(100% + 11px);
	color: #A5A5A5;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 0.13px;
	line-height: 14px;
	text-align: center;
	cursor: pointer;
}
.up-chart__day-charts {
	display: flex;
	flex-flow: row nowrap;
	height: 100%;
	align-items: flex-end;
}
.up-chart__day-chart {
	height: 100%;
	width: 17px;
	flex: 0 0 17px;
	margin-right: 1px;
	position: relative;
	z-index: 0;
	&::before {
		content: " ";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		box-shadow: 0 -2px 6px 0 currentColor;
		opacity: 0;
		z-index: -1;
		border-radius: 2px 2px 0 0;
	}
	&:hover {
		&::before {
			opacity: .5;
		}
	}
}
.up-chart__day-chart__full,
.up-chart__day-chart__confirmed {
	position: absolute;
	width: 100%;
	background-color:currentColor;
	top: auto;
	bottom: 0;
	left:0;
	border-radius: 2px 2px 0 0;
}
.up-chart__day-chart__full {
	opacity: .5;
}


.down-charts {
	margin-top: 40px;
	width: 100vw;
}
.down-chart {
	display: none;
	&.is-active {
		display: block;
	}
}
.down-chart__head {
	display: flex;
	flex-flow: row;
	align-items: center;
	color: #A5A5A5;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 0.13px;
	line-height: 14px;
	margin-bottom: 21px;
	padding: 0 30px;
}
.down-chart__help {
	margin-left: auto;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	color: #A5A5A5;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 0.13px;
	line-height: 22px;
	span::before {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		height: 16px;
		width: 16px;
		margin-right: 8px;
		margin-left: 31px;
		border-radius: 50%;
	}
	span {
		&:first-child::before {
			height: 17px;
			width: 17px;
			border: 1px dashed #A6A6A6;
		}
		&:nth-child(2)::before {
			background-color: #E1E1E1;
		}
		&:nth-child(3)::before {
			background-color: #618FC1;
		}
	}
}
.down-chart__sd-name {
	color: #313131;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}
.down-chart__sd-link {
	display: inline-block;
	vertical-align: middle;
	height: 13px;
	width: 13px;
	margin-left: 5px;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(../../img/icons/icon-link.jpg);
}
.down-chart__arrows {
	margin-right: 13px;
}
.down-chart__arrow-next,
.down-chart__arrow-prev {
	height: 32px;
	width: 32px;
	background-color: #ECECEC;
	display: inline-block;
	border-radius: 4px;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: auto 12px;
}
.down-chart__arrow-prev {
	background-image: url(../../img/icons/icon-chevron-left.svg);
	&:hover {
		background-image: url(../../img/icons/icon-chevron-left-color.svg);
	}
}
.down-chart__arrow-next {
	background-image: url(../../img/icons/icon-chevron-right.svg);
	&:hover {
		background-image: url(../../img/icons/icon-chevron-right-color.svg);
	}
}
.down-chart__boxes {
	white-space:nowrap;
	overflow:auto;
	padding-bottom: 40px;
}
.down-chart__box {
	width: 195px;
	display:inline-block;
	background-color: white;
	text-align:center;
	padding:15px 24.5px 24.5px;
	margin: 0 10px;
	box-shadow: 0 6px 30px -10px rgba(106,120,124,0.1);
	&:first-child {
		margin-left: 30px;
	}
	&:last-child {
		margin-right: 30px;
	}
}
.down-chart__box__svg {
	width:146px;
	height:146px;
	svg {
		width:100%;
		height:100%;
	}
}
.down-chart__box__head {
	color: #313131;
	font-size: 24px;
	font-weight: 600;
	line-height: 29px;
	text-align: center;
	margin-bottom: 20px;
}
.down-chart__box__name {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
/* stylelint-enable */
