@use "sass:color";
@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/header";
@use "../../settings/typography" as typography-settings;
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.premium-box-account {
	padding: spacing.set(4) spacing.set(4);
	margin-inline: spacing.set(-3);
	margin-bottom: spacing.set(2);
	text-align: center;
	border-block: units.px2rem(1px) solid map.get(colors.$grays, 300);
	background-color: map.get(colors.$grays, 066);

	@include breakpoint.up(lg) {
		padding-inline: spacing.set(8);
	}
}
