@use "sass:color";
@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/box-shadow";
@use "../../settings/colors";
@use "../../settings/header";
@use "../../settings/layout";
@use "../../settings/typography" as typography-settings;
@use "../../settings/theme";
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/units";
@use "../../tools/typography";

.filter {
	position: fixed;
	top: 0;
	left: 0;
	z-index: map.get(z-index.$values, filter-aside);
	display: none;
	width: 100vw;
	height: 100vh;
	/* stylelint-disable unit-no-unknown */
	height: 100svh;
	/* stylelint-enable unit-no-unknown */
	padding-inline: map.get(layout.$container, padding-inline, xs);
	overflow-x: hidden;
	overflow-y: auto;
	background-color: color.change(colors.$black, $alpha: 0.4);
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media (hover: none) {
		align-items: flex-start;
	}

	@include breakpoint.up(xxl) {
		top: header.$height;
		display: block;
		width: unset;
		min-width: map.get(layout.$aside-width, small);
		height: unset;
		padding-inline: unset;
		overflow-x: unset;
		overflow-y: unset;
		background-color: unset;
	}
}

.filter--is-open {
	display: block;
}

.filter__dialog {
	position: relative;
	display: flex;
	align-items: center;
	min-height: calc(100% - #{spacing.set(6)});

	@media (hover: none) {
		align-self: flex-start;
	}
}

.filter__content {
	flex-shrink: 0;
	width: spacing.set(125);
	max-width: 95vw;
	padding: units.px2rem(12px);
	margin: spacing.set(3) auto;
	overflow-x: hidden;
	overflow-y: auto;
	border-radius: map.get(border-radius.$values, large);
	background-color: theme.$page-background-color;
	box-shadow: map.get(box-shadow.$values, large);
	scrollbar-width: none;

	@include breakpoint.up(xxl) {
		z-index: map.get(z-index.$values, filter-aside);
		max-width: map.get(layout.$aside-width, small);
		height: calc(100vh - #{header.$height});
		margin: unset;
		border-right: units.px2rem(1px) solid map.get(colors.$grays, 200);
		border-radius: unset;
		box-shadow: unset;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

.filter--is-mini {
	min-width: 0;
}

.filter--is-mini .filter__content {
	width: auto;
	padding-inline: spacing.set(2);
}

.filter__header {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	column-gap: spacing.set(2);
	margin-bottom: spacing.set(2);
}

.filter__title {
	@include typography.style(text2);

	flex: 1;
	margin-bottom: 0;
	font-weight: map.get(typography-settings.$font-weight-values, semibold);
	line-height: map.get(typography-settings.$line-height-values, 1);
	font-family: typography-settings.$font-family-primary;
	letter-spacing: units.px2em(-0.1px, map.get(typography-settings.$font-size-values, 3)),;
	color: map.get(colors.$grays, 700);
}

.filter-selects {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: spacing.set(2);
}

.js-drAside-min {
	display: none;

	@include breakpoint.up(xxl) {
		display: inline-flex;
	}
}

.js-drAside-filters {
	display: inline-flex;

	@include breakpoint.up(xxl) {
		display: none;
	}
}
