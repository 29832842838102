/* stylelint-disable */
.detail-textfield-wrap {
	@include wrap();
}

.detail-textfield {
	@include field();
}

.detail-textfield[data-unit] {
	position: relative;

	&::after {
		content: attr(data-unit);
		position: absolute;
		right: 0;
		top: 0;
		max-height: 44px;
		font-size: 10px;
		padding: 7px;
		display: flex;
		align-items: center;
		background-color: $form-color;
		border-radius: 0 4px 4px 0;
		color: grey;
		font-weight: 600;
		box-sizing: border-box;
		border: 1px solid transparent;
		background-clip: padding-box;
		pointer-events: none;
	}
}

.detail-textfield__label {
	@include label();

	max-width: 80px;
	hyphens: auto;
	overflow-wrap: break-word;
}

.detail-textfield__input {
	@include input();

	height: 27px;
}

.detail-textfield.has-error .error-container {
	@include error-container();
}
/* stylelint-enable */
