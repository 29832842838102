@use "sass:map";
@use "../settings/colors";

/* stylelint-disable */
.form-error-container {
	@extend %form-error;
	margin-bottom: 15px;
}

/* FILE INPUTS */
.file-line {
	.error-container {
		position: relative;
		display: flex;
		align-items: center;
		flex-basis: 100%;
		margin-top: 5px;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 0.24px;
		color: #df3939;
		text-align: left;

		&::before {
			content: '';
			display: inline-block;
			width: 18px;
			height: 18px;
			flex-shrink: 0;
			margin-right: 2px;
			background-image: url(../../img/icons/icon-error-color.svg);
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}

.file-line.has-error .file-line__preview {
	border: 2px solid #df3939;
}

/* stylelint-enable */
