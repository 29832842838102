@use "../../tools/breakpoint";
@use "../../tools/units";

.logo-upper {
	padding-top: units.px2rem(19px);
	padding-bottom: units.px2rem(63px);

	@include breakpoint.up(md) {
		height: units.px2rem(163px);
		padding-top: units.px2rem(42px);
		padding-bottom: units.px2rem(42px);
		text-align: center;
	}
}

.logo-upper__img {
	height: units.px2rem(35px);

	@include breakpoint.up(md) {
		height: units.px2rem(68px);
	}
}
