/* stylelint-disable */
.aside,
.aside--modal.aside--modal {
	position: fixed;
	top: $header-height;
	right: 0;
	bottom: 0;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	box-sizing: content-box;
	visibility: hidden;
	opacity: 0;
	overflow: hidden;
	width: $aside-width-xs;
	height: auto;
	min-height: calc(100vh - #{$header-height});
	min-height: calc(var(--vh, 1vh) * 100 - #{$header-height});
	padding-left: 15px;

	&:not(:empty) {
		z-index: 2;
		visibility: visible;
		opacity: 1;
	}

	.nav {
		padding: 0 20px;
	}

	.nav-link + .nav-link {
		margin-left: 30px;
	}

	@media (min-width: map-get($breakpoints, sm)) {
		width: $aside-width;
	}
}

.aside--modal:not(:empty).aside--modal:not(:empty) {
	top: 0;
	z-index: $z-index-modal-aside;
	min-height: 100vh;
}

.aside--modal.aside--modal > div > .aside-close {
	top: 19px;
}

.aside--modal ~ .dr-modal {
	width: calc(100vw - #{$aside-width});
}

.aside-body {
	@extend %form-box-shadow;

	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	height: 100%;
	padding-right: 20px;
	margin-right: -20px;
	box-sizing: content-box;
	border-left: 1px solid #EEEEEE;
	background-color: #FFFFFF;
}

.modal-open .aside-body {
	height: 100vh;
}

.aside-close {
	@extend %icon-circle--close;

	position: fixed;
	top: calc(#{$header-height} + 19px);
	right: $aside-width-xs - 13px;
	z-index: 55;

	@media (min-width: map-get($breakpoints, sm)) {
		right: $aside-width - 13px;
	}
}

.aside-padding {
	padding: 8px;
	overflow: hidden;
	flex: 0 0 auto;

	.modal-line,
	.optional-line {
		left: -8px;
		padding-right: 16px;
		box-sizing: content-box;
		position: relative;
	}
}

.aside-inner {
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	width: 100%;
	height: auto;
}

.aside--down {
	top: $header-height + $dr-height;
	min-height: calc(100vh - #{$header-height + $dr-height});

	&:not(:empty) {
		z-index: 30;
	}

	.aside-body {
		box-shadow: 0 2px 15px 0 rgba(0,0,0,0.15);
	}
}

.aside--down > div > .aside-close {
	top: calc(#{$header-height + $dr-height} + 19px);
}

.aside--wide {
	@media (min-width: map-get($breakpoints, md)) {
		width: $aside-width-wide;
	}

	@media (min-width: map-get($breakpoints, xxl)) {
		width: $aside-width-wide-xxl;
	}
}

.aside--wide > div > .aside-close {
	@media (min-width: map-get($breakpoints, md)) {
		right: $aside-width-wide - 13px;
	}

	@media (min-width: map-get($breakpoints, xxl)) {
		right: $aside-width-wide-xxl - 13px;
	}
}

.aside__footer {
	margin-top: 50px;
	padding-bottom: 8px;
	display: flex;
	flex-flow: row;
	justify-content: space-between;
}

.aside-flex {
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	justify-content: center;
	align-items: flex-start;
	min-height: 100%;
	margin-top: -61px;
}

.aside-table {
	width: 100%;
	margin-top: 61px;
	margin-left: 0;
	margin-right: auto;
}

.aside-table--aside {
	margin-right: $aside-width-xs;

	@media (min-width: map-get($breakpoints, sm)) {
		margin-right: $aside-width;
	}
}

.aside--second:not(:empty) {
	z-index: 3;
}

.aside--edit-tags {
	min-height: 800px;
}
/* stylelint-enable */
