/* stylelint-disable */
.project-st__table-wrap {
	background-color: #F9FAFA;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	height: 318px;
	padding: 8px 10px;
	overflow: auto;
}
.project-st__table-wrap-no-height {
	margin-top: 10px;
	background-color: #F9FAFA;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	padding: 8px 10px;
	overflow: auto;
}

.project-st__table {
	width: 100%;
	border-spacing: 0;
	position: relative;
	border-collapse: separate;
	border-spacing: 0 5px;
	font-family: $font-lato;
	font-size: 13px;
	font-weight: bold;
	letter-spacing: 0.26px;
	line-height: 16px;
}
.project-st__table-row {
	border: 1px solid #E5E5E5;
	border-radius: 4px;
	height: 60px;
	td:first-child {
		display: flex;
		align-items: center;
		height: 60px;
		padding-left: 20px;
		&::before {
			content: " ";
			position: absolute;
			left: 0;
			width: 100%;
			height: 60px;
			background-color: #FFFFFF;
			border: 1px solid #E5E5E5;
			border-radius: 4px;
			box-shadow: 0 6px 30px -5px rgba(106,120,124,0.1);
			opacity: .6;
		}
	}
	td:last-child {
		padding-right: 20px;
		text-align: right;
	}
}
/* stylelint-enable */
