/* stylelint-disable */
.p-strong {
	font-size: 13px;
	font-weight: 600;
	letter-spacing: -0.02px;
	line-height: 16px;
}

.p-note {
	color: $typo-dark;
	font-family: $font-lato;
	font-size: 12px;
	letter-spacing: 0.15px;
	line-height: 15px;
	text-decoration: none;
	margin: 0;
	margin-top: 15px;
}
.p-fade {
	color: $p-fade;
	font-family: $font-lato;
	font-size: 14px;
	line-height: 20px;
}
.p--sm {
	font-size: 11px;
	letter-spacing: 0.13px;
	line-height: 14px;
}
.p-no-project {
	text-align: center;
	color: $darkGrey;
	font-size: 16px;
	font-weight: 600;
	line-height: 23px;
}
.sm-title {
	color: #606060;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: -0.02px;
	line-height: 15px;
	margin-bottom: 0;
}
.email-message {
	margin-bottom: -10px;
	margin-top: 5px;
	font-size: 12px;
}
.dash-title {
	color: #606060;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: -0.02px;
	line-height: 15px;
	margin-top: 0;
	margin-bottom: 0;
	&::before {
		content: " ";
		position: relative;
		display: inline-block;
		height: 10px;
		width: 10px;
		background-color: #FE5C4F;
		border-radius: 50%;
		margin-right: 10px;
	}
}
.sd-date {
	color: #606060;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: -0.02px;
	line-height: 15px;
	margin-top: 18px;
	margin-bottom: 6px;
}
.typo-sort-by {
	color: #A5A5A5;
	font-size: 11px;
	letter-spacing: 0.13px;
	line-height: 14px;
}
/* stylelint-enable */
