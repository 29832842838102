/* stylelint-disable */
.projects-section {
	margin-top: -52px;
}
.projects {
	background-color: #E7E9ED;
	width: 100%;
	min-height: 500px;
	display: flex;
	justify-content: center;
	height: 100%;
	padding-bottom: 50px;
}
.projects-container {
	padding: 10px 5vw 30px;
	max-width: calc(1140px + 10vw + 30px);
	width: 100%;

	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
}
.project-box {
	position: relative;
	height: 270px;
	width: 360px;
	flex: 0 0 calc(33.3% - 30px);
	margin: 15px;
	border-radius: 4px;
	background-color: #FFFFFF;
	box-shadow: 0 1px 8px -3px rgba(0,0,0,0.3);

	padding: 8px 13px 15px 13px;
	display: flex;
	flex-flow: column;
	justify-content: space-between;

	transition: all .2s ease;
	.show-on-hover {
		opacity: 0;
		transition: all .1s ease;
	}
	&:hover {
		z-index: 1;
		box-shadow: 0 1px 18px -3px rgba(0,0,0,0.3);
		transform: translateY(-5px);
		.show-on-hover {
			opacity: 1;
		}
	}
}
.project-box__actions {
	position: absolute;
	top: 0;
	right: 0;
}
.project-desc {
	height: 80px;
	overflow: hidden;
}
.project-box__owner-box {
	color: #A5A5A5;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 0.13px;
	line-height: 14px;

	display: flex;
	flex-flow: row;
}
.project-box__owner-status {
	border-right: 1px solid #D8D8D8;
	padding-right: 7px;
	margin-right: 10px;
	span {
		height: 34px;
		width: 34px;
		border-radius: 50%;
		background-color: #FE5C4F;
		color: #FFFFFF;
		font-size: 11px;
		font-weight: 600;
		line-height: 34px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.project-box__owner {
	color: #313131;
	font-family: $font-lato;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: normal;
	font-weight: normal;
}
.project-box__dates {
	border-radius: 15px;
	background-color: #46546A;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.15px;
	line-height: 15px;
	text-align: center;
	padding: 7px 12px;
	margin-bottom: -5px;
}
.project-state {
	left: -20px;
}
.project-state__btn {
	text-transform: none;
	font-weight: bold;
	padding: 10px 8px;
	color: #606060;
	&.active {
		color: $main-color;
	}
}
/* stylelint-enable */
