@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/typography" as typography-settings;
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.form-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: spacing.set(2);
}
