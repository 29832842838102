@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/box-shadow";
@use "../../settings/colors";
@use "../../settings/header";
@use "../../settings/layout";
@use "../../settings/theme";
@use "../../settings/transition";
@use "../../settings/typography" as typography-settings;
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.tabler {
	position: relative;
	z-index: map.get(z-index.$values, base);
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 spacing.set(1);
}

.tabler__head {
	position: relative;
	z-index: 2;
}

.tabler__row {
	position: relative;
	z-index: map.get(z-index.$values, base);
	padding: units.px2rem(5px) units.px2rem(8px);
	overflow: hidden;
	white-space: nowrap;

	&:last-child {
		padding-right: 0;
	}
}

.tabler__row--head {
	height: units.px2rem(45px);
}

.tabler__th {
	padding-inline: units.px2rem(8px);
}

.tabler__th--search {
	padding-left: 0;
	text-align: left;
}

.tabler__th--actions {
	min-width: spacing.set(56);
	padding-right: 0;
}

.tabler__th-link {
	@include typography.style(text1);

	display: flex;
	align-items: center;
	column-gap: units.px2rem(8px);
	padding: 0;
	font-weight: map.get(typography-settings.$font-weight-values, semibold);
	font-family: typography-settings.$font-family-primary;
	text-decoration: none;
	white-space: nowrap;
	color: map.get(colors.$grays, 700);
}

.tabler__th-link--checkbox {
	padding-left: 0;

	@include breakpoint.up(lg) {
		padding-left: spacing.set(2);
	}
}

.tabler__actions-wrap {
	display: flex;
	justify-content: flex-end;
	gap: spacing.set(3);
}

.tabler__row--body {
	height: spacing.set(12);
	border-radius: map.get(border-radius.$values, medium);
	box-shadow: map.get(box-shadow.$values, huge);

	&::after {
		content: '';
		position: absolute;
		right: 0;
		display: block;
		opacity: 0;
		width: units.px2rem(6px);
		height: spacing.set(12);
		border-radius:
			0
			map.get(border-radius.$values, medium)
			map.get(border-radius.$values, medium)
			0;
		background-color: colors.$primary;
		transition:
			opacity transition.$base,
			background-color transition.$base,
			transform transition.$base;
	}
}

.tabler__row--body--empty::after {
	content: none;
}

@media (hover: hover) {
	.tabler__row--body:hover::after {
		opacity: 1;
	}
}

@include breakpoint.up(sm) {
	.tabler__row--is-active::after {
		opacity: 1;
	}
}

.tabler__cell {
	position: relative;
	height: spacing.set(12);
	padding: spacing.set(1) units.px2rem(8px);
	background-color: colors.$white;

	&:first-of-type {
		border-radius: map.get(border-radius.$values, medium) 0 0 map.get(border-radius.$values, medium);
	}

	&:last-of-type {
		border-radius: 0 map.get(border-radius.$values, medium) map.get(border-radius.$values, medium) 0;
	}
}

.tabler__cell--ellipsis {
	max-width: spacing.set(20);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.tabler__cell--bulk-check {
	height: spacing.set(6);
	background-color: map.get(colors.$grays, 200);

	@include breakpoint.up(xxxxl) {
		display: none;
	}
}

.tabler__indicator--end {
	justify-content: flex-end;
	white-space: nowrap;
}

.tabler__indicator--alternate::after {
	display: none;
}

.tabler-sort {
	display: inline-flex;
	flex-direction: column;
	row-gap: units.px2rem(3px);
	font-size: units.px2rem(7px);
	vertical-align: middle;
}

.tabler-sort--sort-by-attributes-up .icon-chevron-up {
	color: colors.$primary;
}

.tabler-sort--sort-by-attributes-down .icon-chevron-down {
	color: colors.$primary;
}

.tabler__cell--image {
	width: spacing.set(23);
	padding: spacing.set(1) units.px2rem(8px);
	padding: spacing.set(1);
}

.tabler__image {
	background-image: url(../../../img/image-default.svg);
	background-position: units.px2rem(8px) center;
	background-size: spacing.set(20) spacing.set(10);
	background-repeat: no-repeat;
}

.tabler__image > img {
	display: block;
	width: spacing.set(20);
	height: spacing.set(10);
	border-radius: map.get(border-radius.$values, medium);
}

.tabler__row-link {
	position: absolute;
	inset: 0;
	font-size: 0;
}

.tabler__row-link ~ span {
	position: relative;
}

.table-layout__filter:not(.filter--is-mini) ~ .table-layout__detail:not(:empty) {
	display: block;
}

.table-layout__detail-hide {
	display: none;

	@include breakpoint.up(lg) {
		display: table-cell;
	}
}

.tabler__cell--first-active {
	border-radius: map.get(border-radius.$values, medium) 0 0 map.get(border-radius.$values, medium);

	@include breakpoint.up(lg) {
		border-radius: 0;
	}
}

.tabler__cell--last-active {
	border-radius: 0 map.get(border-radius.$values, medium) map.get(border-radius.$values, medium) 0;

	@include breakpoint.up(lg) {
		border-radius: 0;
	}
}

.tabler__btn {
	@include typography.style(text1);

	display: inline-flex;
	column-gap: spacing.set(2);
	align-items: baseline;
	padding: 0;
	font-weight: map.get(typography-settings.$font-weight-values, semibold);
	font-family: typography-settings.$font-family-primary;
	white-space: nowrap;
	color: map.get(colors.$grays, 700);
	border: 0;
	background-color: transparent;
	cursor: pointer;
}

.tabler__btn--active {
	color: colors.$primary;
}

.tabler__btn .icon {
	align-self: center;
	margin-top: units.px2rem(-2px);
	font-size: map.get(typography-settings.$font-size-values, 8);
}

.table-layout__detail:not(:empty) ~ .table-layout__table .table-layout__detail-hide {
	display: none;

	@include breakpoint.up(xxxxl) {
		display: table-cell;
	}
}

.table-layout__detail:not(:empty) ~ .table-layout__table .tabler__cell--first-active {
	border-radius: map.get(border-radius.$values, medium) 0 0 map.get(border-radius.$values, medium);
}

.table-layout__detail:not(:empty) ~ .table-layout__table .tabler__cell--last-active {
	border-radius: 0 map.get(border-radius.$values, medium) map.get(border-radius.$values, medium) 0;
}

.table-layout__detail:not(:empty) ~ .table-layout__table .custom-checkbox__wrap--tabler-head {
	display: none;

	@include breakpoint.up(xxxxl) {
		display: block;
	}
}

.table-layout__detail:not(:empty) ~ .table-layout__table .tabler__th-link--checkbox {
	padding-left: 0;

	@include breakpoint.up(xxxxl) {
		padding-left: spacing.set(2);
	}
}

.table-layout__detail:not(:empty) ~ .table-layout__table .dropdown-table-wrap {
	display: flex;

	@include breakpoint.up(xxxxl) {
		display: none;
	}
}

.table-layout__detail:not(:empty) ~ .table-layout__table .tabler__cell--bulk-check {
	display: table-cell;

	@include breakpoint.up(xxxxl) {
		display: none;
	}
}

.table-layout__detail:empty ~ .table-layout__table .dropdown-table-wrap {
	@include breakpoint.up(lg) {
		display: none;
	}
}

.table-layout__detail:empty ~ .table-layout__table .tabler__cell--bulk-check {
	@include breakpoint.up(lg) {
		display: none;
	}
}

.table-layout__detail:empty ~ .table-layout__table .custom-checkbox__wrap--tabler-head {
	display: none;

	@include breakpoint.up(lg) {
		display: block;
	}
}

/* stylelint-disable selector-max-specificity */
.table-layout__detail:empty ~ .table-layout__table .tabler__row--is-active::after {
	opacity: 0;
}

@media (hover: hover) {
	.table-layout__detail:empty ~ .table-layout__table .tabler__row--is-active:hover::after {
		opacity: 1;
	}
}
/* stylelint-enable selector-max-specificity */
