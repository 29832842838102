@use "sass:color";
@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/z-index";
@use "../../settings/typography" as typography-settings;
@use "../../tools/breakpoint";
@use "../../tools/spacing";
@use "../../tools/typography";
@use "../../tools/units";

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: map.get(z-index.$values, modal);
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: color.change(colors.$black, $alpha: 0.4);
}

.modal-dialog {
	position: relative;
	display: flex;
	align-items: center;
	min-height: calc(100% - #{spacing.set(6)});
	/* stylelint-disable unit-no-unknown */
	min-height: calc(100svh - #{spacing.set(6)});
	/* stylelint-enable unit-no-unknown */
	pointer-events: none;

	@media (hover: none) {
		align-self: flex-start;
	}
}

.modal__content {
	width: spacing.set(125);
	max-width: 95vw;
	margin: spacing.set(3) auto;
	border-radius: map.get(border-radius.$values, large);
	background-color: colors.$white;
	pointer-events: auto;
}

.modal__content--large {
	padding: spacing.set(4) spacing.set(12);
	text-align: center;
}

.modal__content--medium {
	width: 95vw;

	@include breakpoint.up(sm) {
		max-width: spacing.set(96);
	}
}

.modal__close {
	width: units.px2rem(32px);
	height: units.px2rem(32px);
	padding-inline: units.px2rem(6px);
	margin-left: auto;
	font-size: map.get(typography-settings.$font-size-values, 8);
	color: map.get(colors.$grays, 700);
	border-radius: map.get(border-radius.$values, medium);
	background-color: map.get(colors.$grays, 200);
	cursor: pointer;

	&:hover,
	&:focus {
		color: map.get(colors.$grays, 700);
		background-color: darken(map.get(colors.$grays, 200), 10%);
		outline: 0;
	}
}

.modal__body {
	padding: spacing.set(2) spacing.set(2) spacing.set(2);

	@include breakpoint.up(lg) {
		padding: spacing.set(4) spacing.set(4) spacing.set(4);
	}
}

.modal__body--aligned {
	padding-top: spacing.set(1);

	@include breakpoint.up(lg) {
		padding-top: spacing.set(2);
	}
}

.modal__header {
	position: relative;
	display: flex;
	align-items: center;
	column-gap: spacing.set(3);
	padding: spacing.set(2);
	border-bottom: units.px2rem(1px) solid map.get(colors.$grays, 200);
	border-radius: map.get(border-radius.$values, large) map.get(border-radius.$values, large) 0 0;

	@include breakpoint.up(lg) {
		padding: spacing.set(2) spacing.set(4) spacing.set(2);
	}
}

.modal__header--control {
	position: sticky;
	top: 0;
	z-index: 3;
	gap: spacing.set(2);
	padding-bottom: 0;
	border-bottom: 0;
	background-color: colors.$white;
}

.modal__title {
	@include typography.style(heading4);

	margin: 0;
	color: colors.$primary;
}

.modal__buttons {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	gap: spacing.set(2);
	margin-inline: spacing.set(2);

	@include breakpoint.up(sm) {
		flex-direction: row;
		margin-inline: spacing.set(0);
	}
}

.modal__footer {
	padding: spacing.set(2);
	border-top: units.px2rem(1px) solid map.get(colors.$grays, 200);

	@include breakpoint.up(lg) {
		padding: spacing.set(4);
	}
}

.modal__footer--sticky {
	position: sticky;
	bottom: 0;
	z-index: 2;
	background-color: colors.$white;
}

.modal__promocode-input {
	display: none;
}

.modal__section-gray {
	padding: spacing.set(2);
	margin-inline: spacing.set(-2);
	margin-bottom: spacing.set(2);
	background-color: map.get(colors.$grays, 100);
	border-block: units.px2rem(1px) solid map.get(colors.$grays, 200);

	@include breakpoint.up(lg) {
		padding: spacing.set(4);
		margin-inline: spacing.set(-4);
	}
}
