/* stylelint-disable */
.questionnaire-nav {
	display: none;

	@media (min-width: 600px) {
		display: flex;
		padding: 0;
		margin: 0;
	}
}

.questionnaire-nav[novisible] {
	display: none;
}

.questionnaire-nav__link {
	position: relative;
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	justify-content: center;
	height: 47px;
	padding: 0 15px;
	font-family: $font-family;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: -0.1px;
	color: #313131;
	border-bottom: 1px solid #fe5c4f;
	transition:
		border-color 0.2s ease,
		color 0.2s ease;

	&:first-child {
		padding-left: 20px;
	}

	&:last-child {
		padding-right: 20px;
	}

	&:not(:last-child)::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		width: 8px;
		height: 8px;
		box-sizing: border-box;
		border-top: 2px solid #fe5c4f;
		border-right: 2px solid #fe5c4f;
		transform: translate(25%, -50%) rotate(45deg);
		transition: border-color 0.2s ease;
	}
}

.questionnaire-nav__link.active ~ .questionnaire-nav__link {
	color: transparentize(#313131, 0.6);
	border-bottom-color: #c4c4c4;

	&:hover,
	&:focus {
		color: transparentize(#313131, 0.3);
	}

	&:not(:last-child)::after {
		border-color: #c4c4c4;
	}
}
/* stylelint-enable */
