/* stylelint-disable */
.sms_wrap {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	margin-left: -15px;
	margin-right: -15px;
}
.sms_bx {
	flex: 1;
	box-shadow: 2px 4px 16px 0 rgba(0,0,0,0.15);
	border-radius: 4px;
	margin: 15px 15px;
	padding: 20px;
}
.sms_bx--1-5 {
	flex: 1.5;
}
.sms_count {
	color: #313131;
	font-size: 44px;
	font-weight: bold;
	letter-spacing: -0.6px;
	line-height: 54px;
	margin-bottom: 0;
	small {
		font-size: 16px;
		font-weight: 600;
		line-height: 19px;
		margin-left: -10px;
	}
}
.sms_price {
	font-size: 14px;
	color: $p-fade;
	margin-top: 0;
	margin-bottom: 10px;

	&:first-of-type {
		margin-top: 20px;
	}
}
.sms_bx--no-bg {
	box-shadow: none;
}
.sms__btn {
	width: 160px;
	padding: 15px 7px;
}
/* stylelint-enable */
