/* stylelint-disable */
.communications-popover {
    opacity: 0;
    visibility: hidden;
    position: relative;
    z-index: 100;
    transition: opacity .2s ease;
}

.communications-popover__button {
    position: absolute;
    float: left;
    width: 32px;
    height: 32px;
    margin-top: 5px;
    margin-left: 8px;
    margin-right: 5px;
    padding-bottom: 6px;
    background-color: transparentize($lightGrey, 0.2);
    border-radius: 3px;
    font-weight: 600;
    cursor: pointer;

    &:hover,
    &:focus {
        color: $main-color;
    }
}

.communications-popover__link {
    position: absolute;
    display: none;
    top: 39px;
    left: 8px;
    padding: 15px 38px 15px 13px;
    color: $darkGrey;
    background-color: #fff;
    box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    opacity: 1;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.1px;
    white-space: nowrap;
}

.communications-popover__link.disabled {
    color: $lightGreyBtnText;
}

.communications-popover.is-open {
    .communications-popover__button {
        color: $main-color;
    }

    .communications-popover__link {
        display: block;
    }
}

.communications-wrap:hover .communications-popover,
.communications-wrap:focus .communications-popover {
        opacity: 1;
        visibility: visible;
}

.communications-wrap .dr-extra__img,
.communications-wrap .dr-extra-name {
    transition: transform 0.2s ease;
}

.communications-wrap:hover .dr-extra__img,
.communications-wrap:hover .dr-extra__name,
.communications-wrap:focus .dr-extra__img,
.communications-wrap:focus .dr-extra-name {
    transform: translateX(35px);
}
/* stylelint-enable */
