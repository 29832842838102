/* stylelint-disable */
%table-icons-wrap {
	white-space: nowrap;
}
%table-icons {
	height: 24px;
	width: 24px;
	display: inline-flex;
	vertical-align: middle;
	margin-left: 7px;
	justify-content: center;
	align-items: center;
}
.icon-published {
	@extend %table-icons-wrap;
	&::after {
		content: url(../../img/icons/icon-visible.svg);
		@extend %table-icons;
	}
}
.icon-unpublished {
	@extend %table-icons-wrap;
	&::after {
		content: url(../../img/icons/icon-visible-none.svg);
		@extend %table-icons;
	}
}
.icon-sharelink {
	@extend %table-icons-wrap;
	&::after {
		content: url(../../img/icons/icon-sharelink.svg);
		@extend %table-icons;
	}
}

.delete-modal-icon {
	min-width: 50px;
	margin: 22px 35px 22px 18px;
}

.btn:hover {
	.icon--move {
		background-image: url(../../img/icons/icon-import-to-masterlist-color.svg);
	}
}

.icon {
	display: inline-block;
	vertical-align: middle;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}
.icon--move {
	background-image: url(../../img/icons/icon-import-to-masterlist.svg);
	height: 16.67px;
	width: 19.58px;
	margin-right: 9px;
	opacity: .9;
	@extend .icon;
}
.icon--cross {
	&::before {
		content: "×";
		font-size: 37px;
		color: #606060;
		font-weight: 300;
	}
}
.icon--setting {
	background-image: url(../../img/icons/icon-settings.svg);
	height: 16px;
	width: 16px;
	margin-left: 9px;
	margin-top: -2px;
	opacity: .9;
	@extend .icon;
}
.icon--add-person {
	background-image: url(../../img/icons/icon-add-extra.svg);
	height: 16.67px;
	width: 20.03px;
	margin-right: 6px;
	margin-top: -3px;
	@extend .icon;
}
.icon--filter {
	background-image: url(../../img/icons/icon-filter.svg);
	height: 16.67px;
	width: 20px;
	margin-right: 15px;
	margin-top: -2px;
	@extend .icon;
}
.icon--min-drbox {
	background-image: url(../../img/icons/icon-minimize-grey.svg);
	height: 15px;
	width: 15px;
	@extend .icon;
}
.dr-boardbox__icon:hover {
	.icon--min-drbox {
		background-image: url(../../img/icons/icon-minimize-color.svg);
	}
	.icon--max-drbox {
		background-image: url(../../img/icons/icon-maximalize-color.svg);
	}

}
.icon--max-drbox {
	background-image: url(../../img/icons/icon-maximalize-grey.svg);
	height: 15px;
	width: 15px;
	@extend .icon;
}
.icon--search-drbox {
	background-image: url(../../img/icons/icon-search-grey.svg);
	height: 20px;
	width: 16px;
	@extend .icon;
}
.icon--min {
	background-image: url(../../img/icons/icon-minimize.svg);
	height: 14.67px;
	width: 18.67px;
	margin-top: -2px;
	@extend .icon;
}
.icon--max {
	background-image: url(../../img/icons/icon-maximalize.svg);
	height: 14.67px;
	width: 18.67px;
	margin-top: -2px;
	@extend .icon;
}
.icon--visibility {
	background-image: url(../../img/icons/icon-visible.svg);
	height: 12px;
	width: 16px;
	@extend .icon;
	opacity: .9;
}
.icon--duplicate {
	background-image: url(../../img/icons/icon-duplicate.svg);
	height: 13.33px;
	width: 13.33px;
	margin-left: 6px;
	@extend .icon;
	opacity: .9;
}
.icon--trash {
	background-image: url(../../img/icons/icon-delete.svg);
	height: 18.33px;
	width: 16.67px;
	@extend .icon;
}
.icon--trash-blue {
	background-image: url(../../img/icons/icon-delete-blue.svg);
	height: 18.33px;
	width: 16.67px;
	@extend .icon;
}
.icon--upload-blue {
	background-image: url(../../img/icons/icon-upload-blue.svg);
	height: 18.33px;
	width: 16.67px;
	@extend .icon;
}
.icon--crop {
	background-image: url(../../img/icons/icon-crop.svg);
	height: 18px;
	width: 18px;
	@extend .icon;
}
.icon--crop-blue {
	background-image: url(../../img/icons/icon-crop-blue.svg);
	height: 18px;
	width: 18px;
	@extend .icon;
}
.icon--info {
	background-image: url(../../img/icons/icon-info.svg);
	height: 18.33px;
	width: 18.33px;
	@extend .icon;
}
.icon--visible {
	background-image: url(../../img/icons/icon-visible.svg);
	height: 18px;
	width: 18px;
	margin-left: 5px;
	margin-top: -3px;
	@extend .icon;
}

.icon--novisible {
	background-image: url(../../img/icons/icon-visible-none.svg);
	height: 18px;
	width: 18px;
	margin-left: 5px;
	margin-top: -3px;
	@extend .icon;
}
.icon--extraslist {
	background-image: url(../../img/icons/icon-extra-list.svg);
	height: 22px;
	width: 18.33px;
	@extend .icon;
	&:hover {
		background-image: url(../../img/icons/icon-extra-list-color.svg);
	}
}

.icon--email {
	background-image: url(../../img/icons/icon-email.svg);
	height: 16.5px;
	width: 20.17px;
	@extend .icon;
	&:hover {
		background-image: url(../../img/icons/icon-email-color.svg);
	}
}

.icon--close {
	background-image: url(../../img/icons/icon-close-new.svg);
	height: 20px;
	width: 20px;
	@extend .icon;
}

.icon--refresh {
	background-image: url(../../img/icons/icon-refresh.svg);
	height: 16px;
	width: 16px;
	@extend .icon;
	&:hover {
		background-image: url(../../img/icons/icon-refresh--dark.svg);
	}
}
.btn:hover {
	.icon--min,
	.icon--filter,
	.icon--add-person,
	.icon--min,
	.icon--max {
		opacity: 1
	}
	.icon--add-person {
		background-image: url(../../img/icons/icon-add-extra-color.svg);
	}
	.icon--filter {
		background-image: url(../../img/icons/icon-filter-color.svg);
	}
	.icon--max {
		background-image: url(../../img/icons/icon-maximalize-color.svg);
	}
	.icon--trash,
	.icon--trash-blue {
		background-image: url(../../img/icons/icon-delete-color.svg);
	}
	.icon--upload-blue {
		background-image: url(../../img/icons/icon-upload-color.svg);
	}
	.icon--crop,
	.icon--crop-blue {
		background-image: url(../../img/icons/icon-crop-color.svg);
	}
}
.btn--med {
	.icon--filter {
		margin-right: 6px;
	}
}
/* stylelint-enable */
