@use "sass:map";
@use "../../settings/colors";
@use "../../settings/typography" as typography-settings;

.is-required > label::after,
.file-line.is-required label::after {
	content: '*';
	font-weight: map-get(typography-settings.$font-weight-values, bold);
	font-size: map-get(typography-settings.$font-size-values, 8);
	color: colors.$primary;
}
