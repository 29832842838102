/* stylelint-disable */

.dropdown__content .dropdown__button {
	width: 64px;
	height: 64px;
	margin-bottom: 9px;

	&::after {
		display: none;
	}
}


.toggled-nav__account__icon {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	background-image: url(../../img/icons/icon-account-switch.svg);
	background-repeat: no-repeat;
}

.toggled-nav__account__icon__back {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	background-image: url(../../img/icons/icon-account.svg);
	background-repeat: no-repeat;
}

.toggled-nav__account__detail {
	flex: 1;
}

.toggled-nav__account__title {
	display: block;
	margin-bottom: 3px;
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 0.12px;
	line-height: 13px;
	color: #a5a5a5;
}

.toggled-nav__account__name {
	display: block;
	font-size: 13px;
	font-weight: bold;
	letter-spacing: 0.26px;
	line-height: 16px;
	color: #313131;
}

.toggled-nav__account__control {
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.15px;
	line-height: 15px;
	text-align: right;
	white-space: nowrap;
	color: #606060;
}

.secondary-toggled__btn {
	padding-right: 61px;
	position: relative;
	&::after {
		content: " ";
		display: block;
		background-image: url(../../img/icons/icon-chevron-right.svg);
		height: 9.92px;
		width: 6px;
		background-size: 5.8px;
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		right: 11px;
		top: 16px;
	}
}
.secondary-toggled__nav {
	position: absolute;
	pointer-events: none;
	display: none;
}
.secondary-toggled--active {
	display: block;
	pointer-events: auto;
	z-index: $z-index-modal-aside + 1;
}

.btn--toggle-simple {
	color: $main-color;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: -0.02px;
	line-height: 15px;
	text-align: right;
	padding: 0;
	cursor: pointer;
	&::after {
		background-image: url(../../img/icons/icon-chevron-down-color.svg);
		margin-left: 0;
	}
}

.btn--toggle-simple-light {
	color: $mediumGrey;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 0.13px;
	line-height: 14px;
	padding: 0;
	cursor: pointer;
	height: 20px;
	&::after {
		background-image: url(../../img/icons/icon-chevron-down.svg);
		margin-left: -3px;
		background-size: 7px;
		margin-bottom: 0;
		height: 14px;
	}
}

.round-nav {
	position: fixed;
	right: 15px;
	top: 15px;
	z-index: 1;
}
.round-nav__btn {
	display: block;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.15px;
	line-height: 15px;
	text-transform: none;
	border-radius: 20px;
	padding: 12px 16px;
	height: 40px;
	width: 90px;
	text-align: center;
	white-space: nowrap;
	cursor: pointer;
	&::after {
		margin-left: 0;
	}
	&:lang(ro) {
		width: 100px;
	}
}
.round-nav__list {
	overflow: hidden;
	padding: 0;
	background-color: transparent;
	box-shadow: none;
	margin-top: -7px;
}
.round-nav__list-btn {
	background-color: #ececec;
	color: #787878;
	margin-top: 7px;
	&:hover {
		background-color: #dddddd;
	}
}

.nav-back {
	display: flex;
	color: #606060;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: -0.02px;
	line-height: 15px;
	align-items: center;
	flex: 0 0 196px;
}
.nav-back__icon {
	height: 44px;
	flex: 0 0 24px;
	max-width: 24px;
	border-radius: 4px;
	background-color: #ECECEC;
	background-image: url(../../img/icons/icon-leave.svg);
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 12px;
}

.switch-warning {
	color: #212937;
	font-size: 12px;
	text-align: center;
}

.switch-warning-background {
	height: 48px;
	width: 299px;
	border-radius: 4px;
	background-color: #C2D1D9;
	margin-bottom: 13px;
}

.switch-warning-big {
	color: #212937;
	font-size: 16px;
	text-align: center;
	margin: 20px;
}

.switch-warning-big-background {
	padding-top: 1px;
	height: 40px;
	width: 70%;
	border-radius: 4px;
	background-color: #C2D1D9;
	margin: auto auto 13px;
	display: flex;
	justify-content: center;
	align-items: center;
}
/* stylelint-enable */
