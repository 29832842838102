/* stylelint-disable */
.detail__tab-select {
	display: flex;
	flex-flow: row;
	justify-content: space-around;
	line-height: 15px;
	font-size: 12px;
	padding-bottom: 8px;
	border-bottom: 2px solid #EEEEEE;
	flex: 0 0 auto;
	align-items: flex-end;

	padding-right: 20px;
	margin-right: -20px;
	.dropdown {
		margin-left: 0;
	}
	.dropdown__content {
		width: calc(100% + 20px);
		left: -10px;
		top: calc(100% + 9px);
		transition: opacity .3s ease, transform .3s ease;
	}
	.btns-list__btn {
		font-size: 12px;
		font-weight: 500;
		letter-spacing: -0.1px;
		line-height: 15px;
		text-align: right;
		text-transform: inherit;
		padding: 8px 12px;
		&.active {
			font-weight: 600;
		}
	}
}
.detail__tab-select__title {
	color: #313131;
	opacity: 0.66;
	font-weight: 600;
	letter-spacing: -0.01px;
	text-align: center;
}
.detail__tab-select__btn {
	position: relative;
	font-weight: bold;
	letter-spacing: -0.02px;
	text-align: center;
	color: $main-color;
	cursor: pointer;
	padding: 0;
	width: 150px;
	white-space: nowrap;
	&::before {
		content: " ";
		position: absolute;
		pointer-events: none;
		width: calc(100% + 20px);
		bottom: -10px;
		left: -10px;
		height: 2px;
		background-color: $main-color;
	}
	&::after {
		background-image: url(../../img/icons/icon-chevron-down-color.svg);
		margin-left: 0;
	}
	&.is-open {
		z-index: 1001;
		+ .dropdown__content {
			border-top: 34px solid white;
			margin-top: -34px;
		}
	}
}
/* stylelint-enable */
