@use "sass:map";
@use "../../settings/border-radius";
@use "../../settings/colors";
@use "../../settings/typography" as typography-settings;
@use "../../settings/theme";
@use "../../settings/transition";
@use "../../tools/spacing";
@use "../../tools/units";
@use "../../tools/typography";

.filter-advanced {
	margin-top: spacing.set(4);
	margin-inline: units.px2rem(-12px);
}

.filter-advanced__item {
	border-top: units.px2rem(1px) solid map.get(colors.$grays, 300);
}

.filter-advanced__item__button {
	@include typography.style(text2);

	position: relative;
	width: 100%;
	padding: spacing.set(3) spacing.set(5) spacing.set(3) units.px2rem(12px);
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	letter-spacing: units.px2em(-0.1px, map.get(typography-settings.$font-size-values, 3));
	text-align: left;
	color: map.get(colors.$grays, 700);
	cursor: pointer;
	transition: color transition.$base;

	&:hover {
		color: colors.$primary;
	}
}

.filter-advanced__item__button--is-active {
	color: colors.$primary;
}

.filter-advanced__item__button > svg {
	position: absolute;
	top: 50%;
	right: spacing.set(3);
	color: map.get(colors.$grays, 700);
	transform: translateY(-50%);
	transition: color transition.$base;
}

.filter-advanced__item__button:hover > svg,
.filter-advanced__item__button:focus > svg {
	color: colors.$primary;
}

.filter-advanced__item__tags {
	display: flex;
	flex-wrap: wrap;
	gap: units.px2rem(3px) units.px2rem(7px);
	padding: 0 spacing.set(3) spacing.set(3);
}

.filter-advanced__tag {
	@include typography.style(text3);

	display: inline-flex;
	align-items: center;
	column-gap: spacing.set(1);
	height: units.px2rem(17px);
	padding-inline: units.px2rem(8px);
	font-weight: map.get(typography-settings.$font-weight-values, semibold);
	text-align: center;
	white-space: nowrap;
	color: colors.$white;
	border-radius: map.get(border-radius.$values, full);
	background-color: colors.$primary;
}

.filter-advanced__tag__remove {
	position: relative;
	display: inline-block;
	width: spacing.set(2);
	height: spacing.set(2);
	font-size: 0;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: 100%;
		height: units.px2rem(1px);
		background-color: colors.$white;
	}

	&::before {
		transform: translate(-50%, -50%) rotate(-45deg);
	}

	&::after {
		transform: translate(-50%, -50%) rotate(45deg);
	}
}
