@use "sass:map";
@use "../../settings/layout";
@use "../../tools/units";

.container {
	width: 100%;
	margin-inline: auto;
}

.container--large {
	max-width: map.get(layout.$container, width, large);
}

.container--small {
	max-width: map.get(layout.$container, width, small);
}
