/* stylelint-disable */
.page-logo {
	display: flex;
	width: 143px;
	margin-right: 55px;
	img {
		max-width: 100%;
	}
}

.shadow-box {
	box-shadow: 2px 4px 16px 0 rgba(0,0,0,0.15);
	padding: 10px 7px;
	text-align: center;
	border-radius: 4px;
}
.shadow-box__hr {
	border: 0;
	height: 1px;
	background-color: #eeeeee;
	width: calc(100% + 14px);
	margin-left: -7px;
}
.modal {
	.page-box__grey {
		&::before {
			width: 480px;
			max-width: 95vw;
			left: calc(((95vw - 100%) / 2) * -1);
			@media (min-width: 506px) {
				left: calc(((480px - 100%) / 2) * -1);
			}
		}
	}
}

.tooltip-result {
	position: absolute;
	max-width: 200px;
	background-color: #46546A;
	color: #FFFFFF;
	font-size: 11px;
	font-weight: 600;
	line-height: 14px;
	text-align: center;
	padding: 6px 10px;
	z-index: 2001;
	transform: translate(-50%, -100%);
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
	border-radius: 2px;
	&::after {
		content: " ";
		position: absolute;
		width: 8px;
		height: 8px;
		bottom: 0;
		left: 50%;
		transform-origin: center;
		transform: translate(-50%, 4px) rotate(45deg);
		background-color: #46546A;
		z-index: -1;
	}
}
.tooltip-result--right {
	transform: translate(-20%, -100%);
	&::after {
		left: 20%;
	}
}
.tooltip-result--left {
	transform: translate(-80%, -100%);
	&::after {
		left: 80%;
	}
}
.three-point-burger {
	position: relative;
	height: 24px;
	width: 24px;
	cursor: pointer;
	&::before {
		content: " ";
		position: absolute;
		width: 3px;
		height: 3px;
		left: 4px;
		top: 10px;
		border-radius: 50%;
		background-color: $black;
		box-shadow: 6px 0 0 0 $black, 12px 0 0 0 $black;
	}
}

.three-point-burger.is-open::before {
	background-color: $main-color;
	box-shadow: 6px 0 0 0 $main-color, 12px 0 0 0 $main-color;
}

$color-collection: (#D9DD79, #84B68D, #DCAC5B, #CB735C, #5A84A4, #8C64A9, #59A7A5, #33CB89, #C533C4, #E96C14);
$color-count: length($color-collection);

@for $i from 0 to $color-count {
	.random-color--#{$i} {
		color: nth($color-collection, $i+1);
	}
}

$num-colors: 100;
$colors-from: $color-count;

@for $i from $colors-from to $num-colors {
	.random-color--#{$i} {
		color: rgb(random(255), random(255), random(255));
	}
}

.scroll-arrow {
	position: sticky;
	height: 44px;
	width: 24px;
	flex: 0 0 24px;
	background-color: #ECECEC;
	border-radius: 4px;
	cursor: pointer;
	&:first-child {
		margin-left: 7px;
		margin-right: 5px;
		&::before {
			background-image: url(../../img/icons/icon-chevron-left.svg);
		}
	}
	&:last-child {
		margin-right: 7px;
		margin-left: 5px;
		&::before {
			background-image: url(../../img/icons/icon-chevron-right.svg);
		}
	}
	&::before {
		content: " ";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 7px;
		opacity: .8;
	}
	&:hover {
		&:first-child::before {
			background-image: url(../../img/icons/icon-chevron-left-color.svg);
		}
		&:last-child::before {
			background-image: url(../../img/icons/icon-chevron-right-color.svg);
		}
	}
}

.dr-aside__body__nrm-dir {
	hr {
		padding-left: 10px;
		padding-right: 10px;
		margin-left: -10px;
	}
}

.presence-table {
	max-width: 100%;
	font-size: 12px;
	font-weight: 500;
	border-collapse: collapse;
	td {
		padding: 4px;
	}
	tr:nth-child(odd) {
		background-color: #eeeeee;
	}
}

.aside-full-name {
	height: 19px;
	float:left;
	margin-bottom: 30px;
	font-size: 16px;
	font-weight: 600;
	line-height: 29px;
	color: #313131;
	padding-left: 20px;
}

/* stylelint-enable */
