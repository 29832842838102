@use "sass:map";

$white: #fff;
$black: #000;
$green: #69b552;

$brand: (
	red: #df3939,
	orange: #fe5c4f,
	orange-dark: #db5149,
	blue-dark: #46546a,
	pink: #f5c0b4
);

$grays: (
	066: #f9fafa,
	100: #f8f8f8,
	200: #ececec,
	300: #dadada,
	400: #d0d0d0,
	500: #bcbcbc,
	600: #a8a8a8,
	700: #767676,
	800: #4e4e4e,
	900: #262626
);

$primary: map.get($brand, orange);
$text: map.get($grays, 900);
