/* stylelint-disable */
.detail-textarea-wrap {
	@include wrap();
}

.detail-textarea {
	@include field();
}

.detail-textarea__label {
	@include label();
}

.detail-textarea__input {
	@include input();

	width: 100%;
	max-height: 150px;
	margin-top: 5px;
}

.detail-textarea.has-error .error-container {
	@include error-container();
}
/* stylelint-enable */
