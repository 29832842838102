/* stylelint-disable */
.questionnaire-bar:not([hidden]) {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	max-width: 640px;
	padding: 15px 20px;
	margin: 0 auto 10px;
	border-radius: 6px;
	background-color: #f5f8f8;
}

.questionnaire-bar__label {
	width: 100%;
	margin: 10px 0;
	flex-grow: 1;
	font-family: $font-family;
	font-size: 16px;
	font-weight: 600;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	color: #313131;

	@media (min-width: 600px) {
		width: auto;
	}
}

.questionnaire-bar__radio {
	position: relative;
	padding-left: 24px;
	margin: 10px 20px 10px 0;
	box-sizing: border-box;
	font-family: $font-lato;
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	text-align: left;
	color: #313131;

	@media (min-width: 600px) {
		margin: 10px 0 10px 20px;
	}
}

.questionnaire-bar__radio > input {
	position: absolute;
	visibility: hidden;
	opacity: 0;
}

.questionnaire-bar__radio > span {
	position: absolute;
	top: 50%;
	left: 0;
	flex-shrink: 0;
	display: block;
	width: 17px;
	height: 17px;
	box-sizing: border-box;
	color: #606060;
	border-radius: 50%;
	border: 1px solid currentColor;
	transform: translateY(-50%);

	&::after {
		content: '';
		position: absolute;
		top: 2px;
		left: 2px;
		display: block;
		width: 11px;
		height: 11px;
		box-sizing: border-box;
		visibility: hidden;
		opacity: 1;
		border-radius: 50%;
		background-color: currentColor;
	}
}

.questionnaire-bar__radio > input:checked + span {
	color: #fe5c4f;
}

.questionnaire-bar__radio > input:checked + span::after {
	visibility: visible;
	opacity: 1;
}

.questionnaire-bar__head {
	flex-grow: 1;
}

.questionnaire-bar__head > .questionnaire-bar__label {
	margin-bottom: 0;
}

.questionnaire-bar__subtitle {
	max-width: 250px;
	font-family: $font-lato;
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	color: #787878;
}

.questionnaire-bar__input {
	margin-top: 20px;
}

.questionnaire-bar__input > input {
	border: 1px solid #dadee1;
	background-color: #ffffff;
}

.questionnaire-bar__input > input:disabled {
	opacity: 0.5;
}

.questionnaire-bar__input > label {
	visibility: hidden;
	opacity: 0;
}

.questionnaire-bar__input--multiple {
	position: relative;
	display: flex;
	padding-left: 30px;
	margin-top: 10px;

	&::before {
		content: '';
		position: absolute;
		top: 13.5px;
		left: 0;
		display: block;
		width: 17px;
		height: 17px;
		border: 1px solid #606060;
		border-radius: 2px;
		box-sizing: border-box;
	}
}

.questionnaire-bar__input--multiple + .questionnaire-bar__input--multiple {
	margin-top: 0;
}

.questionnaire-bar__input--radio::before {
	border-radius: 50%;
}

.questionnaire-bar__input--multiple.has-error {
	padding-bottom: 15px;
}

.questionnaire-bar__input--multiple > .error-container {
	position: absolute;
	left: 30px;
	bottom: 0;
	width: auto;
}

.questionnaire-bar__input__delete.questionnaire-bar__input__delete {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	padding: 0;
	font-size: 0;
	border: 0;
	background: transparent;
	cursor: pointer;

	&::after {
		content: '';
		display: block;
		width: 20px;
		height: 20px;
		background-image: url(../../img/icons/icon-delete-dark.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 16.67px 20px;
	}
	&:hover::after {
		background-image: url(../../img/icons/icon-delete-color.svg);
	}
}

.questionnaire-bar__add {
	position: relative;
	display: flex;
	align-items: center;
	height: 22px;
	padding: 0 0 0 22px;
	margin-left: 30px;
	font-family: $font-family;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.15px;
	color: #606060;
	cursor: pointer;

	&::before,
	&::after {
		content: '';
		position: absolute;
		border-radius: 2px;
		box-sizing: border-box;
		background-color: currentColor;
	}

	&::before {
		top: 10px;
		left: 3px;
		height: 2px;
		width: 12px;
	}

	&::after {
		top: 4px;
		left: 8px;
		height: 14px;
		width: 2px;
	}

	&:hover {
		color: $main-color;
	}
}

.questionnaire-bar__footer {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-top: 13px;
}
/* stylelint-enable */
