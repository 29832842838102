@use "sass:map";
@use "../../settings/colors";
@use "../../settings/border-radius";
@use "../../settings/header";
@use "../../settings/layout";
@use "../../settings/typography" as typography-settings;
@use "../../settings/z-index";
@use "../../tools/breakpoint";
@use "../../tools/units";
@use "../../tools/spacing";

.mark {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(18px);
	height: units.px2rem(18px);
	font-weight: map.get(typography-settings.$font-weight-values, semi-bold);
	font-family: typography-settings.$font-family-primary;
	color: colors.$white;
	border-radius: map.get(border-radius.$values, full);
	background-color: colors.$primary;
}
