/* stylelint-disable */
#frm-filter-form {
	position: relative;
	margin-bottom: -6px;
	padding-bottom: 24px;
	padding-top: 23.5px;
}
.filter-row.filter-row {
	justify-content: space-between;
	flex-wrap: wrap;
}

.filter-row__item {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media (min-width: 800px) {
		width: 30%;
	}
}

.filter-row__item--wide {
	align-items: flex-start;

	@media (min-width: 800px) {
		width: 65%;
	}
}

.filter-row__item > .input-wrapper {
	width: calc(50% - 15px);
	margin-bottom: 20px;
}

.filter-row__item:not(.filter-row__item--wide) > .filter-range {
	margin-bottom: 30px;
}

.filter-row__item:not(.filter-row__item--wide) > .filter-range--last {
	margin-bottom: 0;
}

/* stylelint-enable */
